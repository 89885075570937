import React, { useContext, useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { ListItem, List, ListItemText, Drawer } from "@material-ui/core";
import { getListStyle } from "./styles";
import FieldEditor from "./field-editor";
import FormBuilderContext from "./form-builder-context";
import FieldRenderer from "./Render/renderer";

export const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
};

export const copy = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const item = source[droppableSource.index];
  destination.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destination;
};

// This method is needed for rendering clones of draggables
export const getRenderItem = (items, className) => (
  provided,
  snapshot,
  rubric
) => {
  const item = items[rubric.source.index];
  return (
    <React.Fragment>
      <ListItem
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={{
          ...provided.draggableProps.style,
          background: "#de1736",
          color: "white",
        }}
        className={snapshot.isDragging ? "dragging" : ""}
      >
        <ListItemText primary={item.label} />
      </ListItem>
    </React.Fragment>
  );
};

const EmptyDropzone = () => (
  <div
    style={{
      border: "1px dashed grey",
      color: "grey",
      minWidth: "100%",
      height: "500px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    Start building the form by dragging elements here from the toolbox.
  </div>
);

export function FormDropzone(props) {
  const { items, onDelete } = useContext(FormBuilderContext);
  const [currentEditableItem, setCurrentEditableItem] = useState();
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false);

  const onEdit = (item) => {
    setCurrentEditableItem(item);
    setEditDrawerOpen(true);
  };

  const onFinishedEditing = () => {
    setEditDrawerOpen(false);
  };

  useEffect(() => {
    if (currentEditableItem) {
      const updatedItem = items.find((i) => i.id === currentEditableItem.id);
      setCurrentEditableItem(updatedItem);
    }
  }, [items]);

  return (
    <>
      <Droppable droppableId="BUILDER">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {items.length === 0 && <EmptyDropzone />}
            {items.length > 0 && (
              <List style={getListStyle(snapshot.isDraggingOver)}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <FieldRenderer
                          item={item}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          provided={provided}
                          snapshot={snapshot}
                          type={item.type}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </div>
        )}
      </Droppable>
      {/* Edit Drawer */}
      <Drawer
        anchor={"left"}
        open={isEditDrawerOpen}
        onClose={onFinishedEditing}
        PaperProps={{
          style: { width: "30%" },
        }}
      >
        <FieldEditor item={currentEditableItem} />
      </Drawer>
    </>
  );
}
