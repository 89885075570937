import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { ApolloProvider } from "@apollo/client";
import { graphqlClient } from "./app/apollo";

const theme = createMuiTheme({
  palette: {
    secondary: {
      light: "#ff5a60",
      main: "#de1736",
      dark: "#a40011",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: `Montserrat, "Helvetica", "Arial", sans-serif`,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={graphqlClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <App />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
