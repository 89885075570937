import { Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

const FILE_SIZE_LIMIT = 8388608; // 8mb in bytes

function UploadFileRenderer({ id, settings, register, setValue }) {
  const ref = React.useRef();
  const [hasError, setHasError] = React.useState(false);

  const onChange = (e, value) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.size > FILE_SIZE_LIMIT) {
        ref.current.value = "";
        setValue(id, null);
        setHasError(true);
        return;
      }
    }
    setValue(id, files);
  };

  React.useEffect(() => {
    register(id);
  }, [register, id]);

  return (
    <>
      <TextField
        type="file"
        name={id}
        inputRef={ref}
        label={settings.title}
        variant="outlined"
        onChange={onChange}
        required={settings.required}
        className="form-file-input"
        helperText={settings.help}
        fullWidth
        focused
      />
      <Snackbar
        open={hasError}
        autoHideDuration={4000}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={() => setHasError(false)}
      >
        <Alert onClose={() => setHasError(false)} severity="error">
          Please select a file with maximum of 8mb!
        </Alert>
      </Snackbar>
    </>
  );
}

export default UploadFileRenderer;
