import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";

import CustomTable from "../../components/common/Table";
import { BASE_URL, MEMBERS_SITE_URL, usePublicAxios } from "../../util/axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch"
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    border: "red 1px 1px"
  }
}));

function Merchant() {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const [requestCount, setRequestCount] = useState(1);
  const [selectedTab, setSelectedTab] = useState("listings");
  const [selectedListing, setSelectedListing] = useState(null);

  const [
    {
      data: voucherData,
      loading: fetchingVouchers,
      error: errorFetchingVouchers
    },
    fetchVouchers
  ] = usePublicAxios(
    {
      url: `/getVouchersInfo?email=${email}&password=${password}`,
      method: "GET"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (requestCount && email && password) {
      fetchVouchers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestCount]);

  const submit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      return alert("Please enter Email and Password to fetch results");
    }
    emailRef.current = email;
    passwordRef.current = password;
    fetchVouchers();
  };

  const toggleSelectedTab = (record) => {
    if (selectedTab === "listings") {
      setSelectedTab("vouchers");
      setSelectedListing(record);
    } else {
      setSelectedTab("listings");
      setSelectedListing(null);
    }
  };

  const listingColumns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Start Date",
        accessor: (record) => moment(record.start_date).format("DD-MM-YYYY")
      },
      {
        Header: "End Date",
        accessor: (record) => moment(record.end_date).format("DD-MM-YYYY")
      },
      {
        Header: "Status",
        accessor: (record) => (
          <Chip
            label={record.status === "Draft" ? "Draft" : "Published"}
            color={record.status === "Draft" ? "default" : "secondary"}
            style={{ padding: record.status === "Draft" ? "0 8px" : "" }}
          />
        )
      },
      {
        Header: "Action",
        accessor: (record) => (
          <div>
            <ButtonGroup
              color="secondary"
              aria-label="outlined primary button group"
              size="small"
            >
              <Button onClick={() => toggleSelectedTab(record)}>
                View Redemptions
              </Button>
              <Button
                onClick={() =>
                  onDownloadVouchersRedemptions(
                    record.title,
                    record.id,
                    email,
                    password
                  )
                }
              >
                Download Redemptions
              </Button>
            </ButtonGroup>
          </div>
        )
      }
    ],
    []
  );

  const voucherColumns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>
      },
      {
        Header: "Voucher Code",
        accessor: (record) => (record.code ? record.code : "Not Available")
      },
      {
        Header: "Redeemed?",
        accessor: (record) => (record.member ? "True" : "False")
      }
    ],
    []
  );

  async function onDownloadVouchersRedemptions(title, id, email, password) {
    if (!emailRef.current || !passwordRef.current)
      return enqueueSnackbar(`Please provide email and password!`, {
        preventDuplicate: true,
        variant: "error"
      });

    const key = enqueueSnackbar(
      `Generating Redemptions for listing '${title}'!`,
      {
        preventDuplicate: true,
        variant: "info"
      }
    );

    await axios({
      baseURL: BASE_URL,
      url: `/downloadVouchersRedemptionsMerchant/${id}?email=${emailRef.current}&password=${passwordRef.current}`,
      method: "GET",
      responseType: "blob" // important
    })
      .then((res) => {
        fileDownload(res.data, title + ".csv");
        closeSnackbar(key);
        enqueueSnackbar(
          `Redemptions for listing '${title}' are downloaded successfully!`,
          {
            preventDuplicate: true,
            variant: "success"
          }
        );
      })
      .catch((e) => {
        enqueueSnackbar(
          "There was some internal server error while downloading the Redemptions. Please try again later!",
          {
            preventDuplicate: true,
            variant: "error"
          }
        );
      });
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => (window.location.href = MEMBERS_SITE_URL)}
      >
        Go to Members Site
      </Button>
      <Typography
        variant="h4"
        color="secondary"
        style={{ fontWeight: "bold", textAlign: "center" }}
      >
        Merchant Portal
      </Typography>
      <Box border={1} borderColor="text.primary">
        <form onSubmit={submit} className={classes.root}>
          <TextField
            type="email"
            id="standard-primary"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            color="secondary"
            required
          />
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="standard-secondary"
            label="Password"
            color="secondary"
            required
          />
          <Button
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
          >
            Login
          </Button>
        </form>
      </Box>
      {email && password && (
        <div style={{ textAlign: "center", paddingTop: "1.5rem" }}>
          <ButtonGroup
            color="secondary"
            aria-label="outlined primary button group"
            //   size="small"
          >
            <Button onClick={() => setRequestCount((count) => count + 1)}>
              Refresh Data
            </Button>
          </ButtonGroup>
        </div>
      )}

      {selectedTab === "listings" &&
        !fetchingVouchers &&
        !errorFetchingVouchers &&
        voucherData && (
          <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
            <Grid container spacing={3}>
              <CustomTable
                columns={listingColumns}
                data={voucherData}
                showSelections={false}
                showPagination={false}
                hover
              />
            </Grid>
          </Paper>
        )}

      {selectedTab === "vouchers" &&
        !fetchingVouchers &&
        !errorFetchingVouchers &&
        voucherData && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              <Typography
                variant="h4"
                color="secondary"
                style={{ fontWeight: "bold" }}
              >
                {selectedListing && selectedListing.title
                  ? selectedListing.title
                  : "Vouchers"}
              </Typography>
              {selectedListing && (
                <Button variant="contained" onClick={() => toggleSelectedTab()}>
                  Go Back to All listings
                </Button>
              )}
            </div>

            <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
              <Grid container spacing={3}>
                <CustomTable
                  columns={voucherColumns}
                  data={selectedListing.vouchers || []}
                  showSelections={false}
                  showPagination={false}
                  hover
                />
              </Grid>
            </Paper>
          </>
        )}

      {fetchingVouchers && (
        <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        </Paper>
      )}
      {!fetchingVouchers && errorFetchingVouchers && (
        <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorFetchingVouchers.response.data.message
              ? errorFetchingVouchers.response.data.message
              : "There was some internal server error while fetching records. Please try reloading the page."}
          </Alert>
        </Paper>
      )}
    </div>
  );
}

export default Merchant;
