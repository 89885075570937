import React from "react";

const ActiveItems = ({ items }) => {
  return (
    <div>
      <ul style={{ listStyleType: "circle" }}>
        {items?.map((item) => (
          <li key={item.id}>{item.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default ActiveItems;
