import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { DragDropContext } from "react-beautiful-dnd";
import { Link, useHistory, useParams } from "react-router-dom";
import { authedAxios, useAuthedAxios } from "../../../../../util/axios";
import { copy, FormDropzone, reorder } from "../FormBuilder/builder";
import FormBuilderContext from "../FormBuilder/form-builder-context";
import { Toolbox, TOOLBOX_ELEMENTS } from "../FormBuilder/toolbox";
import { GET_FORM } from "../forms.graphql";
import moment from "moment";
import slugify from "slugify";
import { AutorenewOutlined, LinkOutlined } from "@material-ui/icons";
import FormPreview from "../components/form-preview";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function EditForm() {
  const { id } = useParams();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [formHeader, setFormHeader] = useState("");
  const [slug, setFormSlug] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [hasFormHeaderError, setFormHeaderError] = useState(false);
  const [hasformSlugError, setFormSlugError] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hasStartDateError, setStartDateError] = useState(false);
  const [hasEndDateError, setEndDateError] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [availableTo, setAvailableTo] = useState("member");

  //   Loading Current Form

  const { loading, error, data, refetch } = useQuery(GET_FORM, {
    variables: { id }
  });

  useEffect(() => {
    if (data) {
      const { formBuilder } = data;
      if (!formBuilder) {
        history.push("/dashboard/form-management");
        return;
      }
      const {
        title,
        slug,
        description,
        form,
        start_date,
        end_date,
        available_to
      } = formBuilder;
      const startDate = moment(start_date);
      const endDate = moment(end_date);
      setFormHeader(title);
      setFormSlug(slug);
      setFormDescription(description || "");
      setStartDate(startDate);
      setEndDate(endDate);
      setAvailableTo(available_to);
      setSelectedElements([...form]);
    }
  }, [data, history]);

  const onDragEnd = React.useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          setSelectedElements((state) => [
            ...reorder(state, source.index, destination.index)
          ]);
          break;
        case "TOOLBOX":
          setSelectedElements((state) => [
            ...copy(TOOLBOX_ELEMENTS, state, source, destination)
          ]);
          break;
        default:
          break;
      }
    },
    [setSelectedElements]
  );

  const onDelete = (itemId) => {
    const newItems = selectedElements.filter((item) => item.id !== itemId);
    setSelectedElements(newItems);
  };

  const onSettingField = (item, settings) => {
    const itemIndex = selectedElements.findIndex((i) => i.id === item.id);
    const newElements = [...selectedElements];
    newElements[itemIndex] = { ...item, settings };
    setSelectedElements(newElements);
  };

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setFormHeader(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onFormSlugUpdate = (e) => {
    const value = e.target.value;
    setFormSlug(value);
    if (!value) {
      setFormSlugError(true);
    } else if (/^[a-z0-9](-?[a-z0-9])*$/.test(value)) {
      setFormSlugError(false);
    } else {
      setFormSlugError(true);
    }
  };

  const onGenerateSlug = (e) => {
    const generatedSlug = slugify(formHeader || "", {
      replacement: "-",
      lower: true,
      remove: /[^-a-zA-Z0-9 ]/g
    });
    if (generatedSlug) setFormSlug(generatedSlug);
  };

  const onSlugCopy = () => {
    const url = "https://members.asme.org.sg/form/" + slug;
    navigator.clipboard &&
      navigator.clipboard.writeText(url).then(() =>
        enqueueSnackbar("Link is successfully copied to clipboard!", {
          variant: "success",
          preventDuplicate: true
        })
      );
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setFormDescription(value);
  };

  const onChangeStartDate = (date) => {
    if (date) {
      setStartDateError(false);
      setStartDate(date);
    } else {
      setStartDate(null);
      setStartDateError(true);
    }
  };
  const onChangeEndDate = (date) => {
    if (date) {
      setEndDateError(false);
      setEndDate(date);
    } else {
      setEndDate(null);
      setEndDateError(true);
    }
  };

  const [
    { loading: updating, data: updateData, error: updateError },
    updateForm
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(
        "There was some internal server error while saving the form. Please try again later.",
        {
          variant: "error"
        }
      );
    }
  }, [updateError, enqueueSnackbar]);

  useEffect(() => {
    if (updateData) {
      enqueueSnackbar("Form is updated successfully!", {
        variant: "success",
        preventDuplicate: true
      });
      refetch();
    }
  }, [updateData, enqueueSnackbar, refetch]);

  const getDate = (date) => {
    const day = moment(date).get("D");
    const month = moment(date).get("M");
    const year = moment(date).get("Y");

    const d = day < 10 ? `0${day}` : day;
    const m = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`;
    return `${year}-${m}-${d}T00:00:00.000Z`;
  };

  const onSave = async (status) => {
    if (!formHeader) {
      setFormHeaderError(true);
      return;
    }
    if (!slug) {
      setFormSlugError(true);
      return;
    }
    if (!startDate) {
      setStartDateError(true);
      return;
    }
    if (!endDate) {
      setEndDateError(true);
      return;
    }
    if (!availableTo) {
      enqueueSnackbar(
        <span>
          Please select any one option from <b>Available To</b>
        </span>,
        {
          variant: "error",
          preventDuplicate: true
        }
      );
      return;
    }

    const formData = {
      status,
      title: formHeader,
      slug,
      description: formDescription,
      start_date: getDate(startDate),
      end_date: getDate(endDate),
      available_to: availableTo,
      form: selectedElements.map(({ icon, ...rest }) => ({ ...rest }))
    };

    try {
      // Check if slug exists
      if (slug !== data.formBuilder.slug) {
        const response = await authedAxios.get(`/form-builders?slug=${slug}`);

        if (response.data.length > 0) {
          enqueueSnackbar(
            <span>
              The slug <b>{slug}</b> is already being used! Please use another
              slug.
            </span>,
            {
              variant: "error",
              preventDuplicate: true
            }
          );
          return;
        }
      }

      updateForm({
        data: formData,
        url: `/form-builders/${data.formBuilder.id}`
      });
    } catch (e) {
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the form. Please try again later.",
          {
            variant: "error",
            preventDuplicate: true
          }
        );
      }
    }
  };

  const onPreview = () => {
    setShowPreview(true);
  };

  return (
    <>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Edit Form
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/form-management">
          Form Management
        </Link>
        <Typography color="textPrimary">Edit Form</Typography>
      </Breadcrumbs>
      <FormBuilderContext.Provider
        value={{
          items: selectedElements,
          onDelete,
          onSettingField,
          title: formHeader
        }}
      >
        <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
          {!loading && !updating && data && (
            <>
              <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                <Grid item xs={6} sm={2}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    Form Builder
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={10} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "10px" }}
                    onClick={onPreview}
                  >
                    Preview
                  </Button>
                  <Chip
                    label={
                      data.formBuilder.status === "Draft"
                        ? "Draft"
                        : "Published"
                    }
                    color={
                      data.formBuilder.status === "Draft"
                        ? "default"
                        : "secondary"
                    }
                    style={{
                      marginRight: "10px"
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="default"
                    style={{ marginRight: "10px" }}
                    disabled={selectedElements.length === 0}
                    onClick={() => onSave("Draft")}
                  >
                    Save as Draft
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={selectedElements.length === 0}
                    onClick={() => onSave("Publish")}
                  >
                    Publish Form
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <TextField
                    label="Form Header"
                    placeholder="Please enter form title"
                    style={{ width: "100%" }}
                    variant="filled"
                    value={formHeader}
                    onChange={onFormHeaderUpdate}
                    error={hasFormHeaderError}
                    helperText={
                      hasFormHeaderError ? "Form header is requied!" : ""
                    }
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Form Description"
                    placeholder="Please enter form description (optional)"
                    variant="filled"
                    style={{ width: "100%" }}
                    value={formDescription}
                    onChange={onFormDescriptionUpdate}
                    multiline
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={
                      <span>
                        Form Slug
                        {slug && (
                          <small>
                            {" "}
                            (https://members.asme.org.sg/form/{slug})
                          </small>
                        )}
                      </span>
                    }
                    placeholder="i.e. ihl"
                    variant="filled"
                    style={{ width: "100%" }}
                    value={slug}
                    onChange={onFormSlugUpdate}
                    error={hasformSlugError}
                    helperText={
                      hasformSlugError
                        ? "Form slug is required and it must contain lowercase letters, numbers, or single hyphens only!"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {slug && !hasformSlugError && (
                            <IconButton
                              aria-label="copy slug"
                              onClick={onSlugCopy}
                              size="small"
                            >
                              <LinkOutlined />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="generate slug"
                            onClick={onGenerateSlug}
                            size="small"
                          >
                            <AutorenewOutlined />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <div style={{ display: "flex" }}>
                      <DatePicker
                        label="Start Date"
                        required
                        value={startDate}
                        onChange={(date) => onChangeStartDate(date)}
                        animateYearScrolling
                        style={{
                          marginRight: "10px",
                          height: "55px"
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: "10px",
                            paddingTop: startDate ? "7px" : "0px"
                          }
                        }}
                        InputProps={{
                          style: {
                            paddingLeft: "10px",
                            marginTop: "22px"
                          }
                        }}
                        className="date-filled-input"
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                        error={hasStartDateError}
                        helperText={
                          hasStartDateError
                            ? "Please select the start date!"
                            : ""
                        }
                      />
                      {startDate ? (
                        <DatePicker
                          required
                          label="End Date"
                          className="date-filled-input"
                          value={endDate}
                          onChange={(date) => onChangeEndDate(date)}
                          minDate={startDate}
                          minDateMessage={"The mininim date is not valid"}
                          format="DD/MM/yyyy"
                          style={{
                            height: "55px"
                          }}
                          InputLabelProps={{
                            style: {
                              paddingLeft: "10px",
                              paddingTop: endDate ? "7px" : "0px"
                            }
                          }}
                          InputProps={{
                            style: {
                              paddingLeft: "10px",
                              marginTop: "22px"
                            }
                          }}
                          animateYearScrolling
                          DialogProps={{
                            clearable: true
                          }}
                          error={hasEndDateError}
                          helperText={
                            hasEndDateError ? "Please select the end date!" : ""
                          }
                        />
                      ) : (
                        <DatePicker
                          required
                          className="date-filled-input"
                          label="End Date"
                          DialogProps={{
                            clearable: true
                          }}
                          value={endDate}
                          onChange={(date) => onChangeEndDate(date)}
                          format="DD/MM/yyyy"
                          animateYearScrolling
                          style={{
                            height: "55px"
                          }}
                          InputLabelProps={{
                            style: {
                              paddingLeft: "10px",
                              paddingTop: endDate ? "7px" : "0px"
                            }
                          }}
                          InputProps={{
                            style: {
                              paddingLeft: "10px",
                              marginTop: "22px"
                            }
                          }}
                          error={hasEndDateError}
                          helperText={
                            hasEndDateError ? "Please select the end date!" : ""
                          }
                        />
                      )}
                    </div>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginottom: "20px" }}>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>Available To</Grid>
                    {/* <Divider /> */}
                    <Grid item>
                      <RadioGroup
                        required
                        value={availableTo}
                        onChange={(e) => setAvailableTo(e.target.value)}
                        style={{ display: "flow-root" }}
                      >
                        <FormControlLabel
                          control={<Radio color="default" />}
                          label="Public"
                          value="public"
                        />
                        <FormControlLabel
                          control={<Radio color="default" />}
                          label="Members Only"
                          value="member"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>{" "}
                </Grid>
              </Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Grid container className={classes.root} spacing={2}>
                  <Grid xs={8} item>
                    <Paper variant="outlined">
                      <FormDropzone />
                    </Paper>
                  </Grid>
                  <Grid xs={4} item>
                    <Paper variant="outlined">
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ padding: "10px 0" }}
                      >
                        Toolbox
                      </Typography>
                      <Toolbox />
                    </Paper>
                  </Grid>
                </Grid>
              </DragDropContext>
            </>
          )}
          {!loading && error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              There was some internal server error while fetching records.
              Please try reloading the page.
            </Alert>
          )}
          {(loading || updating) && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </div>
          )}
        </Paper>
      </FormBuilderContext.Provider>
      <FormPreview
        key="edit-preview"
        open={showPreview}
        onHide={() => setShowPreview(false)}
        data={{
          items: selectedElements,
          title: formHeader
        }}
      />
    </>
  );
}

export default EditForm;
