import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ProtectedRoute } from "./util/ProtectedRoute";
import AuthContainer from "./containers/Auth/auth";
import Dashboard from "./containers/Dashboard/dashboard.container";
import Merchant from "./containers/MerchantView/merchant.container";

const AppRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/auth" />
    </Route>
    <ProtectedRoute key="dashboard" path="/dashboard">
      <Dashboard />
    </ProtectedRoute>
    <Route path="/auth" key="authentication">
      <AuthContainer />
    </Route>
    <Route path="/merchant-view" key="merchant">
      <Merchant />
    </Route>
    {/* Not Found */}
    <Route render={() => <h1>Not Found</h1>} />
  </Switch>
);

export default AppRoutes;
