import React, { useEffect } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

import { useAuthedAxios } from "../../../util/axios";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function MemberHome() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [
    {
      data: updateOrganizationsData,
      loading: updateOrganizationsLoading,
      error: updateOrganizationsError
    },
    updateSubmission
  ] = useAuthedAxios(
    {
      method: "POST",
      timeout: 25000
    },
    {
      manual: true,
      useCache: false
    }
  );

  useEffect(() => {
    if (updateOrganizationsData) {
      enqueueSnackbar("Updated Organizations Successfully!", {
        variant: "success",
        preventDuplicate: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, updateOrganizationsData]);

  useEffect(() => {
    if (updateOrganizationsError) {
      enqueueSnackbar(
        "There was some internal server error while updating the organizations. Please try again later.",
        {
          variant: "error",
          preventDuplicate: true
        }
      );
    }
  }, [enqueueSnackbar, updateOrganizationsError]);

  const submit = () => {
    updateSubmission({
      data: {},
      url: `/updateInsufficientOrganizationData`
    });
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Welcome to ASME Admin Portal
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        Future content will appear here...
        {/* <Typography variant="h6" color="red" style={{ fontWeight: "bold" }}>
          🚧 Temporary Custom Script Below (TODO: Will be removed after use) 🚧
        </Typography>
        <p>
          This function checks organizations which do not have Membership Type,
          Registration Date or Expiry Date.
        </p>
        <ul>
          <li>
            Adds Lite to the Organizations where the Membership Type is null
          </li>
          <li>Sets Registration Date if null to Created Date</li>
          <li>
            Sets Expiry Date if null to Registration Date + 1 Year or Created
            Date + 1 Year
          </li>
        </ul>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={submit}
          // className={classes.searchButton}
          type="submit"
        >
          Update Insufficient Data Of Organizations
        </Button> */}
      </Paper>
      <Backdrop className={classes.backdrop} open={updateOrganizationsLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default MemberHome;
