import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import { axios, BASE_URL } from "../util/axios";

const httpLink = createHttpLink({
  uri: `${BASE_URL}/graphql`,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("member_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const isAuthError = graphQLErrors.find(
      (r) => r.message === "Invalid token."
    );

    if (isAuthError) {
      axios.post("/logout").finally(() => window.location.reload());
    }
  }

  if (networkError) {
    // Handling Network Error
  }
});

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
