import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query {
    users(
      where: { role: { type: ["administrator", "staff", "super_staff"] } }
    ) {
      id
      confirmed
      blocked
      email
      username
      role {
        name
        type
      }
    }
  }
`;

export const GET_USER = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      email
      username
      role {
        name
        type
      }
      confirmed
      blocked
    }
  }
`;
