import React, { useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Button
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import XLSX from "xlsx";

import { authedAxios, BASE_URL } from "../../../../util/axios";
import { trimData } from "../../../../util/utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  searchButton: {
    marginTop: "17px",
    marginLeft: "5px"
  },
  goButton: {
    marginTop: "15px",
    marginLeft: "5px"
  }
}));

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

function UpdateMembers() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState("");
  const [arrayFromCSCV, setArrayFromCSCV] = useState([]);
  const [importErrors, setImportErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  async function importData() {
    setImportErrors([]);
    if (file && arrayFromCSCV.length === 0)
      return enqueueSnackbar("Please process triggers first", {
        variant: "error"
      });
    if (arrayFromCSCV.length === 0)
      return enqueueSnackbar(
        "Please choose a file and process triggers first",
        {
          variant: "error"
        }
      );
    const jsonArr = arrayFromCSCV.map((obj) => {
      trimData(obj);
      let newObj = { ...obj };
      const { registration_date, expiry_date, company_registration_no } =
        newObj;
      newObj = {
        company_registration_no,
        registration_date: new Date(registration_date).toISOString(),
        expiry_date: new Date(expiry_date).toISOString()
      };
      return newObj;
    });

    console.log(jsonArr);

    setLoading(true);

    try {
      const res = await authedAxios.post(
        `${BASE_URL}/updateIncorrectDataOfOrganizations`,
        {
          organizations: jsonArr
        }
      );
      setLoading(false);
      setImportErrors(res.data.errors);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Update Failed", {
        variant: "error"
      });
      setLoading(false);
      enqueueSnackbar(error.response.data.message, {
        variant: "error"
      });
    }
  }

  function handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) setFile(files[0]);
  }

  function handleFile() {
    if (!file)
      return enqueueSnackbar("Please choose a file first", {
        variant: "error"
      });
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: "binary",
        cellText: false,
        cellDates: true
      });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        dateNF: "yyyy-mm-dd"
      });
      /* Update state */
      setArrayFromCSCV(data);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Member Management
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "40px", display: "grid" }}
      >
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Update Organisations Dates
        </Typography>
        <br />
        <ul>
          <li>
            From the excel it will take each UEN (company_registration_no ) and
            find that Organization
          </li>
          <li>
            Update the Registration (registration_date) and Expiry Date
            (expiry_date ) for that Organization (with dates from excel)
          </li>
          <li>
            Please keep the headers in excel as company_registration_no,
            registration_date and expiry_date to map them properly in DB
          </li>
        </ul>
        {importErrors.length > 0 && (
          <div>
            <Typography
              variant="h5"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              Update Errors:
            </Typography>
            <ul>
              {importErrors.map((error) => (
                <li style={{ color: "red" }} key={error}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div
          className={classes.main_filter_div}
          style={{ justifyContent: "flex-end" }}
        >
          <div
            style={{
              marginTop: "15px"
            }}
          >
            <input
              type="file"
              className="form-control"
              id="file"
              accept={SheetJSFT}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classes.searchButton}
              onClick={handleFile}
            >
              Process Triggers
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classes.goButton}
              onClick={importData}
            >
              Update data{" "}
            </Button>
          </div>
        </div>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </Paper>
    </div>
  );
}

export default UpdateMembers;
