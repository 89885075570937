import React from "react";
import {
  CHECKBOX,
  CHECKBOX_GROUP,
  FILE_UPLOAD,
  PARAGRAPH,
  PLAIN_TEXT,
  RADIO_BUTTONS,
  TEXT_FIELD,
} from "../constants";
import CheckboxFieldRenderer from "./CheckboxField";
import CheckboxGroupRenderer from "./CheckboxGroup";
import FileInputRenderer from "./FileInput";
import ParagraphFieldRenderer from "./ParagraphField";
import PlainTextRenderer from "./PlainText";
import RadioButtonsRenderer from "./RadioButtons";
import TextFieldRenderer from "./TextField";

function FieldRenderer({ type, ...rest }) {
  switch (type) {
    case TEXT_FIELD:
      return <TextFieldRenderer {...rest} />;
    case PARAGRAPH:
      return <ParagraphFieldRenderer {...rest} />;
    case CHECKBOX:
      return <CheckboxFieldRenderer {...rest} />;
    case CHECKBOX_GROUP:
      return <CheckboxGroupRenderer {...rest} />;
    case RADIO_BUTTONS:
      return <RadioButtonsRenderer {...rest} />;
    case FILE_UPLOAD:
      return <FileInputRenderer {...rest} />;
    case PLAIN_TEXT:
      return <PlainTextRenderer {...rest} />;
    default:
      return "";
  }
}

export default FieldRenderer;
