import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import { renderItem } from "../../../../../components/form-builder/Renderer/renderer";

import "./form-preview.style.css";

function FormPreview({
  open,
  onHide = () => null,
  data = { items: [], title: "" },
}) {
  return (
    <Dialog
      open={open}
      onClose={onHide}
      scroll={"paper"}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Form Preview</DialogTitle>
      <DialogContent dividers>
        <FormHeader title={data.title} description="" />
        {data.items.map((item) => (
          <Grid item xs={12} key={item.id} style={{ padding: "10px 0" }}>
            {renderItem(
              item,
              {
                member: {},
                organization: {},
              },
              {
                register: () => null,
                setValue: () => null,
                watch: () => null,
                errors: [],
              }
            )}
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormPreview.propTypes = {
  onHide: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default React.memo(FormPreview, (prev, next) => prev.open === next.open);

const FormHeader = ({ title, description }) => (
  <Grid container className="fb-form-header">
    <Grid item xs={12} sm={12} md={8} lg={9}>
      <h2>{title}</h2>
    </Grid>
    <Grid item xs={12} sm={12} md={4} lg={3} className="logo-wrapper">
      <img src="/images/logo.jpg" width="120px" alt="ASME Logo" />
    </Grid>
  </Grid>
);
