import React, { useContext } from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import FormBuilderContext from "../form-builder-context";
import debounce from "lodash.debounce";

function CheckboxFieldEditor({ item }) {
  const { onSettingField } = useContext(FormBuilderContext);

  const debouncedSettingsChange = debounce(
    (item, settings) => onSettingField(item, settings),
    400
  );

  const onTitleChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, title: value } : { title: value }
    );
  };

  const onHelpTextChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, help: value } : { help: value }
    );
  };

  const onRequiredChange = (e, required) => {
    onSettingField(
      item,
      item.settings ? { ...item.settings, required } : { required }
    );
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      <Typography variant="h6">Edit Checkbox Field</Typography>
      <Divider light />

      <TextField
        label="Field Title"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onTitleChange}
        defaultValue={
          item.settings && item.settings.title ? item.settings.title : ""
        }
      />

      <TextField
        label="Help Text"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onHelpTextChange}
        defaultValue={
          item.settings && item.settings.help ? item.settings.help : ""
        }
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={item.settings && item.settings.required ? true : false}
            onChange={onRequiredChange}
            name="required"
          />
        }
        label="Required"
      />
    </div>
  );
}

export default CheckboxFieldEditor;
