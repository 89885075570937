import React from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../auth.api";
import { ACTIVE } from "../../../app/constants";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Login() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();

  const isLoggingIn = useSelector((state) => state.auth.status === ACTIVE);
  const loginError = useSelector((state) => state.auth.error);

  const onSubmit = (data) => {
    dispatch(login(data.email, data.password));
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh", background: "#E6E6E6" }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        style={{
          textAlign: "center",
          marginBottom: "10%",
        }}
      >
        <img src="/images/logo.png" alt="ASME Logo" width={"100px"} />
        <br />
        <br />
        <Paper elevation={3} style={{ padding: "10px" }}>
          <h3 className="primary">ASME Admin Login</h3>
          {loginError && (
            <>
              <Alert severity="error">Invalid Credentials!</Alert>
              <br />
            </>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              inputRef={register({
                required: "This field cannot be empty!",
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Please enter a valid email address!",
                },
              })}
              name="email"
              label="Email"
              error={errors.email && errors.email.message ? true : false}
              helperText={errors.email ? errors.email.message : null}
              style={{ width: "90%" }}
            />
            <br />
            <br />
            <TextField
              inputRef={register({
                required: "This field cannot be empty!",
              })}
              name="password"
              label="Password"
              type="password"
              error={errors.password && errors.password.message ? true : false}
              helperText={errors.password ? errors.password.message : null}
              style={{ width: "90%" }}
            />

            <br />
            <br />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className="primary"
            >
              Submit
            </Button>
          </form>
          <br />
          <Button onClick={() => history.push("/auth/forgot-password")}>
            Forgot Password?
          </Button>
        </Paper>
      </Grid>
      {isLoggingIn && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="secondary" />
        </Backdrop>
      )}
    </Grid>
  );
}

export default Login;
