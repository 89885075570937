import React from "react";
import moment from "moment";

function OrgInfo({ classes, organization }) {
  return (
    <div>
      <table
        style={{
          borderCollapse: "collapse",
          wordWrap: "break-word",
          tableLayout: "fixed",
        }}
        cellPadding="10"
      >
        <tbody>
          <tr>
            <td
              className={classes.infoCellStyle}
              style={{
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              Organisation Name *
            </td>
            <td className={classes.infoCellLeftMargin}>{organization.name}</td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>UEN</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.company_registration_no ?? "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Year of Incorporation</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.year_of_incorporation
                ? `${moment(organization.year_of_incorporation).format(
                    "DD MMMM YYYY"
                  )}`
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Phone</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.phone ? organization.phone : "-"}
            </td>
          </tr>
          {/* <tr>
            <td className={classes.infoCellStyle}>Fax</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.fax ? organization.fax : "-"}
            </td>
          </tr> */}
          <tr>
            <td className={classes.infoCellStyle}>Staff Strength</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.staff_strength ? organization.staff_strength : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>
              Principal Business Activity
            </td>
            <td className={classes.infoCellLeftMargin}>
              {organization.principal_business_activity
                ? organization.principal_business_activity
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>
              Secondary Business Activity
            </td>
            <td className={classes.infoCellLeftMargin}>
              {organization.secondary_business_activity
                ? organization.secondary_business_activity
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Annual Turnover (SGD)</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.annual_turnover
                ? organization.annual_turnover
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Paid up capital (SGD)</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.paid_up_capital
                ? organization.paid_up_capital
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Industry Classification</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.industry_classification &&
              organization.industry_classification.length > 0
                ? organization.industry_classification
                    .map((i) => i.title)
                    .join(", ")
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Website</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.website ? organization.website : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Facebook</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.facebook ? organization.facebook : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Legal Structure of Co.</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.legal_structure
                ? organization.legal_structure
                : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Ownership Type</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.ownership_type ? organization.ownership_type : "-"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Nature of Business</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.nature_of_business
                ? organization.nature_of_business
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              className={classes.infoCellStyle}
              style={{
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              Description of Business
            </td>
            <td className={classes.infoCellLeftMargin}>
              {organization.company_description
                ? organization.company_description
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OrgInfo;
