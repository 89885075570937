import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  Modal,
  Divider
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ImageUploader from "react-images-upload";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import XLSX from "xlsx";

import {
  authedAxios,
  BASE_URL,
  useAuthedAxios
} from "../../../../../util/axios";
import "../../FormManagement/FormBuilder/style.css";
import { isUrlValid, makeIdsArray } from "../../../../../util/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  searchButton: {
    marginTop: "17px",
    marginLeft: "5px"
  },
  goButton: {
    marginTop: "15px",
    marginLeft: "5px"
  }
}));

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

function ListingBuilder() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [formHeader, setFormHeader] = useState();
  const [formDescription, setFormDescription] = useState();
  const [formTnC, setFormTnC] = useState();
  const [merchant, setMerchant] = useState();
  const [memberships, setMemberships] = useState({
    Lite: false,
    Corporate: false,
    Platinum: true,
    Associate: true
  });
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [link, setLink] = useState("");
  const [voucherPrefix, setVoucherPrefix] = useState("");
  const [noOfVouchers, setNoOfVouchers] = useState(1);
  const [unlimitedUsage, setUnlimitedUsage] = useState(false);
  const [generatedVouchersList, setGeneratedVouchersList] = useState([]);
  const [voucherGeneration, setVoucherGeneration] = useState("RealTime");
  const [hasFormHeaderError, setFormHeaderError] = useState(false);
  const [hasVoucherPrefixError, setHasVoucherPrefixError] = useState(false);
  const [hasNoOfVouchersError, setHasNoOfVouchersError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hasStartDateError, setStartDateError] = useState(false);
  const [hasEndDateError, setEndDateError] = useState(false);
  const [merchantError, setMerchantError] = useState(false);
  const [isImageModalVisible, setModalVisibility] = useState(false);
  const [isThumbnailModalVisible, setThumbnailModalVisibility] =
    useState(false);
  const [picture, setPicture] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [pictureURL, setPictureURL] = useState();
  const [thumbnailURL, setThumbnailURL] = useState();
  const [file, setFile] = useState("");
  const [arrayFromCSCV, setArrayFromCSCV] = useState([]);
  const [hasVoucherLinkError, setHasVoucherLinkError] = useState(false);

  const [
    {
      data: merchantsData
      // loading: loadingMerchants,
      // error: errorFetchingMerchants
    },
    loadMerchants
  ] = useAuthedAxios(
    {
      url: `/merchants`
    },
    {
      manual: true
      // useCache: false,
    }
  );

  const [
    {
      data: membershipsData
      // loading: loadingMemberships,
      // error: errorFetchingMemberships
    },
    loadMemberships
  ] = useAuthedAxios(
    {
      url: `/memberships`
    },
    {
      manual: true
      // useCache: false,
    }
  );

  const [
    { loading: loadingListingData, data: listingData, error: errorListingData },
    savePrivateForm
  ] = useAuthedAxios(
    {
      url: "/listing-builders",
      method: "POST"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    loadMerchants();
    loadMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorListingData) {
      enqueueSnackbar(
        "There was some internal server error while saving the listing. Please try again later.",
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorListingData]);

  useEffect(() => {
    if (listingData) {
      enqueueSnackbar("Listing is saved successfully!", {
        variant: "success"
      });
      history.push("/dashboard/listing-management", {
        update: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingData]);

  const handleImageModalClose = () => {
    setModalVisibility(false);
  };

  const handleThumbnailModalClose = () => {
    setThumbnailModalVisibility(false);
  };

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setFormHeader(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onVoucherPrefixUpdate = (e) => {
    const value = e.target.value;
    setVoucherPrefix(value);
    setGeneratedVouchersList([]);

    if (!value) {
      setHasVoucherPrefixError(true);
    } else {
      setHasVoucherPrefixError(false);
    }
  };

  const onNoOfVouchersUpdate = (e) => {
    const value = e.target.value;
    if (value < 1) return;
    setNoOfVouchers(value);
    setGeneratedVouchersList([]);

    if (!value) {
      setHasNoOfVouchersError(true);
    } else {
      setHasNoOfVouchersError(false);
    }
  };

  const onChangeStartDate = (date) => {
    if (date) {
      setStartDateError(false);
      setStartDate(date);
    } else {
      setStartDate(null);
      setStartDateError(true);
    }
  };

  const onChangeEndDate = (date) => {
    if (date) {
      setEndDateError(false);
      setEndDate(date);
    } else {
      setEndDate(null);
      setEndDateError(true);
    }
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setFormDescription(value);
  };

  const onFormDiscountAmountUpdate = (e) => {
    const value = e.target.value;
    if (parseInt(value) < 0) return;
    setDiscountAmount(value);
  };

  const onFormDiscountTypeUpdate = (e) => {
    const value = e.target.value;
    setDiscountType(value);
  };

  const onFormLinkUpdate = (event) => {
    setLink(event.target.value);
    if (!event.target.value) {
      setHasVoucherLinkError(true);
    } else {
      setHasVoucherLinkError(false);
    }
  };

  const handleMerchantChange = (event) => {
    setMerchant(event.target.value);
    if (!event.target.value) {
      setMerchantError(true);
    } else {
      setMerchantError(false);
    }
  };

  const handleUnlimitedUsageChange = () => {
    setUnlimitedUsage(!unlimitedUsage);
  };

  const handleMembershipChange = (event) => {
    setMemberships({
      ...memberships,
      [event.target.name]: event.target.checked
    });
  };

  const onFormTnCUpdate = (e) => {
    const value = e.target.value;
    setFormTnC(value);
  };

  const getDate = (date) => {
    const day = moment(date).get("D");
    const month = moment(date).get("M");
    const year = moment(date).get("Y");

    const d = day < 10 ? `0${day}` : day;
    const m = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`;
    return `${year}-${m}-${d}T00:00:00.000Z`;
  };

  const onImageDrop = (pictureFiles, pictureDataURLs) => {
    setPicture(pictureFiles);
    setPictureURL(pictureDataURLs);
  };

  const onThumbnailDrop = (pictureFiles, pictureDataURLs) => {
    setThumbnail(pictureFiles);
    setThumbnailURL(pictureDataURLs);
  };

  const onSave = async (status) => {
    if (!formHeader) {
      setFormHeaderError(true);
      return;
    }
    if (!merchant) {
      setMerchantError(true);
      return;
    }
    if (!startDate) {
      setStartDateError(true);
      return;
    }
    if (!endDate) {
      setEndDateError(true);
      return;
    }
    if (
      voucherGeneration !== "PreGeneratedMerchant" &&
      voucherGeneration !== "Link"
    ) {
      if (!voucherPrefix) {
        setHasVoucherPrefixError(true);
        return;
      }
      if (!noOfVouchers) {
        setHasNoOfVouchersError(true);
        return;
      }
    }
    if (voucherGeneration === "Link") {
      if (!link) {
        setHasVoucherLinkError(true);
        return;
      }
      if (isUrlValid(link) === false)
        return enqueueSnackbar("Please enter valid URL", {
          variant: "error"
        });
    }

    // add Vouchers to API and add the array of those IDs to vouchers field
    const vouchersResponse = await authedAxios.post("/bulkVouchers", {
      vouchers: generatedVouchersList
    });

    const [image] = picture;
    const [thumbnailImage] = thumbnail;

    const data = {
      status,
      title: formHeader,
      description: formDescription,
      start_date: getDate(startDate),
      end_date: getDate(endDate),
      available_to: memberships,
      tnc: formTnC,
      voucher_generation: voucherGeneration,
      voucher_prefix: voucherPrefix,
      number_of_vouchers: noOfVouchers,
      unlimited_vouchers: unlimitedUsage,
      discount_type: discountType,
      merchant,
      vouchers: vouchersResponse.data,
      merchant_link: link
    };
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));

      image && formData.append("files.image", image, image.name);
      thumbnailImage &&
        formData.append("files.thumbnail", thumbnailImage, thumbnailImage.name);

      savePrivateForm({
        data: formData
      });
    } catch (e) {
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the form. Please try again later.",
          {
            variant: "error"
          }
        );
      }
    }
  };

  const onGenerateVouchersList = () => {
    if (!voucherPrefix)
      return enqueueSnackbar("Please specify Voucher Prefix.", {
        variant: "error"
      });
    if (!noOfVouchers)
      return enqueueSnackbar("Please specify No Of Vouchers to be generated.", {
        variant: "error"
      });

    setGeneratedVouchersList(makeIdsArray(voucherPrefix, noOfVouchers));
  };

  function handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) setFile(files[0]);
  }

  function handleFile() {
    if (!file)
      return enqueueSnackbar("Please choose a file first", {
        variant: "error"
      });
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false
      });
      /* Update state */
      setArrayFromCSCV(data);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  async function importData() {
    if (file && arrayFromCSCV.length === 0)
      return enqueueSnackbar("Please process triggers first", {
        variant: "error"
      });
    if (arrayFromCSCV.length === 0)
      return enqueueSnackbar(
        "Please choose a file and process triggers first",
        {
          variant: "error"
        }
      );
    const jsonArr = arrayFromCSCV.map((obj) => {
      const { voucher } = obj;
      return voucher;
    });

    console.log(jsonArr);
    setGeneratedVouchersList(jsonArr);
  }

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Listing Builder
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/listing-management">
          Listing Management
        </Link>
        <Typography color="textPrimary">Build New Listing</Typography>
      </Breadcrumbs>

      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              New Listing
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="default"
              style={{ marginRight: "10px" }}
              onClick={() => onSave("Draft")}
            >
              Save as Draft
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onSave("Publish")}
            >
              Publish Listing
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <TextField
              label="Listing Title"
              placeholder="Please enter listing title"
              style={{ width: "100%" }}
              variant="filled"
              onChange={onFormHeaderUpdate}
              error={hasFormHeaderError}
              helperText={hasFormHeaderError ? "Listing Title is requied!" : ""}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Merchant</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={merchant || 0}
                onChange={handleMerchantChange}
                error={merchantError}
                helperText={merchantError ? "Merchant is requied!" : ""}
              >
                {merchantsData &&
                  merchantsData.map((merchant) => (
                    <MenuItem key={merchant.id} value={merchant.id}>
                      {merchant.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Listing Description"
              placeholder="Please enter listing description (optional)"
              variant="filled"
              style={{ width: "100%" }}
              value={formDescription}
              onChange={onFormDescriptionUpdate}
              multiline
              rows={5}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Terms & Conditions"
              placeholder="Please enter Terms & Conditions (optional)"
              variant="filled"
              style={{ width: "100%" }}
              value={formTnC}
              onChange={onFormTnCUpdate}
              multiline
              rows={5}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Available To</FormLabel>
              <FormGroup>
                {membershipsData &&
                  membershipsData
                    .sort((a, b) => a.annual_price - b.annual_price)
                    .map((membership) => (
                      <FormControlLabel
                        key={membership.id}
                        id={membership.id}
                        control={
                          <Checkbox
                            checked={memberships[membership.name]}
                            onChange={handleMembershipChange}
                            name={membership.name}
                          />
                        }
                        label={membership.name}
                      />
                    ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1" color={"secondary"}>
              <div style={{ display: "flex" }}>
                <DatePicker
                  label="Start Date"
                  required
                  value={startDate}
                  onChange={(date) => onChangeStartDate(date)}
                  animateYearScrolling
                  style={{
                    marginRight: "10px",
                    height: "55px"
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: "10px",
                      paddingTop: startDate ? "7px" : "0px"
                    }
                  }}
                  InputProps={{
                    style: {
                      paddingLeft: "10px",
                      marginTop: "22px"
                    }
                  }}
                  className="date-filled-input"
                  format="DD/MM/yyyy"
                  DialogProps={{
                    clearable: true
                  }}
                  error={hasStartDateError}
                  helperText={
                    hasStartDateError ? "Please select the start date!" : ""
                  }
                />
                {startDate ? (
                  <DatePicker
                    required
                    label="End Date"
                    className="date-filled-input"
                    value={endDate}
                    onChange={(date) => onChangeEndDate(date)}
                    minDate={startDate}
                    minDateMessage={"The mininim date is not valid"}
                    format="DD/MM/yyyy"
                    style={{
                      height: "55px"
                    }}
                    InputLabelProps={{
                      style: {
                        paddingLeft: "10px",
                        paddingTop: endDate ? "7px" : "0px"
                      }
                    }}
                    InputProps={{
                      style: {
                        paddingLeft: "10px",
                        marginTop: "22px"
                      }
                    }}
                    animateYearScrolling
                    DialogProps={{
                      clearable: true
                    }}
                    error={hasEndDateError}
                    helperText={
                      hasEndDateError ? "Please select the end date!" : ""
                    }
                  />
                ) : (
                  <DatePicker
                    required
                    className="date-filled-input"
                    label="End Date"
                    DialogProps={{
                      clearable: true
                    }}
                    value={endDate}
                    onChange={(date) => onChangeEndDate(date)}
                    format="DD/MM/yyyy"
                    animateYearScrolling
                    style={{
                      height: "55px"
                    }}
                    InputLabelProps={{
                      style: {
                        paddingLeft: "10px",
                        paddingTop: endDate ? "7px" : "0px"
                      }
                    }}
                    InputProps={{
                      style: {
                        paddingLeft: "10px",
                        marginTop: "22px"
                      }
                    }}
                    error={hasEndDateError}
                    helperText={
                      hasEndDateError ? "Please select the end date!" : ""
                    }
                  />
                )}
              </div>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Discount Amount (%)"
              placeholder="Please enter Discount Amount (%)"
              type="number"
              style={{ width: "100%" }}
              variant="filled"
              onChange={onFormDiscountAmountUpdate}
              value={discountAmount}
              // error={hasFormHeaderError}
              // helperText={
              //   hasFormHeaderError ? "Discount Amount is requied!" : ""
              // }
              // required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Discount Type"
              placeholder="Please enter Discount Type"
              style={{ width: "100%" }}
              variant="filled"
              onChange={onFormDiscountTypeUpdate}
              // error={hasFormHeaderError}
              // helperText={
              //   hasFormHeaderError ? "Discount Type is requied!" : ""
              // }
              // required
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={3}>
              <Grid item>Voucher Generation</Grid>
              <Grid item>
                <RadioGroup
                  value={voucherGeneration}
                  onChange={(e) => {
                    setVoucherGeneration(e.target.value);
                    setGeneratedVouchersList([]);
                  }}
                >
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Real-Time"
                    value="RealTime"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Pre-Generated by ASME"
                    value="PreGeneratedASME"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Pre-Generated by Merchant"
                    value="PreGeneratedMerchant"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Link"
                    value="Link"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid item>Thumbnail</Grid>
            <div
              onClick={() => setThumbnailModalVisibility(true)}
              style={{
                height: "170px",
                maxWidth: "150px",
                display: "flex",
                alignItems: "center"
              }}
            >
              {thumbnailURL ? (
                <img
                  src={thumbnailURL}
                  alt="Thumbnail"
                  style={{
                    width: "100%",
                    height: "150px"
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "rgba(0, 0, 0, 0.15)"
                  }}
                >
                  <Typography
                    variant="h6"
                    // color={"secondary"}
                    style={{ padding: "35px 25px 35px 20px" }}
                  >
                    <b>Upload Thumbnail</b>
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <Grid item>Image</Grid>
            <div
              onClick={() => setModalVisibility(true)}
              style={{
                height: "170px",
                maxWidth: "150px",
                display: "flex",
                alignItems: "center"
              }}
            >
              {pictureURL ? (
                <img
                  src={pictureURL}
                  alt="Voucher"
                  style={{
                    width: "100%",
                    height: "150px"
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    backgroundColor: "rgba(0, 0, 0, 0.15)"
                  }}
                >
                  <Typography
                    variant="h6"
                    // color={"secondary"}
                    style={{ padding: "35px" }}
                  >
                    <b>Upload Image</b>
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
          {voucherGeneration !== "PreGeneratedMerchant" &&
            voucherGeneration !== "Link" && (
              <>
                <Grid item xs={4}>
                  <TextField
                    label="Voucher Prefix"
                    placeholder="Please enter Voucher Prefix"
                    style={{ width: "100%" }}
                    variant="filled"
                    value={voucherPrefix}
                    onChange={onVoucherPrefixUpdate}
                    error={hasVoucherPrefixError}
                    helperText={
                      hasVoucherPrefixError ? "Voucher Prefix is requied!" : ""
                    }
                    required
                  />
                </Grid>
                {(voucherGeneration === "PreGeneratedASME" ||
                  (voucherGeneration === "RealTime" && !unlimitedUsage)) && (
                  <Grid item xs={4}>
                    <TextField
                      label="No. of Vouchers"
                      placeholder="Please enter No. of Vouchers"
                      type="number"
                      style={{ width: "100%" }}
                      variant="filled"
                      value={noOfVouchers}
                      onChange={onNoOfVouchersUpdate}
                      error={hasNoOfVouchersError}
                      helperText={
                        hasNoOfVouchersError
                          ? "No. of Vouchers is requied!"
                          : ""
                      }
                      required
                    />
                  </Grid>
                )}

                {voucherGeneration === "RealTime" && (
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={unlimitedUsage}
                          onChange={handleUnlimitedUsageChange}
                          name="No Limit"
                        />
                      }
                      label="No Limit"
                    />
                  </Grid>
                )}
              </>
            )}
          {voucherGeneration === "Link" && (
            <>
              <Grid item xs={4}>
                <TextField
                  label="Voucher Link"
                  placeholder="Please enter Voucher Link"
                  style={{ width: "100%" }}
                  variant="filled"
                  value={link}
                  onChange={onFormLinkUpdate}
                  error={hasVoucherLinkError}
                  helperText={
                    hasVoucherLinkError ? "Voucher Link is requied!" : ""
                  }
                  required
                />
              </Grid>
            </>
          )}
        </Grid>
        {voucherGeneration === "PreGeneratedMerchant" && (
          <>
            <a href={`${BASE_URL}/uploads/vouchers_ecd1defd05.xlsx`}>
              Download Voucher Upload Template
            </a>
            <Grid item xs>
              <div style={{ textAlign: "center" }}>
                <input
                  type="file"
                  className="form-control"
                  id="file"
                  accept={SheetJSFT}
                  onChange={handleChange}
                />
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  className={classes.searchButton}
                  onClick={handleFile}
                >
                  Process Triggers
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  className={classes.goButton}
                  onClick={importData}
                >
                  Upload List
                </Button>
              </div>
            </Grid>
          </>
        )}
        {voucherGeneration === "PreGeneratedASME" && (
          <Grid item xs>
            <div style={{ textAlign: "center" }}>
              <Button
                color="secondary"
                disabled={generatedVouchersList.length > 0}
                variant="outlined"
                onClick={() => {
                  onGenerateVouchersList();
                }}
              >
                Generate List
              </Button>
            </div>
          </Grid>
        )}
        {generatedVouchersList.length > 0 && (
          <>
            <Grid style={{ marginBottom: "20px", marginTop: "20px" }}>
              Generated Vouchers
              <ul>
                {generatedVouchersList.map((voucher) => (
                  <li key={voucher}>{voucher}</li>
                ))}
              </ul>
            </Grid>
          </>
        )}
      </Paper>
      <Backdrop className={classes.backdrop} open={loadingListingData}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Modal
        open={isImageModalVisible}
        onClose={handleImageModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          className={classes.modal}
          style={{
            position: "absolute",
            width: "50%",
            // height: "35%",
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            border: "none"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Image</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleImageModalClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onImageDrop}
            buttonStyles={{ background: "#de1736", color: "#fff" }}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Image"
            singleImage
            withPreview
          />
          {picture && picture.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleImageModalClose();
                  // onImageChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={isThumbnailModalVisible}
        onClose={handleThumbnailModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          className={classes.modal}
          style={{
            position: "absolute",
            width: "50%",
            // height: "35%",
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            border: "none"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Thumbnail Image</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleThumbnailModalClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onThumbnailDrop}
            buttonStyles={{ background: "#de1736", color: "#fff" }}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Thumbnail"
            singleImage
            withPreview
          />
          {thumbnail && thumbnail.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleThumbnailModalClose();
                  // onImageChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ListingBuilder;
