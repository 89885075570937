import React from "react";
import { Paper, Typography } from "@material-ui/core";
import OrganisationProfile from "../OrganisationInfo/OrganisationProfile";
import { useParams } from "react-router-dom";

function MemberDetails() {
  const { id } = useParams();
  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Member
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "40px", display: "grid" }}
      >
        <OrganisationProfile organizationID={id} />
      </Paper>
    </div>
  );
}

export default MemberDetails;
