import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

import { useAuthedAxios } from "../../../../util/axios";
import ActiveItems from "./ActiveItems";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const ActivePolls = () => {
  const classes = useStyles();

  const [{ data, loading, error }] = useAuthedAxios(
    {
      url: `/poll-builders?`
    },
    {
      useCache: false
    }
  );

  return (
    <div>
      {!error && data && (
        <ActiveItems
          items={data?.filter((reward) => reward.status === "Publish")}
        />
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
};

export default ActivePolls;
