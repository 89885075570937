import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useAuthedAxios } from "../../../../../util/axios";
import "../../FormManagement/FormBuilder/style.css";
import { GET_MERCHANT } from "../merchants.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function EditMerchant() {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [hasFormHeaderError, setFormHeaderError] = React.useState(false);
  const [hasFormEmailError, setHasFormEmailError] = React.useState(false);
  const [hasFormPasswordError, setHasFormPasswordError] = React.useState(false);

  //   Loading Current Form
  const {
    loading,
    error,
    data: merchantData,
    refetch
  } = useQuery(GET_MERCHANT, {
    variables: { id }
  });

  useEffect(() => {
    if (merchantData) {
      const { merchant } = merchantData;
      if (!merchant) {
        history.push("/dashboard/poll-management");
        return;
      }
      const { name, email, password } = merchant;
      setName(name);
      setEmail(email);
      setPassword(password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantData, history]);

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setName(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!value) {
      setHasFormEmailError(true);
    } else {
      setHasFormEmailError(false);
    }
  };

  const onFormPasswordUpdate = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (!value) {
      setHasFormPasswordError(true);
    } else {
      setHasFormPasswordError(false);
    }
  };

  const [
    { loading: updating, data: updateData, error: updateError },
    updateForm
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(
        "There was some internal server error while saving the merchant. Please try again later.",
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    if (updateData) {
      enqueueSnackbar("Merchant is saved successfully!", {
        variant: "success"
      });
      history.push("/dashboard/merchant-management", {
        update: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  const onSave = async (status) => {
    if (!name) {
      setFormHeaderError(true);
      return;
    }

    const data = {
      name,
      email,
      password
    };
    try {
      updateForm({
        data,
        url: `/merchants/${merchantData.merchant.id}`
      });
    } catch (e) {
      console.log(e);
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the merchant. Please try again later.",
          {
            variant: "error"
          }
        );
      }
    }
  };

  return (
    <div>
      {/* <button onClick={() => picture && onImageChange()}>Upload</button> */}
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Edit Merchant
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/merchant-management">
          Merchant Management
        </Link>
        <Typography color="textPrimary">Build New Merchant</Typography>
      </Breadcrumbs>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              Edit Merchant
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onSave("Publish")}
            >
              Update Merchant
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <TextField
              label="Merchant Name"
              placeholder="Please enter merchant name"
              style={{ width: "100%" }}
              variant="filled"
              value={name}
              onChange={onFormHeaderUpdate}
              error={hasFormHeaderError}
              helperText={hasFormHeaderError ? "Name is requied!" : ""}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Merchant Email"
              type="email"
              placeholder="Please enter merchant email"
              variant="filled"
              style={{ width: "100%" }}
              value={email}
              onChange={onFormDescriptionUpdate}
              error={hasFormEmailError}
              helperText={hasFormEmailError ? "Email is requied!" : ""}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Merchant Password"
              type="password"
              placeholder="Please enter merchant password"
              variant="filled"
              style={{ width: "100%" }}
              value={password}
              onChange={onFormPasswordUpdate}
              error={hasFormPasswordError}
              helperText={hasFormPasswordError ? "Password is requied!" : ""}
              required
            />
          </Grid>
        </Grid>
      </Paper>
      <Backdrop className={classes.backdrop} open={updating}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default EditMerchant;
