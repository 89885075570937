import React, { useContext, useEffect } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import FormBuilderContext from "../form-builder-context";
import { useForm } from "react-hook-form";
import { RemoveCircleOutline } from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import debounce from "lodash.debounce";

function RadioButtonsEditor({ item }) {
  const { onSettingField } = useContext(FormBuilderContext);

  const debouncedSettingsChange = debounce(
    (item, settings) => onSettingField(item, settings),
    400
  );

  const onTitleChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, title: value } : { title: value }
    );
  };

  const onHelpTextChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, help: value } : { help: value }
    );
  };

  const onRequiredChange = (e, required) => {
    onSettingField(
      item,
      item.settings ? { ...item.settings, required } : { required }
    );
  };

  const onAddOption = () => {
    onSettingField(
      item,
      item.settings
        ? {
            ...item.settings,
            options: [
              ...item.settings.options,
              {
                id: uuid(),
                value: "Option",
              },
            ],
          }
        : {
            options: [
              ...item.settings.options,
              {
                id: uuid(),
                value: "Option",
              },
            ],
          }
    );
  };

  const onOptionChange = (id, newVal) => {
    const options = item.settings.options.map((option) =>
      option.id === id
        ? {
            ...option,
            value: newVal,
          }
        : option
    );
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, options } : { options }
    );
  };

  const onRemoveOption = (id) => {
    const options = item.settings.options.filter((option) => option.id !== id);
    onSettingField(
      item,
      item.settings ? { ...item.settings, options } : { options }
    );
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      <Typography variant="h6">Edit Radio Buttons</Typography>
      <Divider light />

      <TextField
        label="Field Title"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onTitleChange}
        defaultValue={
          item.settings && item.settings.title ? item.settings.title : ""
        }
      />

      <TextField
        label="Help Text"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onHelpTextChange}
        defaultValue={
          item.settings && item.settings.help ? item.settings.help : ""
        }
      />

      <Typography variant="subtitle1">
        <b>Options</b>
      </Typography>
      {item.settings.options.map((option, index) => {
        if (index < 2) {
          return (
            <TextField
              key={option.id}
              defaultValue={`${option.value}`}
              style={{
                width: "100%",
                margin: "10px 0",
              }}
              onChange={(e) => onOptionChange(option.id, e.target.value)}
            />
          );
        } else {
          return (
            <Grid key={option.id} container spacing={3}>
              <Grid item xs={10}>
                <TextField
                  defaultValue={`${option.value}`}
                  style={{
                    width: "100%",
                    margin: "10px 0",
                  }}
                  onChange={(e) => onOptionChange(option.id, e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => onRemoveOption(option.id)}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
          );
        }
      })}
      <div style={{ textAlign: "center" }}>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onAddOption}
        >
          Add Option
        </Button>
      </div>

      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={item.settings && item.settings.required ? true : false}
            onChange={onRequiredChange}
            name="required"
          />
        }
        label="Required"
      />
    </div>
  );
}

export default RadioButtonsEditor;
