import React from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Input,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { successLogin } from "../auth.slice";
import { login } from "../auth.api";
import { ACTIVE } from "../../../app/constants";
import { Alert } from "@material-ui/lab";
import { usePublicAxios } from "../../../util/axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ForgotPassword() {
  const classes = useStyles();

  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();

  const [{ data, loading, error }, forgotPassword] = usePublicAxios(
    "/organizations/reset/sendToken",
    {
      manual: true,
      cache: false,
    }
  );

  const onSubmit = (data) => {
    forgotPassword({
      data: {
        email: data.email,
        role: "administrator",
      },
      method: "POST",
    });
    // history.push("/dashboard/admin");
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh", background: "#E6E6E6" }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        style={{
          textAlign: "center",
          marginBottom: "10%",
        }}
      >
        <img src="/images/logo.png" alt="ASME Logo" width={"100px"} />
        <br />
        <br />
        <Paper elevation={3} style={{ padding: "10px" }}>
          {error && (
            <>
              <Alert severity="error">
                {error.response && error.response.status === 400
                  ? "No account found with this email address."
                  : "There was some internal server error while processing your request. Please try later!"}
              </Alert>
              <br />
            </>
          )}
          {data && (
            <div style={{ textAlign: "center" }}>
              <h3 className="primary">
                We have sent you an email to reset your password. Please follow
                the instructions there to recover your account.
              </h3>
              <br />
            </div>
          )}
          {!data && (
            <>
              <h3 className="primary">Forgot Password?</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  inputRef={register({
                    required: "This field cannot be empty!",
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Please enter a valid email address!",
                    },
                  })}
                  name="email"
                  label="Email"
                  error={errors.email && errors.email.message ? true : false}
                  helperText={errors.email ? errors.email.message : null}
                  style={{ width: "90%" }}
                />

                <br />
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="primary"
                >
                  Submit
                </Button>
              </form>
              <br />
              <Button onClick={() => history.push("/auth/login")}>
                Back to Login
              </Button>
            </>
          )}
        </Paper>
      </Grid>
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="secondary" />
        </Backdrop>
      )}
    </Grid>
  );
}

export default ForgotPassword;
