import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewAllForms from "./Routes/view";
import FormBuilder from "./Routes/form-builder";
import EditForm from "./Routes/edit";
import ViewAttendance from "./Routes/view-attendance";

function Forms() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <ViewAllForms />
        </Route>
        <Route path={`${path}/builder`}>
          <FormBuilder />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditForm />
        </Route>
        <Route path={`${path}/attendance/:id`}>
          <ViewAttendance />
        </Route>
      </Switch>
    </div>
  );
}

export default Forms;
