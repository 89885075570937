import React from "react";
import { authedAxios } from "../../util/axios";
import AdminContainer from "../Admin/admin";

function Dashboard() {
  const onLogout = () => {
    authedAxios.post("/logout").finally(() => {
      localStorage.removeItem("member_token");
      window.location.reload();
    });
  };

  return <AdminContainer logout={onLogout} />;
}

export default Dashboard;
