import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewAllWorkshops from "./Routes/view";
import ViewAttendance from "./Routes/view-attendance";

function Workshops() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <ViewAllWorkshops />
        </Route>
        <Route exact path={`${path}/:id`}>
          <ViewAttendance />
        </Route>
      </Switch>
    </div>
  );
}

export default Workshops;
