import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useAuthedAxios } from "../../../../../util/axios";
import "../../FormManagement/FormBuilder/style.css";
import { GET_USER } from "../users.graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function EditAdmin() {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [typeOfAdmin, setTypeOfAdmin] = useState("administrator");
  const [hasFormTypeOfAdminError, setFormTypeOfAdminError] = useState(false);
  // const [hasFormEmailError, setHasFormEmailError] = useState(false);
  // const [hasFormPasswordError, setHasFormPasswordError] = useState(false);

  //   Loading Current Form
  const {
    // loading,
    // error,
    data: adminData
    // refetch
  } = useQuery(GET_USER, {
    variables: { id }
  });

  useEffect(() => {
    if (adminData) {
      const { user } = adminData;
      if (!user) {
        history.push("/dashboard/poll-management");
        return;
      }
      const { role, email } = user;
      setEmail(email);
      // setPassword(password);
      setTypeOfAdmin(role?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData, history]);

  // const onFormDescriptionUpdate = (e) => {
  //   const value = e.target.value;
  //   setEmail(value);
  //   if (!value) {
  //     setHasFormEmailError(true);
  //   } else {
  //     setHasFormEmailError(false);
  //   }
  // };

  // const onFormPasswordUpdate = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);
  //   if (!value) {
  //     setHasFormPasswordError(true);
  //   } else {
  //     setHasFormPasswordError(false);
  //   }
  // };

  const [
    { loading: updating, data: updateData, error: updateError },
    updateForm
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(
        "There was some internal server error while saving the admin. Please try again later.",
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    if (updateData) {
      enqueueSnackbar("Admin is saved successfully!", {
        variant: "success"
      });
      history.push("/dashboard/admin-management", {
        update: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  const onSave = async (status) => {
    if (!typeOfAdmin) {
      setFormTypeOfAdminError(true);
      return;
    }

    // if (!email) {
    //   setHasFormEmailError(true);
    //   return;
    // }

    // if (!password) {
    //   setHasFormPasswordError(true);
    //   return;
    // }

    const data = {
      role: typeOfAdmin,
      email
      // password
    };
    try {
      updateForm({
        data,
        url: `/editAdminUser`
      });
    } catch (e) {
      console.log(e);
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the admin. Please try again later.",
          {
            variant: "error"
          }
        );
      }
    }
  };

  return (
    <div>
      {/* <button onClick={() => picture && onImageChange()}>Upload</button> */}
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Edit Admin
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/admin-management">
          Admin Management
        </Link>
        <Typography color="textPrimary">Build New Admin</Typography>
      </Breadcrumbs>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              Edit Admin
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onSave("Publish")}
            >
              Update Admin
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <TextField
              label="Admin Email"
              type="email"
              placeholder="Please enter admin email"
              variant="filled"
              style={{ width: "100%" }}
              value={email}
              // onChange={onFormDescriptionUpdate}
              // error={hasFormEmailError}
              // helperText={hasFormEmailError ? "Email is requied!" : ""}
              disabled
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              label="Admin Password"
              type="password"
              placeholder="Please enter admin password"
              variant="filled"
              style={{ width: "100%" }}
              value={password}
              onChange={onFormPasswordUpdate}
              error={hasFormPasswordError}
              helperText={hasFormPasswordError ? "Password is requied!" : ""}
              required
            />
          </Grid> */}
          <Grid item xs={6}>
            <Grid container direction="column" spacing={3}>
              <Grid item>Type of Admin User</Grid>
              <Grid item>
                <RadioGroup
                  value={typeOfAdmin}
                  onChange={(e) => {
                    setTypeOfAdmin(e.target.value);
                  }}
                  error={hasFormTypeOfAdminError}
                  helperText={
                    hasFormTypeOfAdminError
                      ? "Type of Admin User is requied!"
                      : ""
                  }
                  required
                >
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Administrator"
                    value="administrator"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Super Staff"
                    value="super_staff"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Staff"
                    value="staff"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Backdrop className={classes.backdrop} open={updating}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default EditAdmin;
