import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useEffect } from "react";
import { usePagination, useRowSelect, useTable } from "react-table";
import { SECONDARY_COLOR } from "../../app/constants";

function CustomTable({
  columns,
  data,
  headBG = SECONDARY_COLOR,
  headColor = "white",
  emptyText = "No data to display!",
  showPagination = true,
  pageSize = 10,
  onRowSelection,
  showSelections = true,
  onRowSelect,
  hover = false,
  onPageChange = () => null,
  totalRecordsCount = 0,
  totalPagesCount = 0,
  currentPage = 0
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    page,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      initialState: showPagination
        ? { pageSize }
        : {
            pageSize: 100
          }
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      showSelections &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <Checkbox
                  color="default"
                  {...getToggleAllRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <Checkbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ]);
    }
  );
  useEffect(() => {
    if (onRowSelection && selectedFlatRows)
      onRowSelection(selectedFlatRows.map((d) => d.original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  // Render the UI for your table
  return (
    <>
      <TableContainer className="custom-scroll">
        <Table {...getTableProps()}>
          <TableHead style={{ backgroundColor: headBG }}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    style={{ color: headColor }}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={
                    typeof onRowSelect === "function"
                      ? () => onRowSelect(row.original)
                      : null
                  }
                  hover={hover}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <div
            style={{
              textAlign: "center",
              // border: "1px solid #e2d1d1",
              padding: "50px"
            }}
          >
            {emptyText}
          </div>
        )}
      </TableContainer>
      {showPagination && data.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            Showing{" "}
            <b>
              {pageSize * currentPage + 1} -{" "}
              {pageSize * currentPage + pageSize > totalRecordsCount
                ? totalRecordsCount
                : pageSize * currentPage + pageSize}
            </b>{" "}
            of <b>{totalRecordsCount}</b> results
          </div>
          <Pagination
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end"
            }}
            page={currentPage + 1}
            count={totalPagesCount}
            onChange={(e, p) => {
              if (p !== currentPage + 1) {
                gotoPage(p - 1);
                onPageChange(p - 1);
              }
            }}
            size={"small"}
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </>
  );
}

export default CustomTable;
