export const Allowed_Columns = [
  "Organisation Name",
  "Company Registration No. (UEN)",
  "Year of Incorporation",
  "Address",
  "Phone No.",
  "Staff Strength",
  "Annual Turnover (SGD)",
  "Paid Up Capital (SGD)",
  "Website",
  "Facebook",
  "Legal Structure of Company",
  "Ownership Type",
  "Nature of Business",
  "Industry Classification",
  "Principal Business Activity",
  "Secondary Business Activity",
  "Company Description (Profile)",
  "Salutation",
  "Designation",
  "First Name",
  "Last Name",
  "Email Address",
  "Mobile No.",
  "Citizenship",
  "Source",
];

export const POSSIBLE_LEGAL_STRUCTURE = [
  "Sole Proprietorship",
  "Partnership",
  "Limited Liability Partnership",
  "Private Limited",
];

export const POSSIBLE_OWNERSHIP_TYPE = [
  "Local (at 30% local shareholding or more)",
  "Foreign (less than 30% local shareholding)",
  "Joint-Venture",
];

export const POSSIBLE_CITIZENSHIP = [
  "Singaporean",
  "Permanent Resident (PR)",
  "Foreigner",
];

export const POSSIBLE_BUSINESS_NATURE = [
  "Business-to-Customer",
  "Business-to-Business",
  "Both",
];

export const POSSIBLE_SALUTATIONS = ["Dr.", "Mr.", "Ms.", "Mrs.", "Mdm."];

export const VALIDATE_COMPANY_UEN = (v) => /^[A-Z0-9]*$/.test(v);
export const VALIDATE_YEAR = (date) => date instanceof Date;
export const VALIDATE_PHONE_NUMBER = (number) =>
  /^([6|8|9]\d{7}|65[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65-[6|8|9]\d{7})$/.test(
    number
  );
export const VALIDATE_STAFF_STRENGTH = (v) => /^[0-9]*$/.test(v);
export const VALIDATE_ANNUAL_TURNOVER = (v) =>
  /^$|^[0-9]+(\.[0-9][0-9]){0,1}$/.test(v);
export const VALIDATE_PAID_UP_CAPITAL = (v) =>
  /^$|^[0-9]+(\.[0-9][0-9]){0,1}$/.test(v);
export const VALIDATE_WEBSITE = (v) =>
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    v
  );
export const VALIDATE_FACEBOOK = (v) =>
  /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?$/.test(
    v
  );
export const VALIDATE_LEGAL_STRUCTURE = (v) =>
  POSSIBLE_LEGAL_STRUCTURE.includes(v);
export const VALIDATE_OWNERSHIP_TYPE = (v) =>
  POSSIBLE_OWNERSHIP_TYPE.includes(v);
export const VALIDATE_BUSINESS_NATURE = (v) =>
  POSSIBLE_BUSINESS_NATURE.includes(v);
export const VALIDATE_SALUTAION = (v) => POSSIBLE_SALUTATIONS.includes(v);
export const VALIDATE_CITIZENSHIP = (v) => POSSIBLE_CITIZENSHIP.includes(v);

export const HAS_DUPLICATIONS = (key, array) => {
  const data = array.map((d) => d[key]);
  return data.length !== new Set(data).size;
};

export const HAS_DUPLICATIONS_UENS = (key, array) => {
  const data = array.filter((d) => !!d[key]);

  const uens = data.map((d) => d[key]);

  return uens.length !== new Set(uens).size;
};
