import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewAllPolls from "./Routes/view";
import PollBuilder from "./Routes/poll-builder";
import EditPoll from "./Routes/edit";

function Polls() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <ViewAllPolls />
        </Route>
        <Route path={`${path}/builder`}>
          <PollBuilder />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditPoll />
        </Route>
      </Switch>
    </div>
  );
}

export default Polls;
