import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import fileDownload from "js-file-download";
import moment from "moment";

import CustomTable from "../../../../../components/common/Table";
import { authedAxios, useAuthedAxios } from "../../../../../util/axios";
import { GET_ALL_LISTINGS } from "../rewards.graphql";

function ViewAllListings() {
  const history = useHistory();
  const { state } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [
    selectedFormForSubmissionsRemoval,
    setSelectedFormForSubmissionsRemoval
  ] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteFormConfirmation, setShowDeleteFormConfirmation] =
    useState(false);
  const [selectedFormRemoval, setSelectedFormRemoval] = useState();
  const { loading, error, data, refetch } = useQuery(GET_ALL_LISTINGS, {
    pollInterval: 5000
  });

  const [
    { data: updateData, loading: updating, error: updateError },
    updateStatus
  ] = useAuthedAxios(
    {
      url: "/listing-builders",
      method: "PUT"
    },
    {
      manual: true
    }
  );

  const [
    { data: deletedData, loading: deleting, error: deleteError },
    deleteSubmissions
  ] = useAuthedAxios(
    {
      method: "POST"
    },
    {
      manual: true
    }
  );

  const [
    {
      data: deletedFormData,
      loading: deletingFormLoading,
      error: deleteFormError
    },
    deleteForm
  ] = useAuthedAxios(
    {
      method: "DELETE"
    },
    {
      manual: true
    }
  );

  const [
    { loading: updatingMainPoll, data: updateMainData, error: updateMainError },
    updateForm
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (deletedData || deletedFormData) {
      enqueueSnackbar("Listing deleted successfully!", {
        preventDuplicate: true,
        variant: "success"
      });
    }
  }, [deletedData, deletedFormData, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (deleteError || deleteFormError) {
      enqueueSnackbar(
        "There was some internal server error while deleting the listing. Please try again later!",
        {
          preventDuplicate: true,
          variant: "error"
        }
      );
    }
  }, [deleteError, deleteFormError, enqueueSnackbar]);

  const downloadSubmissions = useCallback(
    async (title, id) => {
      const key = enqueueSnackbar(`Generating csv file for form '${title}'!`, {
        preventDuplicate: true,
        variant: "info"
      });
      authedAxios({
        url: `/listing-submissions/download/${id}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then((res) => {
          fileDownload(res.data, title + ".csv");
          closeSnackbar(key);
          enqueueSnackbar(
            `Submissions for listing '${title}' are downloaded successfully!`,
            {
              preventDuplicate: true,
              variant: "success"
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(
            "There was some internal server error while downloading the submissions. Please try again later!",
            {
              preventDuplicate: true,
              variant: "error"
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  useEffect(() => {
    if (state && state.update) {
      refetch();
    }
  }, [state, refetch]);

  useEffect(() => {
    if (updateData) {
      refetch();
      enqueueSnackbar("Status updated successfully!", {
        preventDuplicate: true,
        variant: "success"
      });
    }
    if (updateMainData) {
      refetch();
      enqueueSnackbar("Main listing updated successfully!", {
        preventDuplicate: true,
        variant: "success"
      });
    }
  }, [updateData, updateMainData, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (updateError || updateMainError) {
      enqueueSnackbar(
        "There was some internal server error while updating the status. Please try again later!",
        {
          preventDuplicate: true,
          variant: "error"
        }
      );
    }
  }, [updateError, updateMainError, enqueueSnackbar]);

  const onPublish = useCallback(
    (id) => {
      updateStatus({
        url: "/listing-builders/" + id,
        data: {
          status: "Publish"
        }
      });
    },
    [updateStatus]
  );

  const onDraft = useCallback(
    (id) => {
      updateStatus({
        url: "/listing-builders/" + id,
        data: {
          status: "Draft"
        }
      });
    },
    [updateStatus]
  );

  const onUpdate = useCallback(
    (id) => {
      history.push(`/dashboard/listing-management/edit/${id}`);
    },
    [history]
  );

  const handleDeleteSubmissionsCancel = () => {
    setSelectedFormForSubmissionsRemoval(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDeletion = () => {
    handleDeleteSubmissionsCancel();
    deleteSubmissions({
      url: `/listing-submissions/delete/${selectedFormForSubmissionsRemoval}`
    });
  };

  const onDeleteForm = (formID, type, slug) => {
    setSelectedFormRemoval(formID);
    setShowDeleteFormConfirmation(true);
  };

  const handleDeleteFormCancel = () => {
    setSelectedFormRemoval(null);
    setShowDeleteFormConfirmation(false);
  };

  const handleConfirmFormDeletion = () => {
    handleDeleteFormCancel();
    deleteForm({
      url: `/listing-builders/${selectedFormRemoval}`
    });
  };

  const onDownloadVouchersList = React.useCallback(
    async (title, id) => {
      const key = enqueueSnackbar(`Generating sheet for listing '${title}'!`, {
        preventDuplicate: true,
        variant: "info"
      });
      authedAxios({
        url: `/vouchers/downloadVouchersList/${id}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then((res) => {
          fileDownload(res.data, title + ".csv");
          closeSnackbar(key);
          enqueueSnackbar(
            `Vouchers for listing '${title}' are downloaded successfully!`,
            {
              preventDuplicate: true,
              variant: "success"
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(
            "There was some internal server error while downloading the vouchers. Please try again later!",
            {
              preventDuplicate: true,
              variant: "error"
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const onDownloadVouchersRedemptions = React.useCallback(
    async (title, id) => {
      const key = enqueueSnackbar(
        `Generating Redemptions for listing '${title}'!`,
        {
          preventDuplicate: true,
          variant: "info"
        }
      );
      authedAxios({
        url: `/vouchers/downloadVouchersRedemptions/${id}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then((res) => {
          fileDownload(res.data, title + ".csv");
          closeSnackbar(key);
          enqueueSnackbar(
            `Redemptions for listing '${title}' are downloaded successfully!`,
            {
              preventDuplicate: true,
              variant: "success"
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(
            "There was some internal server error while downloading the Redemptions. Please try again later!",
            {
              preventDuplicate: true,
              variant: "error"
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Start Date",
        accessor: (record) => moment(record.start_date).format("DD-MM-YYYY")
      },
      {
        Header: "End Date",
        accessor: (record) => moment(record.end_date).format("DD-MM-YYYY")
      },
      {
        Header: "Status",
        accessor: (record) => (
          <Chip
            label={record.status === "Draft" ? "Draft" : "Published"}
            color={record.status === "Draft" ? "default" : "secondary"}
            style={{ padding: record.status === "Draft" ? "0 8px" : "" }}
          />
        )
      },
      {
        Header: "Action",
        accessor: (record) => (
          <div>
            <ButtonGroup
              color="secondary"
              aria-label="outlined primary button group"
              size="small"
            >
              {record.status === "Draft" ? (
                <Button onClick={() => onPublish(record.id, record.__typename)}>
                  Publish
                </Button>
              ) : (
                <Button
                  style={{ padding: "0 16px" }}
                  onClick={() => onDraft(record.id, record.__typename)}
                >
                  Unpublish
                </Button>
              )}
              <Button onClick={() => onUpdate(record.id, record.__typename)}>
                Update
              </Button>
              <Button
                onClick={() =>
                  onDeleteForm(record.id, record.__typename, record.slug)
                }
              >
                Delete
              </Button>
              <Button
                onClick={() => onDownloadVouchersList(record.title, record.id)}
                disabled={record.vouchers.length === 0}
              >
                Download Vouchers
              </Button>
              <Button
                onClick={() =>
                  // onDownloadVouchersRedemptions(record.title, record.id)
                  history.push(`/dashboard/listing-management/${record.id}`)
                }
              >
                View Redemptions
              </Button>
            </ButtonGroup>
          </div>
        )
      }
    ],
    [onDraft, onPublish, onUpdate]
  );

  const openFormBuilder = () => {
    history.push("/dashboard/listing-management/builder");
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Rewards Management
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              All Listings
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={openFormBuilder}
            >
              Add New Listing
            </Button>
          </Grid>
        </Grid>
        {!updating && data && (
          <Grid container spacing={3}>
            <CustomTable
              columns={columns}
              data={data.listingBuilders}
              showSelections={false}
              showPagination={false}
              hover
            />
          </Grid>
        )}
        {(loading ||
          updating ||
          deleting ||
          deletingFormLoading ||
          updatingMainPoll) && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!updating && !loading && error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was some internal server error while fetching records. Please
            try reloading the page.
          </Alert>
        )}
      </Paper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showDeleteConfirmation}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This action is irrevertible. Once deleted, vouchers cannot be
          recovered.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleDeleteSubmissionsCancel}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showDeleteFormConfirmation}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This action is irrevertible. Once deleted, Listing and Listing
          Vouchers cannot be recovered.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteFormCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmFormDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={deleting || deletingFormLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default ViewAllListings;
