import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  IconButton,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel,
  Modal,
  Divider
} from "@material-ui/core";
import { Close, LinkOutlined } from "@material-ui/icons";
import ImageUploader from "react-images-upload";
import { Link, useHistory } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { AutorenewOutlined } from "@material-ui/icons";
import slugify from "slugify";

import {
  TOOLBOX_ELEMENTS,
  Toolbox
} from "../../FormManagement/FormBuilder/toolbox";
import {
  copy,
  FormDropzone,
  reorder
} from "../../FormManagement/FormBuilder/builder";
import FormBuilderContext from "../../FormManagement/FormBuilder/form-builder-context";
import {
  authedAxios,
  MEMBERS_SITE_URL,
  useAuthedAxios
} from "../../../../../util/axios";
import FormPreview from "../../FormManagement/components/form-preview";
import "../../FormManagement/FormBuilder/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function PollBuilder() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [formHeader, setFormHeader] = React.useState();
  const [slug, setFormSlug] = React.useState("");
  const [formDescription, setFormDescription] = React.useState();
  const [url, setFormUrl] = React.useState("");
  const [availableTo, setAvailableTo] = React.useState("member");
  const [allowMultiSelect, setAllowMultiSelect] = React.useState("false");
  const [hasFormHeaderError, setFormHeaderError] = React.useState(false);
  const [hasformSlugError, setFormSlugError] = React.useState(false);
  const [selectedElements, setSelectedElements] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [hasStartDateError, setStartDateError] = React.useState(false);
  const [hasEndDateError, setEndDateError] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [isModalVisible, setModalVisibility] = React.useState(false);

  const handleClose = () => {
    setModalVisibility(false);
  };

  const onDragEnd = React.useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          setSelectedElements((state) => [
            ...reorder(state, source.index, destination.index)
          ]);
          break;
        case "TOOLBOX":
          setSelectedElements((state) => [
            ...copy(TOOLBOX_ELEMENTS, state, source, destination)
          ]);
          break;
        default:
          break;
      }
    },
    [setSelectedElements]
  );

  const onDelete = (itemId) => {
    const newItems = selectedElements.filter((item) => item.id !== itemId);
    setSelectedElements(newItems);
  };

  const onSettingField = (item, settings) => {
    const itemIndex = selectedElements.findIndex((i) => i.id === item.id);
    const newElements = [...selectedElements];
    newElements[itemIndex] = { ...item, settings };
    setSelectedElements(newElements);
  };

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setFormHeader(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onChangeStartDate = (date) => {
    if (date) {
      setStartDateError(false);
      setStartDate(date);
    } else {
      setStartDate(null);
      setStartDateError(true);
    }
  };

  const onChangeEndDate = (date) => {
    if (date) {
      setEndDateError(false);
      setEndDate(date);
    } else {
      setEndDate(null);
      setEndDateError(true);
    }
  };

  const onFormSlugUpdate = (e) => {
    const value = e.target.value;
    setFormSlug(value);
    setFormUrl(`${MEMBERS_SITE_URL}/polls/${value}`);
    if (!value) {
      setFormSlugError(true);
    } else if (/^[a-z0-9](-?[a-z0-9])*$/.test(value)) {
      setFormSlugError(false);
    } else {
      setFormSlugError(true);
    }
  };

  const onGenerateSlug = (e) => {
    const generatedSlug = slugify(formHeader || "", {
      replacement: "-",
      lower: true,
      remove: /[^-a-zA-Z0-9 ]/g
    });
    if (generatedSlug) {
      setFormSlug(generatedSlug);
      setFormUrl(`${MEMBERS_SITE_URL}/polls/${slug}`);
    }
  };

  const onSlugCopy = () => {
    navigator.clipboard &&
      navigator.clipboard.writeText(url).then(() =>
        enqueueSnackbar("Link is successfully copied to clipboard!", {
          variant: "success",
          preventDuplicate: true
        })
      );
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setFormDescription(value);
  };

  const [
    { loading: loadingPrivate, data: dataPrivate, error: errorPrivate },
    savePrivateForm
  ] = useAuthedAxios(
    {
      url: "/poll-builders",
      // url: "public-poll-builders",
      method: "POST"
    },
    {
      manual: true
    }
  );
  const [pollBuilder, setPollBuilder] = React.useState();

  const [
    { loading: loadingImage, data: dataImage, error: errorImage },
    saveImageForm
  ] = useAuthedAxios(
    {
      url: `/poll-builders/${pollBuilder && pollBuilder.id}`,
      method: "PUT"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (errorPrivate || errorImage) {
      enqueueSnackbar(
        "There was some internal server error while saving the poll. Please try again later.",
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPrivate]);

  useEffect(() => {
    if (dataPrivate) {
      enqueueSnackbar("Poll is saved successfully!", {
        variant: "success"
      });
      history.push("/dashboard/poll-management", {
        update: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrivate]);

  const getDate = (date) => {
    const day = moment(date).get("D");
    const month = moment(date).get("M");
    const year = moment(date).get("Y");

    const d = day < 10 ? `0${day}` : day;
    const m = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`;
    return `${year}-${m}-${d}T00:00:00.000Z`;
  };

  const [picture, setPicture] = React.useState([]);
  const [pictureURL, setPictureURL] = React.useState();

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setPicture(pictureFiles);
    setPictureURL(pictureDataURLs);
  };

  const onSave = async (status) => {
    if (!formHeader) {
      setFormHeaderError(true);
      return;
    }
    if (!slug) {
      setFormSlugError(true);
      return;
    }
    if (!startDate) {
      setStartDateError(true);
      return;
    }
    if (!endDate) {
      setEndDateError(true);
      return;
    }

    setFormUrl(`${MEMBERS_SITE_URL}/polls/${slug}`);
    const [image] = picture;
    const data = {
      status,
      title: formHeader,
      slug,
      description: formDescription,
      start_date: getDate(startDate),
      end_date: getDate(endDate),
      // image,
      available_to: availableTo,
      main_poll: false,
      allow_multiple_select: allowMultiSelect,
      form: selectedElements.map(({ icon, ...rest }) => ({ ...rest }))
    };
    try {
      // Check if slug exists
      const response = await authedAxios.get(`/poll-builders?slug=${slug}`);

      if (response.data.length > 0) {
        enqueueSnackbar(
          <span>
            The slug <b>{slug}</b> is already being used! Please use another
            slug.
          </span>,
          {
            variant: "error",
            preventDuplicate: true
          }
        );
        return;
      }
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      image && formData.append("files.image", image, image.name);
      // console.log();

      savePrivateForm({
        data: formData
      });
    } catch (e) {
      console.log(e);
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the form. Please try again later.",
          {
            variant: "error"
          }
        );
      }
    }
  };

  const onPreview = () => {
    setShowPreview(true);
  };

  return (
    <div>
      {/* <button onClick={() => picture && onImageChange()}>Upload</button> */}
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Poll Builder
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/poll-management">
          Poll Management
        </Link>
        <Typography color="textPrimary">Build New Poll</Typography>
      </Breadcrumbs>
      <FormBuilderContext.Provider
        value={{
          items: selectedElements,
          onDelete,
          onSettingField
        }}
      >
        <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
          <Grid container spacing={3} style={{ marginBottom: "10px" }}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                color="secondary"
                style={{ fontWeight: "bold" }}
              >
                New Poll
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              {selectedElements.length > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "10px" }}
                  onClick={onPreview}
                >
                  Preview
                </Button>
              )}
              <Button
                variant="outlined"
                color="default"
                style={{ marginRight: "10px" }}
                disabled={selectedElements.length === 0}
                onClick={() => onSave("Draft")}
              >
                Save as Draft
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                disabled={selectedElements.length === 0}
                onClick={() => onSave("Publish")}
              >
                Publish Poll
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: "20px" }}>
            <Grid item xs={6}>
              <TextField
                label="Poll Header"
                placeholder="Please enter poll title"
                style={{ width: "100%" }}
                variant="filled"
                onChange={onFormHeaderUpdate}
                error={hasFormHeaderError}
                helperText={hasFormHeaderError ? "Form header is requied!" : ""}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={
                  <span>
                    Poll Slug
                    {/* {slug && (
                      <small> (https://members.asme.org.sg/poll/{slug})</small>
                    )} */}
                  </span>
                }
                placeholder="i.e. ihl"
                variant="filled"
                style={{ width: "100%" }}
                onChange={onFormSlugUpdate}
                error={hasformSlugError}
                value={slug}
                helperText={
                  hasformSlugError
                    ? "Poll slug is required and it must contain lowercase letters, numbers, or single hyphens only!"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* {slug && !hasformSlugError && (
                        <IconButton
                          aria-label="copy slug"
                          onClick={onSlugCopy}
                          size="small"
                        >
                          <LinkOutlined />
                        </IconButton>
                      )} */}
                      <IconButton
                        aria-label="generate slug"
                        onClick={onGenerateSlug}
                        size="small"
                      >
                        <AutorenewOutlined />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Poll Description"
                placeholder="Please enter poll description (optional)"
                variant="filled"
                style={{ width: "100%" }}
                value={formDescription}
                onChange={onFormDescriptionUpdate}
                multiline
                rows={5}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="subtitle1" color={"secondary"}>
                <div style={{ display: "flex" }}>
                  <DatePicker
                    label="Start Date"
                    required
                    value={startDate}
                    onChange={(date) => onChangeStartDate(date)}
                    animateYearScrolling
                    style={{
                      marginRight: "10px",
                      height: "55px"
                    }}
                    InputLabelProps={{
                      style: {
                        paddingLeft: "10px",
                        paddingTop: startDate ? "7px" : "0px"
                      }
                    }}
                    InputProps={{
                      style: {
                        paddingLeft: "10px",
                        marginTop: "22px"
                      }
                    }}
                    className="date-filled-input"
                    format="DD/MM/yyyy"
                    DialogProps={{
                      clearable: true
                    }}
                    error={hasStartDateError}
                    helperText={
                      hasStartDateError ? "Please select the start date!" : ""
                    }
                  />
                  {startDate ? (
                    <DatePicker
                      required
                      label="End Date"
                      className="date-filled-input"
                      value={endDate}
                      onChange={(date) => onChangeEndDate(date)}
                      minDate={startDate}
                      minDateMessage={"The mininim date is not valid"}
                      format="DD/MM/yyyy"
                      style={{
                        height: "55px"
                      }}
                      InputLabelProps={{
                        style: {
                          paddingLeft: "10px",
                          paddingTop: endDate ? "7px" : "0px"
                        }
                      }}
                      InputProps={{
                        style: {
                          paddingLeft: "10px",
                          marginTop: "22px"
                        }
                      }}
                      animateYearScrolling
                      DialogProps={{
                        clearable: true
                      }}
                      error={hasEndDateError}
                      helperText={
                        hasEndDateError ? "Please select the end date!" : ""
                      }
                    />
                  ) : (
                    <DatePicker
                      required
                      className="date-filled-input"
                      label="End Date"
                      DialogProps={{
                        clearable: true
                      }}
                      value={endDate}
                      onChange={(date) => onChangeEndDate(date)}
                      format="DD/MM/yyyy"
                      animateYearScrolling
                      style={{
                        height: "55px"
                      }}
                      InputLabelProps={{
                        style: {
                          paddingLeft: "10px",
                          paddingTop: endDate ? "7px" : "0px"
                        }
                      }}
                      InputProps={{
                        style: {
                          paddingLeft: "10px",
                          marginTop: "22px"
                        }
                      }}
                      error={hasEndDateError}
                      helperText={
                        hasEndDateError ? "Please select the end date!" : ""
                      }
                    />
                  )}
                </div>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: "20px" }}>
            <Grid item xs={6}>
              <Grid container direction="column" spacing={3}>
                <Grid item>Available To</Grid>
                <Grid item>
                  <RadioGroup
                    value={availableTo}
                    onChange={(e) => setAvailableTo(e.target.value)}
                    style={{ display: "flow-root" }}
                  >
                    <FormControlLabel
                      control={<Radio color="default" />}
                      label="Public"
                      value="public"
                    />
                    <FormControlLabel
                      control={<Radio color="default" />}
                      label="Members Only"
                      value="member"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item>Allow Selecting Multiple Options</Grid>
                <Grid item>
                  <RadioGroup
                    value={allowMultiSelect}
                    onChange={(e) => setAllowMultiSelect(e.target.value)}
                    style={{ display: "flow-root" }}
                  >
                    <FormControlLabel
                      control={<Radio color="default" />}
                      label="Yes"
                      value="true"
                    />
                    <FormControlLabel
                      control={<Radio color="default" />}
                      label="No"
                      value="false"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <TextField
                    label={<span>Poll URL</span>}
                    placeholder="i.e. poll"
                    variant="filled"
                    style={{ width: "100%" }}
                    value={slug && `${MEMBERS_SITE_URL}/polls/${slug}`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {slug && !hasformSlugError && (
                            <IconButton
                              aria-label="copy slug"
                              onClick={onSlugCopy}
                              size="small"
                            >
                              <LinkOutlined />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <div
                onClick={() => setModalVisibility(true)}
                style={{
                  height: "170px",
                  maxWidth: "150px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {pictureURL ? (
                  <img
                    src={pictureURL}
                    alt="ASME LOGO"
                    style={{
                      width: "100%",
                      height: "150px"
                    }}
                  />
                ) : (
                  <img
                    src="/images/logo.jpg"
                    alt="ASME LOGO"
                    style={{
                      width: "100%",
                      height: "150px"
                    }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container className={classes.root} spacing={2}>
              <Grid xs={8} item>
                <Paper variant="outlined">
                  <FormDropzone />
                </Paper>
              </Grid>
              <Grid xs={4} item>
                <Paper variant="outlined">
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ padding: "10px 0" }}
                  >
                    Toolbox
                  </Typography>
                  <Toolbox isPollBuilder={true} />
                </Paper>
              </Grid>
            </Grid>
          </DragDropContext>
        </Paper>
      </FormBuilderContext.Provider>
      <Backdrop className={classes.backdrop} open={loadingPrivate}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <FormPreview
        key="form-builder-preview"
        open={showPreview}
        onHide={() => setShowPreview(false)}
        data={{
          items: selectedElements,
          title: formHeader
        }}
      />
      <Modal
        open={isModalVisible}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          className={classes.modal}
          style={{
            position: "absolute",
            width: "50%",
            // height: "35%",
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            border: "none"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Image</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onDrop}
            buttonStyles={{ background: "#de1736", color: "#fff" }}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Image"
            singleImage
            withPreview
          />
          {picture && picture.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                  // onImageChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default PollBuilder;
