import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";

import CustomTable from "../../../../../components/common/Table";
import { useAuthedAxios } from "../../../../../util/axios";
import { GET_ALL_USERS } from "../users.graphql";

function ViewAllAdmins() {
  const history = useHistory();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [
    // selectedFormForSubmissionsRemoval,
    setSelectedFormForSubmissionsRemoval
  ] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteFormConfirmation, setShowDeleteFormConfirmation] =
    useState(false);
  const [selectedFormRemoval, setSelectedFormRemoval] = useState();
  const { loading, error, data, refetch } = useQuery(GET_ALL_USERS, {
    pollInterval: 5000
  });

  const [
    {
      data: deletedFormData,
      loading: deletingFormLoading,
      error: deleteFormError
    },
    deleteForm
  ] = useAuthedAxios(
    {
      method: "DELETE"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (deletedFormData) {
      enqueueSnackbar("Admin deleted successfully!", {
        preventDuplicate: true,
        variant: "success"
      });
      refetch();
    }
  }, [deletedFormData, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (deleteFormError) {
      enqueueSnackbar(
        "There was some internal server error while deleting the admin. Please try again later!",
        {
          preventDuplicate: true,
          variant: "error"
        }
      );
    }
  }, [deleteFormError, enqueueSnackbar]);

  useEffect(() => {
    if (state && state.update) {
      refetch();
    }
  }, [state, refetch]);

  const onUpdate = useCallback(
    (id) => {
      history.push(`/dashboard/admin-management/edit/${id}`);
    },
    [history]
  );

  const handleDeleteSubmissionsCancel = () => {
    setSelectedFormForSubmissionsRemoval(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDeletion = () => {
    handleDeleteSubmissionsCancel();
  };

  const onDeleteForm = (formID, type, slug) => {
    setSelectedFormRemoval(formID);
    setShowDeleteFormConfirmation(true);
  };

  const handleDeleteFormCancel = () => {
    setSelectedFormRemoval(null);
    setShowDeleteFormConfirmation(false);
  };

  const handleConfirmFormDeletion = () => {
    handleDeleteFormCancel();
    deleteForm({
      url: `/deleteAdminUser/${selectedFormRemoval}`
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>
      },
      {
        Header: "Role",
        accessor: (record) => record.role.name
      },
      {
        Header: "Email",
        accessor: (record) => record.email
      },
      {
        Header: "Action",
        accessor: (record) => (
          <div>
            <ButtonGroup
              color="secondary"
              aria-label="outlined primary button group"
              size="small"
            >
              <Button
                disabled={record.role.type === "administrator"}
                onClick={() => onUpdate(record.id, record.__typename)}
              >
                Update
              </Button>
              <Button
                disabled={record.role.type === "administrator"}
                onClick={() =>
                  onDeleteForm(record.id, record.__typename, record.slug)
                }
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        )
      }
    ],
    [onUpdate]
  );

  const openFormBuilder = () => {
    history.push("/dashboard/admin-management/builder");
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Admin Management
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              All Admins
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={openFormBuilder}
            >
              Add New Admin
            </Button>
          </Grid>
        </Grid>
        {data && (
          <Grid container spacing={3}>
            <CustomTable
              columns={columns}
              data={data.users}
              showSelections={false}
              showPagination={false}
              hover
            />
          </Grid>
        )}
        {loading ||
          (deletingFormLoading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </div>
          ))}
        {!loading && error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was some internal server error while fetching records. Please
            try reloading the page.
          </Alert>
        )}
      </Paper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showDeleteConfirmation}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This action is irrevertible. Once deleted, admins cannot be recovered.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleDeleteSubmissionsCancel}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showDeleteFormConfirmation}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This action is irrevertible. Once deleted, Admin cannot be recovered.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteFormCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmFormDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={deletingFormLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default ViewAllAdmins;
