import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Avatar,
  CircularProgress,
  Menu,
  MenuItem,
  Collapse
} from "@material-ui/core";
import {
  AccountBox,
  ExpandLess,
  ExpandMore,
  Home,
  Menu as MenuIcon,
  Person
} from "@material-ui/icons";
import clsx from "clsx";
import MemberHome from "./Routes/home";
import ContactInfo from "./Routes/contact";
import { SECONDARY_COLOR } from "../../app/constants";
import AllMembers from "./Routes/Member/all";
import ExpiringMembers from "./Routes/Member/expiring-members";
import NonMembers from "./Routes/Member/non";
import AdminAuditTrail from "./Routes/Audit/admin-trial";
import MemberAuditTrail from "./Routes/Audit/member-trial";
import MemberDetails from "./Routes/Member/Details/MemberDetails";
import Forms from "./Routes/FormManagement/forms";
import Polls from "./Routes/PollManagement/polls";
import Listings from "./Routes/ListingManagement/rewards";
import Merchants from "./Routes/MerchantManagement/merchants";
import ImportMembers from "./Routes/Member/import-members";
import PendingAccountCreation from "./Routes/Member/pending-account-creation";
import AdminBuilder from "./Routes/Admin/Routes/admin-builder";
import EditAdmin from "./Routes/Admin/Routes/edit";
import ViewAllAdmins from "./Routes/Admin/Routes/view";
import Reporting from "./Routes/Reporting/reporting";
import UpdateMembers from "./Routes/Member/update-members";
import Workshops from "./Routes/Workshops/workshops";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    paddingTop: "20px",
    paddingBottom: "20px",

    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#E6E6E6",
    minHeight: "100vh"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const menu = [
  {
    title: "Home",
    path: "/dashboard",
    icon: <Home />
  },
  {
    title: "Contact",
    path: "/dashboard/contact",
    icon: <AccountBox />
  }
];

function AdminContainer({ logout }) {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [memberMenuOpen, setMemberMenuOpen] = useState(false);
  const [auditMenuOpen, setAuditMenuOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const role = user?.role?.type;

  const { pathname } = useLocation();

  const onMenuSelect = (menuItem) => {
    history.push(menuItem.path);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  useEffect(() => {
    if (role) {
      if (
        role === "administrator" ||
        role === "staff" ||
        role === "super_staff"
      ) {
        setIsLoaded(true);
      } else {
        history.push("/");
      }
    }
  }, [history, role]);

  const renderRoutes = () => {
    switch (role) {
      case "administrator":
        return (
          <>
            <Route exact path={`${path}`}>
              <MemberHome />
            </Route>
            <Route exact path={`${path}/contact`}>
              <ContactInfo />
            </Route>
            <Route path={`${path}/form-management`}>
              <Forms />
            </Route>
            <Route path={`${path}/poll-management`}>
              <Polls />
            </Route>
            <Route path={`${path}/merchant-management`}>
              <Merchants />
            </Route>
            <Route path={`${path}/listing-management`}>
              <Listings />
            </Route>
            <Route path={`${path}/workshops`}>
              <Workshops />
            </Route>
            <Route exact path={`${path}/admin-management`}>
              <ViewAllAdmins />
            </Route>
            <Route exact path={`${path}/admin-management/builder`}>
              <AdminBuilder />
            </Route>
            <Route exact path={`${path}/admin-management/edit/:id`}>
              <EditAdmin />
            </Route>
            <Route exact path={`${path}/member/all`}>
              <AllMembers />
            </Route>
            <Route exact path={`${path}/member/expiring`}>
              <ExpiringMembers />
            </Route>

            <Route exact path={`${path}/member/non`}>
              <NonMembers />
            </Route>
            <Route exact path={`${path}/member/import`}>
              <ImportMembers />
            </Route>
            <Route exact path={`${path}/member/update`}>
              <UpdateMembers />
            </Route>
            <Route exact path={`${path}/member/pending-account-creation`}>
              <PendingAccountCreation />
            </Route>
            <Route exact path={`${path}/members/:id`}>
              <MemberDetails />
            </Route>
            <Route exact path={`${path}/audit/admin-trail`}>
              <AdminAuditTrail />
            </Route>
            <Route exact path={`${path}/audit/member-trail`}>
              <MemberAuditTrail />
            </Route>
            <Route exact path={`${path}/reporting`}>
              <Reporting />
            </Route>
          </>
        );

      case "staff":
        return (
          <>
            <Route exact path={`${path}`}>
              <MemberHome />
            </Route>
            <Route exact path={`${path}/contact`}>
              <ContactInfo />
            </Route>
            <Route exact path={`${path}/member/all`}>
              <AllMembers />
            </Route>
            <Route exact path={`${path}/member/expiring`}>
              <ExpiringMembers />
            </Route>
            <Route exact path={`${path}/member/non`}>
              <NonMembers />
            </Route>
            <Route exact path={`${path}/member/import`}>
              <ImportMembers />
            </Route>
            <Route exact path={`${path}/member/pending-account-creation`}>
              <PendingAccountCreation />
            </Route>
            <Route exact path={`${path}/members/:id`}>
              <MemberDetails />
            </Route>
          </>
        );

      case "super_staff":
        return (
          <>
            <Route exact path={`${path}`}>
              <MemberHome />
            </Route>
            <Route exact path={`${path}/contact`}>
              <ContactInfo />
            </Route>
            <Route path={`${path}/form-management`}>
              <Forms />
            </Route>
            <Route exact path={`${path}/member/all`}>
              <AllMembers />
            </Route>
            <Route exact path={`${path}/member/expiring`}>
              <ExpiringMembers />
            </Route>
            <Route exact path={`${path}/member/non`}>
              <NonMembers />
            </Route>
            <Route exact path={`${path}/member/import`}>
              <ImportMembers />
            </Route>
            <Route exact path={`${path}/member/pending-account-creation`}>
              <PendingAccountCreation />
            </Route>
            <Route exact path={`${path}/members/:id`}>
              <MemberDetails />
            </Route>
          </>
        );

      default:
        return (
          <>
            <Route exact path={`${path}`}>
              <MemberHome />
            </Route>
            <Route exact path={`${path}/contact`}>
              <ContactInfo />
            </Route>
          </>
        );
    }
  };

  return (
    <>
      {isLoaded ? (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open
            })}
            color="transparent"
            elevation={0}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open
                })}
                size="small"
                style={{
                  background: SECONDARY_COLOR,
                  color: "white"
                }}
                elevation={4}
              >
                <MenuIcon />
              </IconButton>
              {open && (
                <IconButton
                  color="secondary"
                  style={{
                    background: SECONDARY_COLOR,
                    color: "white",
                    marginLeft: "-39px"
                  }}
                  size="small"
                  onClick={handleDrawerClose}
                  elevation={4}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            elevation={3}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            PaperProps={{ elevation: 10 }}
          >
            <div
              className={classes.toolbar}
              style={
                !open
                  ? {
                      marginTop: "40px"
                    }
                  : null
              }
            >
              <img
                src="/images/logo.png"
                width={open ? "70px" : "30px"}
                alt="ASME Logo"
              />
            </div>
            <Divider />
            <List
              style={{ paddingLeft: "7px" }}
              className="mobile-main-menup-padding"
              dense
            >
              {menu.map((item) => (
                <ListItem
                  button
                  key={item.title}
                  onClick={() => onMenuSelect(item)}
                >
                  <ListItemIcon
                    style={{
                      color: SECONDARY_COLOR,
                      minWidth: open ? "32px" : "56px"
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      style: {
                        fontWeight: pathname === item.path ? "bolder" : 500,
                        color: SECONDARY_COLOR
                      }
                    }}
                  />
                </ListItem>
              ))}
              <ListItem
                button
                onClick={() => setMemberMenuOpen(!memberMenuOpen)}
              >
                <ListItemIcon
                  style={{
                    color: SECONDARY_COLOR,
                    minWidth: open ? "32px" : "56px"
                  }}
                >
                  <img
                    src={require("./images/diamond-shape.png")}
                    width="18px"
                    height="18px"
                    alt="Icon"
                    style={{ marginLeft: "3px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Member"
                  primaryTypographyProps={{
                    style: {
                      fontWeight:
                        pathname === "/dashboard/member/all" ||
                        pathname === "/dashboard/member/non"
                          ? "bolder"
                          : 500,
                      color: SECONDARY_COLOR
                    }
                  }}
                />
                {memberMenuOpen ? (
                  <ExpandLess fontSize="small" color="secondary" />
                ) : (
                  <ExpandMore fontSize="small" color="secondary" />
                )}
              </ListItem>
              <Collapse
                in={memberMenuOpen && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/member/all" })
                    }
                  >
                    <ListItemText
                      primary="All Members"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/member/all"
                              ? "bolder"
                              : 500,
                          fontSize: "14px",
                          marginLeft: !open ? "32px" : "16px"
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/member/non" })
                    }
                  >
                    <ListItemText
                      primary="Non-members"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/member/non"
                              ? "bolder"
                              : 500,
                          fontSize: "14px",
                          marginLeft: !open ? "32px" : "16px"
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/member/import" })
                    }
                  >
                    <ListItemText
                      primary="Import Members"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/member/import"
                              ? "bolder"
                              : 500,
                          fontSize: "14px",
                          marginLeft: !open ? "32px" : "16px"
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/member/update" })
                    }
                  >
                    <ListItemText
                      primary="Update Members"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/member/update"
                              ? "bolder"
                              : 500,
                          fontSize: "14px",
                          marginLeft: !open ? "32px" : "16px"
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      onMenuSelect({
                        path: "/dashboard/member/pending-account-creation"
                      })
                    }
                  >
                    <ListItemText
                      primary="Pending Acct Creation"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname ===
                            "/dashboard/member/pending-account-creation"
                              ? "bolder"
                              : 500,
                          fontSize: "14px",
                          marginLeft: !open ? "32px" : "16px"
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/member/expiring" })
                    }
                  >
                    <ListItemText
                      primary="Expiring Members"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/member/expiring"
                              ? "bolder"
                              : 500,
                          fontSize: "14px",
                          marginLeft: !open ? "32px" : "16px"
                        }
                      }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              {role === "administrator" || role === "super_staff" ? (
                <ListItem
                  button
                  onClick={() =>
                    onMenuSelect({ path: "/dashboard/form-management" })
                  }
                >
                  <ListItemIcon
                    style={{
                      color: SECONDARY_COLOR,
                      minWidth: open ? "32px" : "56px"
                    }}
                  >
                    <img
                      src={require("./images/diamond-shape.png")}
                      width="18px"
                      height="18px"
                      alt="Icon"
                      style={{ marginLeft: "3px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Form Management"
                    primaryTypographyProps={{
                      style: {
                        fontWeight:
                          pathname === "/dashboard/form-management"
                            ? "bolder"
                            : 500,
                        color: SECONDARY_COLOR
                      }
                    }}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {role === "administrator" ? (
                <>
                  <ListItem
                    button
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/admin-management" })
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: SECONDARY_COLOR,
                        minWidth: open ? "32px" : "56px"
                      }}
                    >
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Admin Users"}
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/admin-management"
                              ? "bolder"
                              : 500,
                          color: SECONDARY_COLOR
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => setAuditMenuOpen(!auditMenuOpen)}
                  >
                    <ListItemIcon
                      style={{
                        color: SECONDARY_COLOR,
                        minWidth: open ? "32px" : "56px"
                      }}
                    >
                      <img
                        src={require("./images/diamond-shape.png")}
                        width="18px"
                        height="18px"
                        alt="Icon"
                        style={{ marginLeft: "3px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Audit Trail"
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/audit/admin-trail" ||
                            pathname === "/dashboard/audit/member-trail"
                              ? "bolder"
                              : 500,
                          color: SECONDARY_COLOR
                        }
                      }}
                    />
                    {auditMenuOpen ? (
                      <ExpandLess fontSize="small" color="secondary" />
                    ) : (
                      <ExpandMore fontSize="small" color="secondary" />
                    )}
                  </ListItem>
                  <Collapse
                    in={auditMenuOpen && open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          onMenuSelect({ path: "/dashboard/audit/admin-trail" })
                        }
                      >
                        <ListItemText
                          primary="Admin Audit Trail"
                          primaryTypographyProps={{
                            style: {
                              fontWeight:
                                pathname === "/dashboard/audit/admin-trail"
                                  ? "bolder"
                                  : 500,
                              fontSize: "14px",
                              marginLeft: !open ? "32px" : "16px"
                            }
                          }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          onMenuSelect({
                            path: "/dashboard/audit/member-trail"
                          })
                        }
                      >
                        <ListItemText
                          primary="Member Audit Trail"
                          primaryTypographyProps={{
                            style: {
                              fontWeight:
                                pathname === "/dashboard/audit/member-trail"
                                  ? "bolder"
                                  : 500,
                              fontSize: "14px",
                              marginLeft: !open ? "32px" : "16px"
                            }
                          }}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              ) : (
                ""
              )}
              {role === "administrator" ? (
                <ListItem
                  button
                  onClick={() =>
                    onMenuSelect({ path: "/dashboard/poll-management" })
                  }
                >
                  <ListItemIcon
                    style={{
                      color: SECONDARY_COLOR,
                      minWidth: open ? "32px" : "56px"
                    }}
                  >
                    <img
                      src={require("./images/diamond-shape.png")}
                      width="18px"
                      height="18px"
                      alt="Icon"
                      style={{ marginLeft: "3px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Poll Builder"}
                    primaryTypographyProps={{
                      style: {
                        fontWeight:
                          pathname === "/dashboard/poll-management"
                            ? "bolder"
                            : 500,
                        color: SECONDARY_COLOR
                      }
                    }}
                  />
                </ListItem>
              ) : (
                ""
              )}
              {role === "administrator" ? (
                <>
                  <ListItem
                    button
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/merchant-management" })
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: SECONDARY_COLOR,
                        minWidth: open ? "32px" : "56px"
                      }}
                    >
                      <img
                        src={require("./images/diamond-shape.png")}
                        width="18px"
                        height="18px"
                        alt="Icon"
                        style={{ marginLeft: "3px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Merchants"}
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/merchant-management"
                              ? "bolder"
                              : 500,
                          color: SECONDARY_COLOR
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/listing-management" })
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: SECONDARY_COLOR,
                        minWidth: open ? "32px" : "56px"
                      }}
                    >
                      <img
                        src={require("./images/ribbon.png")}
                        width="18px"
                        height="18px"
                        alt="Icon"
                        style={{ marginLeft: "3px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Rewards"}
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/listing-management"
                              ? "bolder"
                              : 500,
                          color: SECONDARY_COLOR
                        }
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() =>
                      onMenuSelect({ path: "/dashboard/reporting" })
                    }
                  >
                    <ListItemIcon
                      style={{
                        color: SECONDARY_COLOR,
                        minWidth: open ? "32px" : "56px"
                      }}
                    >
                      <img
                        src={require("./images/diamond-shape.png")}
                        width="18px"
                        height="18px"
                        alt="Icon"
                        style={{ marginLeft: "3px" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Reporting"}
                      primaryTypographyProps={{
                        style: {
                          fontWeight:
                            pathname === "/dashboard/reporting"
                              ? "bolder"
                              : 500,
                          color: SECONDARY_COLOR
                        }
                      }}
                    />
                  </ListItem>
                  {role === "administrator" || role === "super_staff" ? (
                    <ListItem
                      button
                      onClick={() =>
                        onMenuSelect({ path: "/dashboard/workshops" })
                      }
                    >
                      <ListItemIcon
                        style={{
                          color: SECONDARY_COLOR,
                          minWidth: open ? "32px" : "56px"
                        }}
                      >
                        <img
                          src={require("./images/diamond-shape.png")}
                          width="18px"
                          height="18px"
                          alt="Icon"
                          style={{ marginLeft: "3px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Workshop Management"
                        primaryTypographyProps={{
                          style: {
                            fontWeight:
                              pathname === "/dashboard/workshops"
                                ? "bolder"
                                : 500,
                            color: SECONDARY_COLOR
                          }
                        }}
                      />
                    </ListItem>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </List>
            <Divider />
          </Drawer>
          <main className={classes.content}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                zIndex: 1202,
                position: "relative"
              }}
            >
              <Typography variant="h6" style={{ marginRight: "10px" }}>
                Welcome, {user.role.name}
              </Typography>
              <div>
                <Avatar
                  style={{ color: "white", background: SECONDARY_COLOR }}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  R
                </Avatar>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={menuOpen}
                  onClose={handleClose}
                >
                  {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem> */}
                  {/* <Divider /> */}
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
            <div className={classes.toolbar} />

            {/* Routes Here */}

            <Switch>{renderRoutes()}</Switch>
          </main>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
}

export default AdminContainer;
