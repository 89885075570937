import React from "react";

import "./auth.css";
import { useSelector } from "react-redux";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import Login from "./Login/login";
import ForgotPassword from "./ForgotPassword/forgot-password";
import ResetPassword from "./ResetPassword/reset-password";

function AuthContainer() {
  const { path } = useRouteMatch();

  const isLoggedIn = useSelector((state) => state.auth.user);

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/login`} />
      </Route>
      <Route exact path={`${path}/login`}>
        <Login />
      </Route>
      <Route exact path={`${path}/forgot-password`}>
        <ForgotPassword />
      </Route>
      <Route exact path={`${path}/reset-password`}>
        <ResetPassword />
      </Route>
    </>
  );
}

export default AuthContainer;
