import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useAuthedAxios } from "../../../../../util/axios";
import "../../FormManagement/FormBuilder/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function MerchantBuilder() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [hasFormHeaderError, setFormHeaderError] = React.useState(false);
  const [hasFormEmailError, setHasFormEmailError] = React.useState(false);
  const [hasFormPasswordError, setHasFormPasswordError] = React.useState(false);

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setName(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!value) {
      setHasFormEmailError(true);
    } else {
      setHasFormEmailError(false);
    }
  };

  const onFormPasswordUpdate = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (!value) {
      setHasFormPasswordError(true);
    } else {
      setHasFormPasswordError(false);
    }
  };

  const [
    { loading: loadingPrivate, data: dataPrivate, error: errorPrivate },
    savePrivateForm
  ] = useAuthedAxios(
    {
      url: "/merchants",
      method: "POST"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (errorPrivate) {
      enqueueSnackbar(
        "There was some internal server error while saving the merchant. Please try again later.",
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPrivate]);

  useEffect(() => {
    if (dataPrivate) {
      enqueueSnackbar("Merchant is saved successfully!", {
        variant: "success"
      });
      history.push("/dashboard/merchant-management", {
        update: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrivate]);

  const onSave = async (status) => {
    if (!name) {
      setFormHeaderError(true);
      return;
    }

    const data = {
      name,
      email,
      password
    };
    try {
      savePrivateForm({
        data
      });
    } catch (e) {
      console.log(e);
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the merchant. Please try again later.",
          {
            variant: "error"
          }
        );
      }
    }
  };

  return (
    <div>
      {/* <button onClick={() => picture && onImageChange()}>Upload</button> */}
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Merchant Builder
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/merchant-management">
          Merchant Management
        </Link>
        <Typography color="textPrimary">Build New Merchant</Typography>
      </Breadcrumbs>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              New Merchant
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onSave("Publish")}
            >
              Save Merchant
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <TextField
              label="Merchant Name"
              placeholder="Please enter merchant name"
              style={{ width: "100%" }}
              variant="filled"
              onChange={onFormHeaderUpdate}
              error={hasFormHeaderError}
              helperText={hasFormHeaderError ? "Name is requied!" : ""}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Merchant Email"
              type="email"
              placeholder="Please enter merchant email"
              variant="filled"
              style={{ width: "100%" }}
              value={email}
              onChange={onFormDescriptionUpdate}
              error={hasFormEmailError}
              helperText={hasFormEmailError ? "Email is requied!" : ""}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Merchant Password"
              type="password"
              placeholder="Please enter merchant password"
              variant="filled"
              style={{ width: "100%" }}
              value={password}
              onChange={onFormPasswordUpdate}
              error={hasFormPasswordError}
              helperText={hasFormPasswordError ? "Password is requied!" : ""}
              required
            />
          </Grid>
        </Grid>
      </Paper>
      <Backdrop className={classes.backdrop} open={loadingPrivate}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default MerchantBuilder;
