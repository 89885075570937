import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewAllMerchants from "./Routes/view";
import MerchantsBuilder from "./Routes/merchants-builder";
import EditMerchant from "./Routes/edit";

function Merchants() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <ViewAllMerchants />
        </Route>
        <Route path={`${path}/builder`}>
          <MerchantsBuilder />
        </Route>
        <Route path={`${path}/edit/:id`}>
          <EditMerchant />
        </Route>
      </Switch>
    </div>
  );
}

export default Merchants;
