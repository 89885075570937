import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useAuthedAxios } from "../../../../../util/axios";
import "../../FormManagement/FormBuilder/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function AdminBuilder() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typeOfAdmin, setTypeOfAdmin] = useState("administrator");
  const [hasFormTypeOfAdminError, setFormTypeOfAdminError] = useState(false);
  const [hasFormEmailError, setHasFormEmailError] = useState(false);
  const [hasFormPasswordError, setHasFormPasswordError] = useState(false);

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!value) {
      setHasFormEmailError(true);
    } else {
      setHasFormEmailError(false);
    }
  };

  const onFormPasswordUpdate = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (!value) {
      setHasFormPasswordError(true);
    } else {
      setHasFormPasswordError(false);
    }
  };

  const [
    { loading: loadingPrivate, data: dataPrivate, error: errorPrivate },
    savePrivateForm
  ] = useAuthedAxios(
    {
      url: "/addNewAdminUser",
      method: "POST"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (errorPrivate) {
      enqueueSnackbar(
        "There was some internal server error while saving the admin. Please try again later.",
        {
          variant: "error"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorPrivate]);

  useEffect(() => {
    if (dataPrivate) {
      enqueueSnackbar("Admin is saved successfully!", {
        variant: "success"
      });
      history.push("/dashboard/admin-management", {
        update: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrivate]);

  const onSave = async () => {
    if (!typeOfAdmin) {
      setFormTypeOfAdminError(true);
      return;
    }

    if (!email) {
      setHasFormEmailError(true);
      return;
    }

    if (!password) {
      setHasFormPasswordError(true);
      return;
    }

    const data = {
      role: typeOfAdmin,
      email,
      password
    };
    try {
      savePrivateForm({
        data
      });
    } catch (e) {
      console.log(e);
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the admin. Please try again later.",
          {
            variant: "error"
          }
        );
      }
    }
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Admin Builder
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/admin-management">
          Admin Management
        </Link>
        <Typography color="textPrimary">Build New Admin</Typography>
      </Breadcrumbs>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              New Admin
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onSave("Publish")}
            >
              Save Admin
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginBottom: "20px" }}>
          <Grid item xs={6}>
            <TextField
              label="Admin Email"
              type="email"
              placeholder="Please enter admin email"
              variant="filled"
              style={{ width: "100%" }}
              value={email}
              onChange={onFormDescriptionUpdate}
              error={hasFormEmailError}
              helperText={hasFormEmailError ? "Email is requied!" : ""}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Admin Password"
              type="password"
              placeholder="Please enter admin password"
              variant="filled"
              style={{ width: "100%" }}
              value={password}
              onChange={onFormPasswordUpdate}
              error={hasFormPasswordError}
              helperText={hasFormPasswordError ? "Password is requied!" : ""}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={3}>
              <Grid item>Type of Admin User</Grid>
              <Grid item>
                <RadioGroup
                  value={typeOfAdmin}
                  onChange={(e) => {
                    setTypeOfAdmin(e.target.value);
                  }}
                  error={hasFormTypeOfAdminError}
                  helperText={
                    hasFormTypeOfAdminError
                      ? "Type of Admin User is requied!"
                      : ""
                  }
                  required
                >
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Administrator"
                    value="administrator"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Super Staff"
                    value="super_staff"
                  />
                  <FormControlLabel
                    control={<Radio color="default" />}
                    label="Staff"
                    value="staff"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Backdrop className={classes.backdrop} open={loadingPrivate}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default AdminBuilder;
