import { gql } from "@apollo/client";

export const GET_ALL_LISTINGS = gql`
  query {
    listingBuilders {
      id
      title
      description
      status
      start_date
      end_date
      available_to
      tnc
      voucher_generation
      voucher_prefix
      number_of_vouchers
      unlimited_vouchers
      merchant_link
      vouchers {
        id
      }
      image {
        id
        name
        url
      }
      thumbnail {
        id
        name
        url
      }
      merchant {
        id
      }
    }
  }
`;

export const GET_LISTING = gql`
  query ($id: ID!) {
    listingBuilder(id: $id) {
      id
      title
      description
      status
      start_date
      end_date
      available_to
      tnc
      voucher_generation
      voucher_prefix
      number_of_vouchers
      unlimited_vouchers
      merchant_link
      vouchers {
        id
        code
      }
      image {
        id
        name
        url
      }
      thumbnail {
        id
        name
        url
      }
      merchant {
        id
      }
    }
  }
`;

export const GET_VOUCHERS_OF_LISTING = gql`
  query ($id: ID!) {
    listingBuilder(id: $id) {
      id
      title
      description
      status
      start_date
      end_date
      available_to
      tnc
      voucher_generation
      voucher_prefix
      number_of_vouchers
      unlimited_vouchers
      merchant_link
      vouchers {
        id
        code
        member {
          id
          email
          first_name
          mobile_no
          organization {
            name
            membership_type
          }
        }
      }
      image {
        id
        name
        url
      }
      thumbnail {
        id
        name
        url
      }
      merchant {
        id
      }
    }
  }
`;
