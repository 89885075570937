import React from "react";
import { Paper, Typography } from "@material-ui/core";

import MembershipSummary from "./MembershipSummary";
import ActiveRewards from "./ActiveRewards";
import ActiveForms from "./ActiveForms";
import ActivePolls from "./ActivePolls";

function Reporting() {
  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Reporting
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Typography variant="h5" color="secondary">
          Membership Summary
        </Typography>
        <MembershipSummary />
        <Typography variant="h5" color="secondary">
          Active Polls
        </Typography>
        <ActivePolls />
        <Typography variant="h5" color="secondary">
          Active Forms
        </Typography>
        <ActiveForms />
        <Typography variant="h5" color="secondary">
          Active Rewards
        </Typography>
        <ActiveRewards />
      </Paper>
    </div>
  );
}

export default Reporting;
