import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Paper,
  Select,
  TextField,
  MenuItem,
  Typography,
  makeStyles,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
  Grid
} from "@material-ui/core";
import CustomTable from "../../../../components/common/Table";
import { Controller, useForm } from "react-hook-form";
import {
  SECONDARY_COLOR,
  allIndustryNames,
  groupedIndustryData
} from "../../../../app/constants";
import { DatePicker } from "@material-ui/pickers";
import { useAuthedAxios } from "../../../../util/axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import moment from "moment";
import qs from "qs";
import { CloseRounded } from "@material-ui/icons";
import { renderMembershipDate } from "../../../../util/utils";

const PER_PAGE_LIMIT = 10;

const FileDownload = require("js-file-download");

const useStyles = makeStyles((theme) => ({
  searchButton: {
    width: "150px",
    height: "30px",
    marginTop: "17px",
    marginLeft: "5px"
  },
  goButton: {
    width: "40px",
    height: "30px",
    marginTop: "15px",
    marginLeft: "5px"
  },
  icons_right: {
    width: "20px",
    height: "20px",
    cursor: "pointer"
  },
  main_filter_div: {
    display: "flex",
    marginBottom: "10px",
    justifyContent: "space-between",
    paddingRight: "10px",
    paddingLeft: "2px"
  },
  filter_checkbox: {
    fontSize: "15px",
    margin: 0,
    padding: 0
  },
  filter_checkbox_label: {
    fontSize: "15px",
    marginLeft: "2px"
  },
  filter_table: {
    borderCollapse: "collapse",
    wordWrap: "break-word",
    tableLayout: "fixed"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function AllMembers() {
  const {
    handleSubmit: searchHandleSubmit,
    register: searchRegister,
    errors: searchError,
    reset: searchFormReset,
    watch
  } = useForm();

  const searchTerm = watch("search_term");
  const { handleSubmit: filterHandleSubmit, control, reset } = useForm();

  const classes = useStyles();
  const history = useHistory();

  const [finalQuery, setFinalQuery] = useState("/members/all");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [createFromDate, setCreateFromDate] = useState(null);
  const [createToDate, setCreateToDate] = useState(null);
  const [expiryFromDate, setExpiryFromDate] = useState(null);
  const [expiryToDate, setExpiryToDate] = useState(null);
  const [industryFilterSearchText, setIndustryFilterSearchText] = useState("");

  const [data, setData] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Organisation Name",
        accessor: "name"
      },
      {
        Header: "UEN",
        accessor: "company_registration_no"
      },
      {
        Header: "Phone No.",
        accessor: "phone"
      },
      {
        Header: "Email",
        accessor: (record) => (
          <span>
            {record.members && record.members.length > 0
              ? record.members.map((m) => (
                  <React.Fragment key={m.id}>
                    <span>
                      <span style={{ fontWeight: 500 }}>
                        ({m.first_name} {m.last_name})
                      </span>{" "}
                      {m.email}
                    </span>
                    <br />
                  </React.Fragment>
                ))
              : // .join(", ")
                "-"}
          </span>
        )
      },
      {
        Header: "Industry",
        accessor: (record) =>
          record.industry_classification &&
          record.industry_classification.length > 0
            ? record.industry_classification[0].title
            : "-"
      },
      {
        Header: "Address",
        accessor: "address"
      },
      {
        Header: "Membership",
        accessor: (record) =>
          record.membership_type ? record.membership_type : "Lite"
      },
      {
        Header: "Registration Date",
        accessor: (record) => renderMembershipDate(record, "registration")
      },
      {
        Header: "Expiry Date",
        accessor: (record) => renderMembershipDate(record, "expiry")
      },
      {
        Header: "Renewal Date",
        accessor: (record) => renderMembershipDate(record, "renewal")
      },
      {
        Header: "Status",
        accessor: (record) =>
          record.membership_status === "Pending"
            ? "Pending Upgrade"
            : record.membership_status
            ? record.membership_status
            : "Active"
      }
    ],
    []
  );

  const onSubmitSearch = ({ search_term }) => {
    setCurrentPage(0);

    const q = `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=${
      0 * PER_PAGE_LIMIT
    }&search=${search_term}`;
    loadMembers(q);
    setFinalQuery(q);
  };

  const onSubmitFilter = (formData) => {
    setCurrentPage(0);

    const data = {
      industry: [],
      search: searchTerm ? searchTerm : undefined,
      created_date_from: createFromDate
        ? moment(createFromDate).toISOString()
        : undefined,
      created_date_to: createToDate
        ? moment(createToDate).toISOString()
        : undefined,
      expiry_date_from: expiryFromDate
        ? moment(expiryFromDate).toISOString()
        : undefined,
      expiry_date_to: expiryToDate
        ? moment(expiryToDate).toISOString()
        : undefined,
      membership_status: [],
      membership_type: []
    };

    console.log(formData);

    Object.keys(formData).forEach((key) => {
      const [type, val] = key.split("___");
      if (type === "i" && formData[key]) {
        data.industry.push(val);
      } else if (type === "s" && formData[key]) {
        data.membership_status.push(val);
      } else if (type === "m" && formData[key]) {
        data.membership_type.push(val);
      }
    });

    if (!data.industry.length) {
      delete data.industry;
    }
    if (!data.membership_status.length) {
      delete data.membership_status;
    }
    if (!data.membership_type.length) {
      delete data.membership_type;
    }

    const query = qs.stringify(data);

    const q =
      `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=${0 * PER_PAGE_LIMIT}&` +
      query;

    loadMembers(q);
    setFinalQuery(q);
  };

  const [{ loading, data: organizations, error }, loadMembers] = useAuthedAxios(
    `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=0`,
    {
      manual: true
    }
  );

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const onFilterReset = () => {
    setCurrentPage(0);
    setIndustryFilterSearchText("");

    const industryObject = {};

    for (const key of allIndustryNames) {
      industryObject[`i___${key?.value}`] = false;
    }

    reset({
      m___Lite: false,
      m___Corporate: false,
      m___Platinum: false,
      m___Associate: false,
      s___Active: false,
      s___Expired: false,
      s___Pending: false,
      s___Renewal: false,
      s___Inactive: false,
      ...industryObject
    });

    setCreateFromDate(null);
    setCreateToDate(null);
    setExpiryFromDate(null);
    setExpiryToDate(null);
    if (searchTerm) {
      const q =
        `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=${
          0 * PER_PAGE_LIMIT
        }&search=` + searchTerm;
      loadMembers(q);
      setFinalQuery(q);
    } else {
      const q = `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=${
        0 * PER_PAGE_LIMIT
      }`;
      loadMembers(q);
      setFinalQuery(q);
    }
  };

  const [{ loading: exporting, data: exportData }, requestExport] =
    useAuthedAxios(
      {
        method: "POST",
        url: "/members/export",
        responseType: "blob"
      },
      {
        manual: true
      }
    );

  useEffect(() => {
    if (exportData) {
      FileDownload(exportData, "members.csv");
    }
  }, [exportData]);

  const onExport = () => {
    const q = finalQuery
      .replace("/members/all?", "")
      .replace("/members/all", "");
    const parsedData = qs.parse(q);
    delete parsedData._limit;
    delete parsedData._offset;
    requestExport({
      data: parsedData
    });
  };

  useEffect(() => {
    if (organizations) {
      setData(organizations.organizations);
      if (!totalCount) {
        setTotalCount(organizations.totalCount);
      }
    }
  }, [organizations, totalCount]);

  const onRowSelect = (organization) => {
    history.push(`/dashboard/members/${organization.id}`);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    const q = finalQuery
      .replace("/members/all?", "")
      .replace("/members/all", "");
    const parsedData = qs.parse(q);
    loadMembers({
      url: `/members/all`,
      params: {
        ...parsedData,
        _offset: page * PER_PAGE_LIMIT
      }
    });
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Member Management
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "40px", display: "grid" }}
      >
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          All Member {totalCount && <span>({totalCount})</span>}
        </Typography>
        <br />
        <div className={classes.main_filter_div}>
          <div
            style={{
              display: "flex"
            }}
          >
            <form onSubmit={searchHandleSubmit(onSubmitSearch)}>
              <TextField
                label="Search"
                inputRef={searchRegister({
                  required: "This field cannot be empty!"
                })}
                name="search_term"
                error={
                  searchError.search_term && searchError.search_term.message
                    ? true
                    : false
                }
                helperText={
                  searchError.search_term && searchError.search_term.message
                    ? searchError.search_term.message
                    : null
                }
                InputProps={{
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          searchFormReset();
                          loadMembers("/members/all");
                        }}
                      >
                        <CloseRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.searchButton}
                type="submit"
              >
                Search
              </Button>
            </form>
            <div
              style={{
                marginLeft: "20px"
              }}
            >
              <FormControl
                style={{
                  width: "150px",
                  marginTop: "10px"
                }}
              >
                <Select
                  name="actions"
                  defaultValue="Bulk Action"
                  style={{
                    color: SECONDARY_COLOR,
                    marginTop: "2px"
                  }}
                  disableUnderline={true}
                >
                  <MenuItem
                    value={"Bulk Action"}
                    style={{
                      color: SECONDARY_COLOR
                    }}
                  >
                    Bulk Action
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.goButton}
                type="submit"
              >
                Go
              </Button>
            </div>
          </div>
          <div
            style={{
              marginTop: "15px"
            }}
          >
            <img
              src="/images/filter.png"
              alt="Filter Icon"
              className={classes.icons_right}
              style={{
                marginRight: "10px"
              }}
              onClick={() => setShowFilter(!showFilter)}
            />
            <img
              src="/images/export.png"
              alt="Export Icon"
              className={classes.icons_right}
              onClick={() => {
                // document.getElementById("allmemberexport").click();
                onExport();
              }}
            />
          </div>
        </div>

        {showFilter && (
          <>
            <form onSubmit={filterHandleSubmit(onSubmitFilter)}>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={7}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Industry</b>
                  </Typography>
                  <div
                    style={{
                      height: "120px",
                      overflow: "auto"
                    }}
                    className="custom-scroll"
                  >
                    <TextField
                      value={industryFilterSearchText}
                      onChange={(e) =>
                        setIndustryFilterSearchText(e.target.value)
                      }
                      placeholder="Search for industry"
                    />
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "10px" }}
                    >
                      <FormGroup aria-label="position">
                        {[...new Set(groupedIndustryData)].map((group) => (
                          <>
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: "10px 0"
                              }}
                            >
                              {group?.label} 👇
                            </div>
                            {group?.options
                              ?.filter((item) =>
                                item?.label
                                  ?.toLowerCase()
                                  ?.includes(
                                    industryFilterSearchText?.toLowerCase()
                                  )
                              )
                              ?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  value="end"
                                  control={
                                    <div>
                                      <Controller
                                        name={`i___${item?.value}`}
                                        control={control}
                                        defaultValue={false}
                                        render={(props) => (
                                          <Checkbox
                                            color="secondary"
                                            size="small"
                                            onChange={(e) =>
                                              props.onChange(e.target.checked)
                                            }
                                            checked={props.value}
                                            style={{
                                              fontSize: "15px",
                                              margin: 0,
                                              padding: 0
                                            }}
                                          />
                                        )} // props contains: onChange, onBlur and value
                                      />
                                    </div>
                                  }
                                  label={
                                    <span
                                      className={classes.filter_checkbox_label}
                                    >
                                      {item?.label?.replace("Sector", "")}
                                    </span>
                                  }
                                  labelPlacement="end"
                                />
                              ))}
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={5}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Membership</b>
                  </Typography>
                  <div>
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "10px" }}
                    >
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___Lite"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Lite
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___Corporate"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Corporate
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___Platinum"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Platinum
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___Associate"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Associate
                            </span>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={4}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Status</b>
                  </Typography>
                  <div>
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "10px" }}
                    >
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          // style={{ margin: 0, padding: 0 }}
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Active"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Active
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Inactive"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Inactive
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Expired"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Expired
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Pending"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Pending Upgrade
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Renewal"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Pending Renewal
                            </span>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={8}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Created Date</b>
                    <div style={{ display: "flex" }}>
                      <DatePicker
                        label="From Date"
                        value={createFromDate}
                        onChange={setCreateFromDate}
                        animateYearScrolling
                        style={{
                          marginRight: "10px"
                        }}
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                      />
                      {createFromDate ? (
                        <DatePicker
                          label="To Date"
                          value={createToDate}
                          onChange={setCreateToDate}
                          minDate={createFromDate}
                          minDateMessage={"The mininim date is not valid"}
                          format="DD/MM/yyyy"
                          animateYearScrolling
                          DialogProps={{
                            clearable: true
                          }}
                        />
                      ) : (
                        <DatePicker
                          label="To Date"
                          value={createToDate}
                          onChange={setCreateToDate}
                          format="DD/MM/yyyy"
                          animateYearScrolling
                          DialogProps={{
                            clearable: true
                          }}
                        />
                      )}
                    </div>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color={"secondary"}
                    style={{ marginTop: "10px" }}
                  >
                    <b>Expiry Date</b>
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      label="From Date"
                      value={expiryFromDate}
                      onChange={setExpiryFromDate}
                      animateYearScrolling
                      style={{
                        marginRight: "10px"
                      }}
                      format="DD/MM/yyyy"
                      DialogProps={{
                        clearable: true
                      }}
                    />
                    {expiryFromDate ? (
                      <DatePicker
                        label="To Date"
                        value={expiryToDate}
                        onChange={setExpiryToDate}
                        minDate={expiryFromDate}
                        minDateMessage={"The mininim date is not valid"}
                        animateYearScrolling
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                      />
                    ) : (
                      <DatePicker
                        label="To Date"
                        value={expiryToDate}
                        onChange={setExpiryToDate}
                        animateYearScrolling
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                      />
                    )}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="default"
                      className={classes.searchButton}
                      onClick={onFilterReset}
                    >
                      Clear Filter
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.searchButton}
                      type="submit"
                    >
                      Filter
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
            <br />
          </>
        )}

        {!error && organizations && (
          <>
            <CustomTable
              columns={columns}
              data={data}
              showSelections={false}
              onRowSelect={onRowSelect}
              onPageChange={onPageChange}
              currentPage={currentPage}
              pageSize={PER_PAGE_LIMIT}
              totalRecordsCount={organizations.pagination.rowCount}
              totalPagesCount={organizations.pagination.pageCount}
              hover
            />
          </>
        )}

        {error && (
          <>
            <Alert severity="error">
              <AlertTitle>Internal Server Error</AlertTitle>
              There was some internal server error while fetching data from
              server. Please try reloading the page!
            </Alert>
          </>
        )}
      </Paper>
      <Backdrop className={classes.backdrop} open={loading || exporting}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default AllMembers;
