import { gql } from "@apollo/client";

export const GET_ALL_WORKSHOPS = gql`
  query {
    workshops {
      id
      title
      date
      location
      price
      tag
      thumbnail {
        id
        name
        url
      }
      workshop_submissions {
        id
      }
      form_builder {
        id
      }
    }
  }
`;
