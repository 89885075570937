import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Modal,
  Divider
} from "@material-ui/core";
import { Close, LinkOutlined } from "@material-ui/icons";
import ImageUploader from "react-images-upload";
import { GET_FORM } from "../polls.graphql";
import moment from "moment";
import slugify from "slugify";
import { AutorenewOutlined } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { DragDropContext } from "react-beautiful-dnd";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  authedAxios,
  useAuthedAxios,
  MEMBERS_SITE_URL
} from "../../../../../util/axios";
import {
  copy,
  FormDropzone,
  reorder
} from "../../FormManagement/FormBuilder/builder";
import FormBuilderContext from "../../FormManagement/FormBuilder/form-builder-context";
import {
  Toolbox,
  TOOLBOX_ELEMENTS
} from "../../FormManagement/FormBuilder/toolbox";
import FormPreview from "../../FormManagement/components/form-preview";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function EditPoll() {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [formHeader, setFormHeader] = React.useState("");
  const [slug, setFormSlug] = React.useState("");
  const [formDescription, setFormDescription] = React.useState("");
  const [url, setFormUrl] = React.useState("");
  const [availableTo, setAvailableTo] = React.useState("member");
  const [allowMultiSelect, setAllowMultiSelect] = React.useState("false");
  const [hasFormHeaderError, setFormHeaderError] = React.useState(false);
  const [hasformSlugError, setFormSlugError] = React.useState(false);
  const [selectedElements, setSelectedElements] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [hasStartDateError, setStartDateError] = React.useState(false);
  const [hasEndDateError, setEndDateError] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [isModalVisible, setModalVisibility] = React.useState(false);

  //   Loading Current Form
  const { loading, error, data, refetch } = useQuery(GET_FORM, {
    variables: { id }
  });

  const handleClose = () => {
    setModalVisibility(false);
  };

  useEffect(() => {
    if (data) {
      const { pollBuilder } = data;
      if (!pollBuilder) {
        history.push("/dashboard/poll-management");
        return;
      }
      const {
        title,
        slug,
        description,
        form,
        start_date,
        end_date,
        allow_multiple_select,
        available_to
      } = pollBuilder;
      console.log(pollBuilder);
      const startDate = moment(start_date);
      const endDate = moment(end_date);
      setFormHeader(title);
      setFormSlug(slug);
      setFormDescription(description || "");
      setStartDate(startDate);
      setEndDate(endDate);
      setFormUrl(`${MEMBERS_SITE_URL}/polls/${slug}`);
      setSelectedElements([...form]);
      setAllowMultiSelect(allow_multiple_select.toString());
      setAvailableTo(available_to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, history]);

  const onDragEnd = React.useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      switch (source.droppableId) {
        case destination.droppableId:
          setSelectedElements((state) => [
            ...reorder(state, source.index, destination.index)
          ]);
          break;
        case "TOOLBOX":
          setSelectedElements((state) => [
            ...copy(TOOLBOX_ELEMENTS, state, source, destination)
          ]);
          break;
        default:
          break;
      }
    },
    [setSelectedElements]
  );

  const onDelete = (itemId) => {
    const newItems = selectedElements.filter((item) => item.id !== itemId);
    setSelectedElements(newItems);
  };

  const onSettingField = (item, settings) => {
    const itemIndex = selectedElements.findIndex((i) => i.id === item.id);
    const newElements = [...selectedElements];
    newElements[itemIndex] = { ...item, settings };
    setSelectedElements(newElements);
  };

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setFormHeader(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onFormSlugUpdate = (e) => {
    const value = e.target.value;
    setFormSlug(value);
    setFormUrl(`${MEMBERS_SITE_URL}/polls/${value}`);
    if (!value) {
      setFormSlugError(true);
    } else if (/^[a-z0-9](-?[a-z0-9])*$/.test(value)) {
      setFormSlugError(false);
    } else {
      setFormSlugError(true);
    }
  };

  const onGenerateSlug = (e) => {
    const generatedSlug = slugify(formHeader || "", {
      replacement: "-",
      lower: true,
      remove: /[^-a-zA-Z0-9 ]/g
    });
    if (generatedSlug) {
      setFormSlug(generatedSlug);
      setFormUrl(`${MEMBERS_SITE_URL}/polls/${generatedSlug}`);
    }
  };

  const onSlugCopy = () => {
    navigator.clipboard &&
      navigator.clipboard.writeText(url).then(() =>
        enqueueSnackbar("Link is successfully copied to clipboard!", {
          variant: "success",
          preventDuplicate: true
        })
      );
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setFormDescription(value);
  };

  const onChangeStartDate = (date) => {
    if (date) {
      setStartDateError(false);
      setStartDate(date);
    } else {
      setStartDate(null);
      setStartDateError(true);
    }
  };

  const onChangeEndDate = (date) => {
    if (date) {
      setEndDateError(false);
      setEndDate(date);
    } else {
      setEndDate(null);
      setEndDateError(true);
    }
  };

  const [
    { loading: updating, data: updateData, error: updateError },
    updateForm
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(
        "There was some internal server error while saving the poll. Please try again later.",
        {
          variant: "error"
        }
      );
    }
  }, [updateError, enqueueSnackbar]);

  useEffect(() => {
    if (updateData) {
      enqueueSnackbar("Poll is updated successfully!", {
        variant: "success",
        preventDuplicate: true
      });
      refetch();
    }
  }, [updateData, enqueueSnackbar, refetch]);

  const getDate = (date) => {
    const day = moment(date).get("D");
    const month = moment(date).get("M");
    const year = moment(date).get("Y");

    const d = day < 10 ? `0${day}` : day;
    const m = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`;
    return `${year}-${m}-${d}T00:00:00.000Z`;
  };

  const [picture, setPicture] = React.useState([]);

  const onDrop = (pictureFiles) => {
    setPicture(pictureFiles);
  };

  const onImageChange = () => {
    const [image] = picture;
    const formData = new FormData();

    formData.append("data", JSON.stringify(data.pollBuilder));
    formData.append("files.image", image, image.name);
    updateForm({
      url: `/poll-builders/${data.pollBuilder.id}`,
      data: formData
    });
  };

  const onSave = async (status) => {
    if (!formHeader) {
      setFormHeaderError(true);
      return;
    }
    if (!slug) {
      setFormSlugError(true);
      return;
    }
    if (!startDate) {
      setStartDateError(true);
      return;
    }
    if (!endDate) {
      setEndDateError(true);
      return;
    }
    const formData = {
      status,
      title: formHeader,
      slug,
      description: formDescription,
      start_date: getDate(startDate),
      end_date: getDate(endDate),
      // url,
      available_to: availableTo,
      allow_multiple_select: allowMultiSelect,
      form: selectedElements.map(({ icon, ...rest }) => ({ ...rest }))
    };
    setFormUrl(`${MEMBERS_SITE_URL}/polls/${slug}`);
    try {
      // Check if slug exists
      if (slug !== data.pollBuilder.slug) {
        const response = await authedAxios.get(`/poll-builders?slug=${slug}`);
        // const response2 = await authedAxios.get(`/public-poll-builders?slug=${slug}`);

        if (response.data.length > 0) {
          enqueueSnackbar(
            <span>
              The slug <b>{slug}</b> is already being used! Please use another
              slug.
            </span>,
            {
              variant: "error",
              preventDuplicate: true
            }
          );
          return;
        }
      }
      updateForm({
        data: formData,
        url: `/poll-builders/${data.pollBuilder.id}`
      });
    } catch (e) {
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the poll. Please try again later.",
          {
            variant: "error",
            preventDuplicate: true
          }
        );
      }
    }
  };

  const onPreview = () => {
    setShowPreview(true);
  };

  return (
    <>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Edit Poll
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/poll-management">
          Poll Management
        </Link>
        <Typography color="textPrimary">Edit Poll</Typography>
      </Breadcrumbs>
      <FormBuilderContext.Provider
        value={{
          items: selectedElements,
          onDelete,
          onSettingField,
          title: formHeader
        }}
      >
        <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
          {!loading && !updating && data && (
            <>
              <Grid container spacing={3} style={{ marginBottom: "10px" }}>
                <Grid item xs={6} sm={2}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    Poll Builder
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={10} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "10px" }}
                    onClick={onPreview}
                  >
                    Preview
                  </Button>
                  <Chip
                    label={
                      data.pollBuilder.status === "Draft"
                        ? "Draft"
                        : "Published"
                    }
                    color={
                      data.pollBuilder.status === "Draft"
                        ? "default"
                        : "secondary"
                    }
                    style={{
                      marginRight: "10px"
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="default"
                    style={{ marginRight: "10px" }}
                    disabled={selectedElements.length === 0}
                    onClick={() => onSave("Draft")}
                  >
                    Save as Draft
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={selectedElements.length === 0}
                    onClick={() => onSave("Publish")}
                  >
                    Publish Poll
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <TextField
                    label="Poll Header"
                    placeholder="Please enter poll title"
                    style={{ width: "100%" }}
                    variant="filled"
                    value={formHeader}
                    onChange={onFormHeaderUpdate}
                    error={hasFormHeaderError}
                    helperText={
                      hasFormHeaderError ? "Poll header is requied!" : ""
                    }
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={
                      <span>
                        Poll Slug
                        {/* {slug && (
                          <small>
                            {" "}
                            (https://members.asme.org.sg/poll/{slug})
                          </small>
                        )} */}
                      </span>
                    }
                    placeholder="i.e. poll"
                    variant="filled"
                    style={{ width: "100%" }}
                    value={slug}
                    onChange={onFormSlugUpdate}
                    error={hasformSlugError}
                    helperText={
                      hasformSlugError
                        ? "Poll slug is required and it must contain lowercase letters, numbers, or single hyphens only!"
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* {slug && !hasformSlugError && (
                            <IconButton
                              aria-label="copy slug"
                              onClick={onSlugCopy}
                              size="small"
                            >
                              <LinkOutlined />
                            </IconButton>
                          )} */}
                          <IconButton
                            aria-label="generate slug"
                            onClick={onGenerateSlug}
                            size="small"
                          >
                            <AutorenewOutlined />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Poll Description"
                    placeholder="Please enter poll description (optional)"
                    variant="filled"
                    style={{ width: "100%" }}
                    value={formDescription}
                    onChange={onFormDescriptionUpdate}
                    multiline
                    rows={5}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <div style={{ display: "flex" }}>
                      <DatePicker
                        label="Start Date"
                        required
                        value={startDate}
                        onChange={(date) => onChangeStartDate(date)}
                        animateYearScrolling
                        style={{
                          marginRight: "10px",
                          height: "55px"
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: "10px",
                            paddingTop: startDate ? "7px" : "0px"
                          }
                        }}
                        InputProps={{
                          style: {
                            paddingLeft: "10px",
                            marginTop: "22px"
                          }
                        }}
                        className="date-filled-input"
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                        error={hasStartDateError}
                        helperText={
                          hasStartDateError
                            ? "Please select the start date!"
                            : ""
                        }
                      />
                      {startDate ? (
                        <DatePicker
                          required
                          label="End Date"
                          className="date-filled-input"
                          value={endDate}
                          onChange={(date) => onChangeEndDate(date)}
                          minDate={startDate}
                          minDateMessage={"The mininim date is not valid"}
                          format="DD/MM/yyyy"
                          style={{
                            height: "55px"
                          }}
                          InputLabelProps={{
                            style: {
                              paddingLeft: "10px",
                              paddingTop: endDate ? "7px" : "0px"
                            }
                          }}
                          InputProps={{
                            style: {
                              paddingLeft: "10px",
                              marginTop: "22px"
                            }
                          }}
                          animateYearScrolling
                          DialogProps={{
                            clearable: true
                          }}
                          error={hasEndDateError}
                          helperText={
                            hasEndDateError ? "Please select the end date!" : ""
                          }
                        />
                      ) : (
                        <DatePicker
                          required
                          className="date-filled-input"
                          label="End Date"
                          DialogProps={{
                            clearable: true
                          }}
                          value={endDate}
                          onChange={(date) => onChangeEndDate(date)}
                          format="DD/MM/yyyy"
                          animateYearScrolling
                          style={{
                            height: "55px"
                          }}
                          InputLabelProps={{
                            style: {
                              paddingLeft: "10px",
                              paddingTop: endDate ? "7px" : "0px"
                            }
                          }}
                          InputProps={{
                            style: {
                              paddingLeft: "10px",
                              marginTop: "22px"
                            }
                          }}
                          error={hasEndDateError}
                          helperText={
                            hasEndDateError ? "Please select the end date!" : ""
                          }
                        />
                      )}
                    </div>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginottom: "20px" }}>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>Available To</Grid>
                    {/* <Divider /> */}
                    <Grid item>
                      <RadioGroup
                        value={availableTo}
                        onChange={(e) => setAvailableTo(e.target.value)}
                        style={{ display: "flow-root" }}
                      >
                        <FormControlLabel
                          control={<Radio color="default" />}
                          label="Public"
                          value="public"
                        />
                        <FormControlLabel
                          control={<Radio color="default" />}
                          label="Members Only"
                          value="member"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item>Allow Selecting Multiple Options</Grid>
                    <Grid item>
                      <RadioGroup
                        value={allowMultiSelect}
                        onChange={(e) => setAllowMultiSelect(e.target.value)}
                        style={{ display: "flow-root" }}
                      >
                        <FormControlLabel
                          control={<Radio color="default" />}
                          label="Yes"
                          value="true"
                        />
                        <FormControlLabel
                          control={<Radio color="default" />}
                          label="No"
                          value="false"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item>
                      <TextField
                        label={<span>Poll URL</span>}
                        placeholder="i.e. poll"
                        variant="filled"
                        style={{ width: "100%" }}
                        value={slug && `${MEMBERS_SITE_URL}/polls/${slug}`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {slug && !hasformSlugError && (
                                <IconButton
                                  aria-label="copy slug"
                                  onClick={onSlugCopy}
                                  size="small"
                                >
                                  <LinkOutlined />
                                </IconButton>
                              )}
                              {/* <IconButton
                                aria-label="generate slug"
                                onClick={onGenerateSlug}
                                size="small"
                              >
                                <AutorenewOutlined />
                              </IconButton> */}
                            </InputAdornment>
                          )
                        }}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {data.pollBuilder && data.pollBuilder.image ? (
                    <div
                      onClick={() => setModalVisibility(true)}
                      style={{
                        height: "170px",
                        maxWidth: "100%",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <img
                        src={`http://localhost:3009${data.pollBuilder.image.url}`}
                        alt="Poll Description"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%"
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      onClick={() => setModalVisibility(true)}
                      style={{
                        height: "170px",
                        maxWidth: "150px",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <img
                        src="/images/logo.jpg"
                        alt="ASME LOGO"
                        style={{
                          width: "100%",
                          height: "150px"
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Grid container className={classes.root} spacing={2}>
                  <Grid xs={8} item>
                    <Paper variant="outlined">
                      <FormDropzone />
                    </Paper>
                  </Grid>
                  <Grid xs={4} item>
                    <Paper variant="outlined">
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ padding: "10px 0" }}
                      >
                        Toolbox
                      </Typography>
                      <Toolbox isPollBuilder={true} />
                    </Paper>
                  </Grid>
                </Grid>
              </DragDropContext>
            </>
          )}
          {!loading && error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              There was some internal server error while fetching records.
              Please try reloading the page.
            </Alert>
          )}
          {(loading || updating) && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </div>
          )}
        </Paper>
      </FormBuilderContext.Provider>
      <FormPreview
        key="edit-preview"
        open={showPreview}
        onHide={() => setShowPreview(false)}
        data={{
          items: selectedElements,
          title: formHeader
        }}
      />
      <Modal
        open={isModalVisible}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          className={classes.modal}
          style={{
            position: "absolute",
            width: "50%",
            // height: "35%",
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            border: "none"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Image</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onDrop}
            buttonStyles={{ background: "#de1736", color: "#fff" }}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Image"
            singleImage
            withPreview
          />
          {picture && picture.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                  onImageChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default EditPoll;
