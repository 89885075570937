import { gql } from "@apollo/client";

export const GET_ALL_MERCHANTS = gql`
  query {
    merchants {
      id
      email
      password
      name
    }
  }
`;

export const GET_MERCHANT = gql`
  query ($id: ID!) {
    merchant(id: $id) {
      id
      email
      password
      name
    }
  }
`;
