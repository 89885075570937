import XLSX from "xlsx";
/* generate an array of column objects */
export const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (let i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

export const allColumnsName = (ws) => {
  const header = [];
  const columnCount = XLSX.utils.decode_range(ws["!ref"]).e.c + 1;
  for (let i = 0; i < columnCount; ++i) {
    const h = ws[`${XLSX.utils.encode_col(i)}1`];
    if (h) header[i] = h.v;
  }

  return header;
};
