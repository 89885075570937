import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import React from "react";

function CheckboxRenderer({ settings, id, register }) {
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        control={
          <Checkbox
            name={id}
            required={settings.required}
            inputRef={register}
          />
        }
        label={settings.title}
      />
      <FormHelperText>{settings.help}</FormHelperText>
    </FormControl>
  );
}

export default CheckboxRenderer;
