import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import { allColumnsName, make_cols } from "./MakeColumns";
import { SheetJSFT } from "./types";
import { EMAIL_REGEX } from "../../../app/constants";
import { authedAxios } from "../../../util/axios";

import {
  Allowed_Columns,
  HAS_DUPLICATIONS,
  HAS_DUPLICATIONS_UENS,
  POSSIBLE_BUSINESS_NATURE,
  POSSIBLE_CITIZENSHIP,
  POSSIBLE_LEGAL_STRUCTURE,
  POSSIBLE_OWNERSHIP_TYPE,
  POSSIBLE_SALUTATIONS,
  VALIDATE_ANNUAL_TURNOVER,
  VALIDATE_BUSINESS_NATURE,
  VALIDATE_CITIZENSHIP,
  VALIDATE_COMPANY_UEN,
  VALIDATE_FACEBOOK,
  VALIDATE_LEGAL_STRUCTURE,
  VALIDATE_OWNERSHIP_TYPE,
  VALIDATE_PAID_UP_CAPITAL,
  VALIDATE_PHONE_NUMBER,
  VALIDATE_SALUTAION,
  VALIDATE_STAFF_STRENGTH,
  VALIDATE_WEBSITE,
  VALIDATE_YEAR,
} from "./validators";

function ExcelReader({ onUploadExcel }) {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);

  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    if (file) {
      handleFile();
    }
  }, [file]);

  const handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellText: false,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      setData(data);
      setCols(make_cols(ws["!ref"]));

      /* Get Name of Columns */
      const columnsNames = allColumnsName(ws);

      /* Check Columns Names */
      // 1. Columns must be equal to Columns List

      const missingColumns = Allowed_Columns.filter(
        (a) => !columnsNames.includes(a)
      );

      if (missingColumns.length > 0) {
        onUploadExcel({
          error: true,
          error_message:
            "Following Columns are missing: " + missingColumns.join(", "),
        });
        return;
      }

      // 2. Find index of row where orgName or companyUEN or email is missing. use .findIndex

      const nullIndex = data.findIndex(
        (row) =>
          !row["Organisation Name"] ||
          // !row["Company Registration No. (UEN)"] ||
          !row["Email Address"] ||
          // !row["Salutation"] ||
          !row["Designation"] ||
          !row["First Name"] ||
          !row["Last Name"]
        // ||
        // !row["Mobile No."] ||
        // !row["Citizenship"]
      );

      // 2a. Read returned obj and find which key is missing.  HARDCODE TEST

      if (nullIndex > -1) {
        const obj = data[nullIndex];
        if (!Object.keys(obj).includes("Organisation Name")) {
          onUploadExcel({
            error: true,
            error_message: `Organsition Name is missing in Row Number ${data[nullIndex].__rowNum__}`,
          });
          return;
        } else if (!Object.keys(obj).includes("Email Address")) {
          onUploadExcel({
            error: true,
            error_message: `Email is missing in Row Number ${data[nullIndex].__rowNum__}`,
          });
          return;
        }
        // if (
        //   !Object.keys(obj).includes("Company Registration No. (UEN)")
        // ) {
        //   onUploadExcel({
        //     error: true,
        //     error_message: `Company Registration No. (UEN) is missing in  Row Number ${data[nullIndex].__rowNum__}`,
        //   });
        //   return;
        // } else if (!Object.keys(obj).includes("Salutation")) {
        //   onUploadExcel({
        //     error: true,
        //     error_message: `Salutation is missing in  Row Number ${data[nullIndex].__rowNum__}`,
        //   });
        //   return;
        // } else
        else if (!Object.keys(obj).includes("Designation")) {
          onUploadExcel({
            error: true,
            error_message: `Designation is missing in  Row Number ${data[nullIndex].__rowNum__}`,
          });
          return;
        } else if (!Object.keys(obj).includes("First Name")) {
          onUploadExcel({
            error: true,
            error_message: `First Name is missing in  Row Number ${data[nullIndex].__rowNum__}`,
          });
          return;
        } else if (!Object.keys(obj).includes("Last Name")) {
          onUploadExcel({
            error: true,
            error_message: `Last Name is missing in  Row Number ${data[nullIndex].__rowNum__}`,
          });
          return;
        }
        // else if (!Object.keys(obj).includes("Mobile No.")) {
        //   onUploadExcel({
        //     error: true,
        //     error_message: `Mobile No. is missing in  Row Number ${data[nullIndex].__rowNum__}`,
        //   });
        //   return;
        // } else if (!Object.keys(obj).includes("Citizenship")) {
        //   onUploadExcel({
        //     error: true,
        //     error_message: `Citizenship is missing in  Row Number ${data[nullIndex].__rowNum__}`,
        //   });
        //   return;
        // }
      }

      // 3. Validate Emails.
      // const invalid_email_data_index = data.findIndex((d) => !d.email.test(EMAIL_PATTERN));
      // return { rowId: invalid_email_data_index, message: "Invalid email format on row ${invalid_email_data_index}" }

      const regex = EMAIL_REGEX;
      const invalid_email_data_index = data.findIndex(
        (d) => !regex.test(d["Email Address"])
      );

      if (invalid_email_data_index > -1) {
        onUploadExcel({
          error: true,
          error_message: `Email is not valid in Row Number ${data[invalid_email_data_index].__rowNum__}. (${data[invalid_email_data_index]["Email Address"]})`,
        });
        return;
      }

      // 4. Check Duplications

      if (HAS_DUPLICATIONS("Email Address", data)) {
        onUploadExcel({
          error: true,
          error_message: `Duplicate entries found for field: Email.`,
        });
        return;
      } else if (
        HAS_DUPLICATIONS_UENS("Company Registration No. (UEN)", data)
      ) {
        onUploadExcel({
          error: true,
          error_message: `Duplicate entries found for field: Company Registration No. (UEN).`,
        });
        return;
      }

      // 5. Validating All Other Fields

      for (let index = 0; index < data.length; index++) {
        const row = data[index];
        if (
          row["Company Registration No. (UEN)"] &&
          !VALIDATE_COMPANY_UEN(row["Company Registration No. (UEN)"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Company Registration No. (UEN) is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Year of Incorporation"] &&
          !VALIDATE_YEAR(row["Year of Incorporation"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Year of Incorporation is not a valid Date in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Phone No."] &&
          !VALIDATE_PHONE_NUMBER(row["Phone No."])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Phone No. is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Staff Strength"] &&
          !VALIDATE_STAFF_STRENGTH(row["Staff Strength"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Staff Strength is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Annual Turnover (SGD)"] &&
          !VALIDATE_ANNUAL_TURNOVER(row["Annual Turnover (SGD)"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Annual Turnover (SGD) is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Paid Up Capital (SGD)"] &&
          !VALIDATE_ANNUAL_TURNOVER(row["Paid Up Capital (SGD)"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Paid Up Capital (SGD) is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Paid Up Capital (SGD)"] &&
          !VALIDATE_PAID_UP_CAPITAL(row["Paid Up Capital (SGD)"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Paid Up Capital (SGD) is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (row["Website"] && !VALIDATE_WEBSITE(row["Website"])) {
          onUploadExcel({
            error: true,
            error_message: `Website is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (row["Facebook"] && !VALIDATE_FACEBOOK(row["Facebook"])) {
          onUploadExcel({
            error: true,
            error_message: `Facebook Url is not valid in Row Number ${data[index].__rowNum__}.`,
          });
          return;
        } else if (
          row["Legal Structure of Company"] &&
          !VALIDATE_LEGAL_STRUCTURE(row["Legal Structure of Company"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Legal Structure of Company is not valid in Row Number ${
              data[index].__rowNum__
            }. It must be one of these: ${POSSIBLE_LEGAL_STRUCTURE.join(
              ", "
            )}.`,
          });
          return;
        } else if (
          row["Ownership Type"] &&
          !VALIDATE_OWNERSHIP_TYPE(row["Ownership Type"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Ownership Type is not valid in Row Number ${
              data[index].__rowNum__
            }. It must be one of these: ${POSSIBLE_OWNERSHIP_TYPE.join(", ")}.`,
          });
          return;
        } else if (
          row["Nature of Business"] &&
          !VALIDATE_BUSINESS_NATURE(row["Nature of Business"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Nature of Business is not valid in Row Number ${
              data[index].__rowNum__
            }. It must be one of these: ${POSSIBLE_BUSINESS_NATURE.join(
              ", "
            )}.`,
          });
          return;
        } else if (
          row["Salutation"] &&
          !VALIDATE_SALUTAION(row["Salutation"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Salutation Type is not valid in Row Number ${
              data[index].__rowNum__
            }. It must be one of these: ${POSSIBLE_SALUTATIONS.join(", ")}.`,
          });
          return;
        } else if (
          row["Citizenship"] &&
          !VALIDATE_CITIZENSHIP(row["Citizenship"])
        ) {
          onUploadExcel({
            error: true,
            error_message: `Citizenship Type is not valid in Row Number ${
              data[index].__rowNum__
            }. It must be one of these: ${POSSIBLE_CITIZENSHIP.join(", ")}.`,
          });
          return;
        }
      }

      // 6. Check whether the email or company uen are already registered or not.

      try {
        await authedAxios.post("/organizations/invitations/verify", {
          emails: data.map((d) => d["Email Address"]),
          uens: data.map((d) => d["Company Registration No. (UEN)"]),
        });
      } catch (e) {
        const error =
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : "There was some internal some error while validating emails. Please try again!";
        onUploadExcel({
          error: true,
          error_message: error,
        });
        return;
      }

      onUploadExcel({
        error: false,
        data: data,
      });
      return;
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <div>
        <input
          type="file"
          className="form-control"
          id="importExcelFile"
          accept={SheetJSFT}
          onChange={handleChange}
          style={{
            display: "none",
          }}
        />
        <input
          style={{
            display: "none",
          }}
          type="submit"
          value="Process Triggers"
          id="importExcelSubmit"
          onClick={handleFile}
        />
      </div>
    </>
  );
}
export default ExcelReader;
