import React from "react";
import {
  Badge,
  Chip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
} from "@material-ui/core";
import { getItemStyle } from "../styles";
import {
  CheckBoxTwoTone,
  DeleteRounded,
  EditRounded,
} from "@material-ui/icons";

function CheckboxGroupRenderer({ item, snapshot, provided, onEdit, onDelete }) {
  return (
    <>
      <ListSubheader disableSticky>
        <Chip color="secondary" size="small" label={item.label} />
      </ListSubheader>
      <div style={{ padding: "0 10px" }}>
        <ListItem
          ContainerComponent="li"
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <ListItemIcon>
            <CheckBoxTwoTone />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                <>
                  {item.settings && item.settings.title
                    ? item.settings.title
                    : item.label}{" "}
                  {item.settings ? (
                    <small>
                      (Min: {item.settings.min}, Max: {item.settings.max})
                    </small>
                  ) : null}
                </>
              </span>
            }
            secondary={
              <span>
                {item.settings.options.map((option) => option.value).join(", ")}
              </span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => onEdit(item)}
            >
              <EditRounded fontSize="small" color="primary" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onDelete(item.id)}
            >
              <DeleteRounded fontSize="small" color="error" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    </>
  );
}

export default CheckboxGroupRenderer;
