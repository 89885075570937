import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import ImageUploader from "react-images-upload";

import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Chip,
  makeStyles,
  Modal,
  Snackbar,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { useAuthedAxios, BASE_URL } from "../../../../../../util/axios";

const useStyles = makeStyles((theme) => ({
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function Contact({ classes, organization, onImageUpdateSuccess }) {
  const styles = useStyles();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [picture, setPicture] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [hasData, setHasData] = useState(false);

  const handleClose = () => {
    setModalVisibility(false);
  };

  const onDrop = (picture) => {
    setPicture(picture);
  };

  const [{ loading, data, error }, updateOrganization] = useAuthedAxios(
    `/organizations/update/${organization.id}`,
    {
      manual: true
    }
  );

  useEffect(() => {
    if (data) {
      setHasData(true);
      onImageUpdateSuccess();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  const onImageChange = () => {
    const [image] = picture;

    const formData = new FormData();

    formData.append("data", JSON.stringify(organization));
    formData.append("files.logo", image, image.name);

    updateOrganization({
      method: "PUT",
      data: formData
    });
  };

  return (
    <>
      <div className={classes.avtarOuter}>
        <div className={classes.avtarDiv}>
          {/* <EditIcon
            fontSize="inherit"
            color="secondary"
            onClick={() => setModalVisibility(true)}
            style={{
              cursor: "pointer",
            }}
          /> */}
        </div>
        {organization.logo ? (
          <div
            style={{
              height: "170px",
              maxWidth: "150px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              src={`${BASE_URL}${organization.logo.url}`}
              alt="Company Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "150px"
              }}
            />
          </div>
        ) : (
          <div
            style={{
              height: "170px",
              maxWidth: "150px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              src="/images/asme_grey_logo.jpg"
              alt="Company Logo"
              style={{
                width: "100%",
                height: "150px"
              }}
            />
          </div>
        )}
      </div>
      <br />
      <Typography variant="subtitle1" color={"secondary"}>
        Contact
        {/* <EditIcon fontSize="inherit" /> */}
      </Typography>
      {organization.members.map((member) => (
        <React.Fragment key={member.id}>
          <Typography variant="subtitle1" color={"secondary"}>
            {" "}
            {!member.user ? (
              <Chip size="small" label="Pending" />
            ) : (
              <Chip size="small" label="Active" />
            )}{" "}
          </Typography>
          <table
            style={{
              borderCollapse: "collapse",
              wordWrap: "break-word",
              overflowWrap: "anywhere",
              tableLayout: "fixed"
            }}
          >
            <tbody>
              <tr>
                <td>
                  {member.salutation}. {member.first_name} {member.last_name}
                </td>
              </tr>
              <tr>
                <td>{member.designation}</td>
              </tr>
              <tr>
                <td>{member.email}</td>
              </tr>
              <tr>
                <td>{member.mobile_no}</td>
              </tr>
            </tbody>
          </table>
          <br />
        </React.Fragment>
      ))}

      <Modal
        open={isModalVisible}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div className={styles.modal}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Organisation Info</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Image"
            singleImage
            withPreview
          />
          {picture && picture.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                  onImageChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>

      <Snackbar
        open={hasData}
        autoHideDuration={3000}
        onClose={() => setHasData(false)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
      >
        <Alert onClose={() => setHasData(false)} severity="success">
          Data Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={hasError}
        autoHideDuration={3000}
        onClose={() => setHasError(false)}
      >
        <Alert onClose={() => setHasError(false)} severity="error">
          There was some internal server error while processing your request.
          Please try again!
        </Alert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  );
}

export default Contact;
