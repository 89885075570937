import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useLocation, useParams } from "react-router-dom";
import fileDownload from "js-file-download";

import CustomTable from "../../../../../components/common/Table";
import { authedAxios } from "../../../../../util/axios";
import { GET_VOUCHERS_OF_LISTING } from "../rewards.graphql";

function ViewRedemptions() {
  const { state } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams();

  const { loading, error, data, refetch } = useQuery(GET_VOUCHERS_OF_LISTING, {
    pollInterval: 5000,
    variables: { id }
  });

  useEffect(() => {
    if (state && state.update) {
      refetch();
    }
  }, [state, refetch]);

  const onDownloadVouchersRedemptions = React.useCallback(
    async (title, id) => {
      const key = enqueueSnackbar(
        `Generating Redemptions for listing '${title}'!`,
        {
          preventDuplicate: true,
          variant: "info"
        }
      );
      authedAxios({
        url: `/vouchers/downloadVouchersRedemptions/${id}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then((res) => {
          fileDownload(res.data, title + ".csv");
          closeSnackbar(key);
          enqueueSnackbar(
            `Redemptions for listing '${title}' are downloaded successfully!`,
            {
              preventDuplicate: true,
              variant: "success"
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(
            "There was some internal server error while downloading the Redemptions. Please try again later!",
            {
              preventDuplicate: true,
              variant: "error"
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>
      },
      {
        Header: "Organization Name",
        accessor: (record) =>
          record.member?.organization.name
            ? record.member?.organization.name
            : "Not Available"
      },
      {
        Header: "Name",
        accessor: (record) =>
          record.member?.first_name
            ? record.member?.first_name
            : "Not Available"
      },
      {
        Header: "Phone No.",
        accessor: (record) =>
          record.member?.mobile_no ? record.member?.mobile_no : "Not Available"
      },
      {
        Header: "Email",
        accessor: (record) =>
          record.member?.email ? record.member?.email : "Not Available"
      },
      {
        Header: "Membership",
        accessor: (record) =>
          record.member?.organization.membership_type
            ? record.member?.organization.membership_type
            : "Not Available"
      },
      //   {
      //     Header: "Date Claimed",
      //     accessor: (record) => moment(record.end_date).format("DD-MM-YYYY")
      //   },
      {
        Header: "Voucher Code",
        accessor: (record) => (record.code ? record.code : "Not Available")
      }
    ],
    []
  );

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        {data && data.listingBuilder.title
          ? data.listingBuilder.title
          : "Vouchers"}
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              Redemptions
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            {data && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  onDownloadVouchersRedemptions(
                    data.listingBuilder.title,
                    data.listingBuilder.id
                  )
                }
              >
                Download Redemptions
              </Button>
            )}
          </Grid>
        </Grid>
        {data && (
          <Grid container spacing={3}>
            <CustomTable
              columns={columns}
              data={data.listingBuilder.vouchers || []}
              showSelections={false}
              showPagination={false}
              hover
            />
          </Grid>
        )}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was some internal server error while fetching records. Please
            try reloading the page.
          </Alert>
        )}
      </Paper>

      <Backdrop open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default ViewRedemptions;
