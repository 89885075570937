import React from "react";
import TextFieldRenderer from "./TextField";
import {
  CHECKBOX,
  FILE_UPLOAD,
  PARAGRAPH,
  RADIO_BUTTONS,
  TEXT_FIELD,
  PLAIN_TEXT,
  CHECKBOX_GROUP,
} from "../../../containers/Admin/Routes/FormManagement/FormBuilder/constants";
import { Grid } from "@material-ui/core";
import ParagraphRenderer from "./Paragraph";
import CheckboxRenderer from "./Checkbox";
import RadioButtonsRenderer from "./RadioButtons";
import UploadFileRenderer from "./UploadFile";
import PlainTextRenderer from "./PlainText";
import CheckboxGroupRenderer from "./CheckboxGroup";

export const renderItem = (
  { type, id, settings },
  { member, organization },
  { register, setValue, watch, errors }
) => {
  switch (type) {
    case TEXT_FIELD:
      return (
        <TextFieldRenderer
          id={id}
          settings={settings}
          member={member}
          organization={organization}
          register={register}
        />
      );
    case PARAGRAPH:
      return (
        <ParagraphRenderer
          id={id}
          settings={settings}
          member={member}
          organization={organization}
          register={register}
        />
      );
    case CHECKBOX:
      return (
        <CheckboxRenderer id={id} settings={settings} register={register} />
      );
    case CHECKBOX_GROUP:
      return (
        <CheckboxGroupRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
          watch={watch}
          errors={errors}
        />
      );
    case RADIO_BUTTONS:
      return (
        <RadioButtonsRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
        />
      );
    case FILE_UPLOAD:
      return (
        <UploadFileRenderer
          id={id}
          settings={settings}
          setValue={setValue}
          register={register}
        />
      );
    case PLAIN_TEXT:
      return <PlainTextRenderer id={id} settings={settings} />;
    default:
      return "";
  }
};

function FormRenderer({ form: formBuilder, id }) {
  return (
    <Grid container>
      <></>
    </Grid>
  );
}

export default FormRenderer;
