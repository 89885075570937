import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Badge,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomTable from "../../../../../components/common/Table";
import { authedAxios, useAuthedAxios } from "../../../../../util/axios";
import { GET_ALL_FORMS } from "../forms.graphql";
import fileDownload from "js-file-download";
import moment from "moment";

function ViewAllForms() {
  const history = useHistory();
  const { state } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [
    selectedFormForSubmissionsRemoval,
    setSelectedFormForSubmissionsRemoval,
  ] = React.useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );

  const [
    showDeleteFormConfirmation,
    setShowDeleteFormConfirmation,
  ] = React.useState(false);
  const [selectedFormRemoval, setSelectedFormRemoval] = React.useState();

  const { loading, error, data, refetch } = useQuery(GET_ALL_FORMS, {
    pollInterval: 5000,
  });

  const [
    { data: updateData, loading: updating, error: updateError },
    updateStatus,
  ] = useAuthedAxios(
    {
      url: "/form-builders",
      method: "PUT",
    },
    {
      manual: true,
    }
  );

  const [
    { data: deletedData, loading: deleting, error: deleteError },
    deleteSubmissions,
  ] = useAuthedAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [
    {
      data: deletedFormData,
      loading: deletingFormLoading,
      error: deleteFormError,
    },
    deleteForm,
  ] = useAuthedAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (deletedData || deletedFormData) {
      enqueueSnackbar("Form data deleted successfully!", {
        preventDuplicate: true,
        variant: "success",
      });
      refetch();
    }
  }, [deletedData, deletedFormData, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (deleteError || deleteFormError) {
      enqueueSnackbar(
        "There was some internal server error while deleting the form. Please try again later!",
        {
          preventDuplicate: true,
          variant: "error",
        }
      );
    }
  }, [deleteError, deleteFormError, enqueueSnackbar]);

  const downloadSubmissions = React.useCallback(
    async (title, id) => {
      const key = enqueueSnackbar(`Generating csv file for form '${title}'!`, {
        preventDuplicate: true,
        variant: "info",
      });
      authedAxios({
        url: `/form-submissions/download/${id}`,
        method: "GET",
        responseType: "blob", // important
      })
        .then((res) => {
          fileDownload(res.data, title + ".csv");
          closeSnackbar(key);
          enqueueSnackbar(
            `Submissions for form '${title}' are downloaded successfully!`,
            {
              preventDuplicate: true,
              variant: "success",
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(
            "There was some internal server error while downloading the submissions. Please try again later!",
            {
              preventDuplicate: true,
              variant: "error",
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  useEffect(() => {
    if (state && state.update) {
      refetch();
    }
  }, [state, refetch]);

  useEffect(() => {
    if (updateData) {
      refetch();
      enqueueSnackbar("Status updated successfully!", {
        preventDuplicate: true,
        variant: "success",
      });
    }
  }, [updateData, enqueueSnackbar, refetch]);

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(
        "There was some internal server error while updating the status. Please try again later!",
        {
          preventDuplicate: true,
          variant: "error",
        }
      );
    }
  }, [updateError, enqueueSnackbar]);

  const onPublish = React.useCallback(
    (id) => {
      updateStatus({
        url: "/form-builders/" + id,
        data: {
          status: "Publish",
        },
      });
    },
    [updateStatus]
  );

  const onDraft = React.useCallback(
    (id) => {
      updateStatus({
        url: "/form-builders/" + id,
        data: {
          status: "Draft",
        },
      });
    },
    [updateStatus]
  );

  const onUpdate = React.useCallback(
    (id) => {
      history.push(`/dashboard/form-management/edit/${id}`);
    },
    [history]
  );

  const onDeleteSubmissions = (formID) => {
    setSelectedFormForSubmissionsRemoval(formID);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteSubmissionsCancel = () => {
    setSelectedFormForSubmissionsRemoval(null);
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDeletion = () => {
    handleDeleteSubmissionsCancel();
    deleteSubmissions({
      url: `/form-submissions/delete/${selectedFormForSubmissionsRemoval}`,
    });
  };

  const onDeleteForm = (formID) => {
    setSelectedFormRemoval(formID);
    setShowDeleteFormConfirmation(true);
  };

  const handleDeleteFormCancel = () => {
    setSelectedFormRemoval(null);
    setShowDeleteFormConfirmation(false);
  };

  const handleConfirmFormDeletion = () => {
    handleDeleteFormCancel();
    deleteSubmissions({
      url: `/form-submissions/delete/${selectedFormRemoval}`,
    });
    deleteForm({
      url: `/form-builders/${selectedFormRemoval}`,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>,
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Slug",
        accessor: "slug",
      },
      {
        Header: "Start Date",
        accessor: (record) => moment(record.start_date).format("DD-MM-YYYY"),
      },
      {
        Header: "End Date",
        accessor: (record) => moment(record.end_date).format("DD-MM-YYYY"),
      },
      {
        Header: "Total Submissions",
        accessor: (record) =>
          record.submissions.length === 0 ? (
            <Chip
              variant="default"
              label={record.submissions.length}
              color={"default"}
            />
          ) : (
            <IconButton
              aria-label="download"
              title="Download Submissions"
              onClick={() => downloadSubmissions(record.title, record.id)}
            >
              <Badge badgeContent={record.submissions.length} color="secondary">
                <CloudDownload />
              </Badge>
            </IconButton>
          ),
      },
      {
        Header: "Status",
        accessor: (record) => (
          <Chip
            label={record.status === "Draft" ? "Draft" : "Published"}
            color={record.status === "Draft" ? "default" : "secondary"}
            style={{ padding: record.status === "Draft" ? "0 8px" : "" }}
          />
        ),
      },

      {
        Header: "Action",
        accessor: (record) => (
          <div>
            <ButtonGroup
              color="secondary"
              aria-label="outlined primary button group"
              size="small"
            >
              {record.status === "Draft" ? (
                <Button onClick={() => onPublish(record.id)}>Publish</Button>
              ) : (
                <Button
                  style={{ padding: "0 16px" }}
                  onClick={() => onDraft(record.id)}
                >
                  Unpublish
                </Button>
              )}
              <Button onClick={() => onUpdate(record.id)}>Update</Button>
              <Button
                onClick={() =>
                  history.push(
                    `/dashboard/form-management/attendance/${record.id}`
                  )
                }
              >
                Attendance
              </Button>
              <Button
                onClick={() => onDeleteSubmissions(record.id)}
                disabled={record.submissions.length === 0}
              >
                Delete Submissions
              </Button>
              <Button onClick={() => onDeleteForm(record.id)}>Delete</Button>
            </ButtonGroup>
          </div>
        ),
      },
    ],
    [downloadSubmissions, history, onDraft, onPublish, onUpdate]
  );

  const openFormBuilder = () => {
    history.push("/dashboard/form-management/builder");
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Form Management
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        <Grid container spacing={3} style={{ marginBottom: "10px" }}>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              All Forms
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={openFormBuilder}
            >
              Build New Form
            </Button>
          </Grid>
        </Grid>
        {!updating && data && (
          <Grid container spacing={3}>
            <CustomTable
              columns={columns}
              data={data.formBuilders}
              showSelections={false}
              showPagination={false}
              hover
            />
          </Grid>
        )}
        {(loading || updating) && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!updating && !loading && error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was some internal server error while fetching records. Please
            try reloading the page.
          </Alert>
        )}
      </Paper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showDeleteConfirmation}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This action is irrevertible. Once deleted, submissions cannot be
          recovered.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleDeleteSubmissionsCancel}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showDeleteFormConfirmation}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          This action is irrevertible. Once deleted, Form and Form Submissions
          cannot be recovered.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteFormCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmFormDeletion} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={deleting || deletingFormLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default ViewAllForms;
