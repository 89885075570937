import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Paper,
  Typography,
  Breadcrumbs,
  Grid,
  makeStyles,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
  Modal,
  Divider,
  Chip,
  FormControl
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ImageUploader from "react-images-upload";
import moment from "moment";
import { Alert, AlertTitle } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import { Link, useHistory, useParams } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

import { BASE_URL, useAuthedAxios } from "../../../../../util/axios";
import { GET_LISTING } from "../rewards.graphql";
import { isUrlValid } from "../../../../../util/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  modal: {
    top: `5%`,
    margin: "auto",
    outline: "none",
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  }
}));

function EditListing() {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [formHeader, setFormHeader] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [hasFormHeaderError, setFormHeaderError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hasStartDateError, setStartDateError] = useState(false);
  const [hasEndDateError, setEndDateError] = useState(false);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isThumbnailModalVisible, setThumbnailModalVisibility] =
    useState(false);
  const [picture, setPicture] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [formTnC, setFormTnC] = useState();
  const [merchant, setMerchant] = useState();
  const [memberships, setMemberships] = useState({
    Lite: false,
    Corporate: false,
    Platinum: true,
    Associate: true
  });
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [voucherPrefix, setVoucherPrefix] = useState("");
  const [noOfVouchers, setNoOfVouchers] = useState(1);
  const [unlimitedUsage, setUnlimitedUsage] = useState(false);
  const [generatedVouchersList, setGeneratedVouchersList] = useState([]);
  const [voucherGeneration, setVoucherGeneration] = useState("RealTime");
  const [hasVoucherPrefixError, setHasVoucherPrefixError] = useState(false);
  const [hasNoOfVouchersError, setHasNoOfVouchersError] = useState(false);
  const [merchantError, setMerchantError] = useState(false);
  const [hasVoucherLinkError, setHasVoucherLinkError] = useState(false);
  const [link, setLink] = useState("");

  //   Loading Current Form
  const { loading, error, data, refetch } = useQuery(GET_LISTING, {
    variables: { id }
  });

  const [
    { loading: updating, data: updateData, error: updateError },
    updateForm
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true
    }
  );

  const [
    {
      data: merchantsData
      // loading: loadingMerchants,
      // error: errorFetchingMerchants
    },
    loadMerchants
  ] = useAuthedAxios(
    {
      url: `/merchants`
    },
    {
      manual: true
      // useCache: false,
    }
  );

  const [
    {
      data: membershipsData
      // loading: loadingMemberships,
      // error: errorFetchingMemberships
    },
    loadMemberships
  ] = useAuthedAxios(
    {
      url: `/memberships`
    },
    {
      manual: true
      // useCache: false,
    }
  );

  useEffect(() => {
    if (data) {
      const { listingBuilder } = data;
      if (!listingBuilder) {
        history.push("/dashboard/listing-management");
        return;
      }
      const {
        title,
        description,
        start_date,
        end_date,
        available_to,
        tnc,
        voucher_generation,
        voucher_prefix,
        number_of_vouchers,
        unlimited_vouchers,
        merchant,
        vouchers,
        merchant_link
      } = listingBuilder;
      const startDate = moment(start_date);
      const endDate = moment(end_date);
      setFormHeader(title);
      setFormDescription(description || "");
      setStartDate(startDate);
      setEndDate(endDate);
      setMemberships(available_to);
      setFormTnC(tnc);
      setVoucherGeneration(voucher_generation);
      setVoucherPrefix(voucher_prefix);
      setNoOfVouchers(number_of_vouchers);
      setUnlimitedUsage(unlimited_vouchers);
      setMerchant(merchant.id);
      setGeneratedVouchersList(vouchers);
      setLink(merchant_link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, history]);

  useEffect(() => {
    loadMerchants();
    loadMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(
        "There was some internal server error while saving the listing. Please try again later.",
        {
          variant: "error"
        }
      );
    }
  }, [updateError, enqueueSnackbar]);

  useEffect(() => {
    if (updateData) {
      enqueueSnackbar("Listing is updated successfully!", {
        variant: "success",
        preventDuplicate: true
      });
      refetch();
    }
  }, [updateData, enqueueSnackbar, refetch]);

  const handleClose = () => {
    setModalVisibility(false);
  };

  const handleThumbnailModalClose = () => {
    setThumbnailModalVisibility(false);
  };

  const onFormHeaderUpdate = (e) => {
    const value = e.target.value;
    setFormHeader(value);
    if (!value) {
      setFormHeaderError(true);
    } else {
      setFormHeaderError(false);
    }
  };

  const onFormDescriptionUpdate = (e) => {
    const value = e.target.value;
    setFormDescription(value);
  };

  const onVoucherPrefixUpdate = (e) => {
    const value = e.target.value;
    setVoucherPrefix(value);
    setGeneratedVouchersList([]);

    if (!value) {
      setHasVoucherPrefixError(true);
    } else {
      setHasVoucherPrefixError(false);
    }
  };

  const onNoOfVouchersUpdate = (e) => {
    const value = e.target.value;
    if (value < 1) return;
    setNoOfVouchers(value);
    setGeneratedVouchersList([]);

    if (!value) {
      setHasNoOfVouchersError(true);
    } else {
      setHasNoOfVouchersError(false);
    }
  };

  const onChangeStartDate = (date) => {
    if (date) {
      setStartDateError(false);
      setStartDate(date);
    } else {
      setStartDate(null);
      setStartDateError(true);
    }
  };

  const onChangeEndDate = (date) => {
    if (date) {
      setEndDateError(false);
      setEndDate(date);
    } else {
      setEndDate(null);
      setEndDateError(true);
    }
  };

  const onFormDiscountAmountUpdate = (e) => {
    const value = e.target.value;
    if (parseInt(value) < 0) return;
    setDiscountAmount(value);
  };

  const onFormLinkUpdate = (event) => {
    setLink(event.target.value);
    if (!event.target.value) {
      setHasVoucherLinkError(true);
    } else {
      setHasVoucherLinkError(false);
    }
  };

  const onFormDiscountTypeUpdate = (e) => {
    const value = e.target.value;
    setDiscountType(value);
  };

  const handleMerchantChange = (event) => {
    setMerchant(event.target.value);
    if (!event.target.value) {
      setMerchantError(true);
    } else {
      setMerchantError(false);
    }
  };

  const handleUnlimitedUsageChange = () => {
    setUnlimitedUsage(!unlimitedUsage);
  };

  const handleMembershipChange = (event) => {
    setMemberships({
      ...memberships,
      [event.target.name]: event.target.checked
    });
  };

  const onFormTnCUpdate = (e) => {
    const value = e.target.value;
    setFormTnC(value);
  };

  const onDrop = (pictureFiles) => {
    setPicture(pictureFiles);
  };

  const onThumbnailDrop = (pictureFiles, pictureDataURLs) => {
    setThumbnail(pictureFiles);
  };

  const onImageChange = () => {
    const [image] = picture;
    const formData = new FormData();

    formData.append("data", JSON.stringify(data.listingBuilder));
    formData.append("files.image", image, image.name);
    updateForm({
      url: `/listing-builders/${data.listingBuilder.id}`,
      data: formData
    });
  };

  const onThumbnailChange = () => {
    const [image] = thumbnail;
    const formData = new FormData();

    formData.append("data", JSON.stringify(data.listingBuilder));
    formData.append("files.thumbnail", image, image.name);
    updateForm({
      url: `/listing-builders/${data.listingBuilder.id}`,
      data: formData
    });
  };

  const getDate = (date) => {
    const day = moment(date).get("D");
    const month = moment(date).get("M");
    const year = moment(date).get("Y");

    const d = day < 10 ? `0${day}` : day;
    const m = month + 1 < 10 ? `0${month + 1}` : `${month + 1}`;
    return `${year}-${m}-${d}T00:00:00.000Z`;
  };

  const onSave = async (status) => {
    if (!formHeader) {
      setFormHeaderError(true);
      return;
    }
    if (!merchant) {
      setMerchantError(true);
      return;
    }
    if (!startDate) {
      setStartDateError(true);
      return;
    }
    if (!endDate) {
      setEndDateError(true);
      return;
    }
    if (
      voucherGeneration !== "PreGeneratedMerchant" &&
      voucherGeneration !== "Link"
    ) {
      if (!voucherPrefix) {
        setHasVoucherPrefixError(true);
        return;
      }
      if (!noOfVouchers) {
        setHasNoOfVouchersError(true);
        return;
      }
    }
    if (voucherGeneration === "Link") {
      if (!link) {
        setHasVoucherLinkError(true);
        return;
      }
      if (isUrlValid(link) === false)
        return enqueueSnackbar("Please enter valid URL", {
          variant: "error"
        });
    }
    const formData = {
      status,
      title: formHeader,
      description: formDescription,
      start_date: getDate(startDate),
      end_date: getDate(endDate),
      available_to: memberships,
      tnc: formTnC,
      discount_type: discountType,
      merchant_link: link
      // voucher_generation: voucherGeneration,
      // voucher_prefix: voucherPrefix,
      // number_of_vouchers: noOfVouchers,
      // unlimited_vouchers: unlimitedUsage,
      // merchant,
      // vouchers: vouchersResponse.data
    };
    try {
      updateForm({
        data: formData,
        url: `/listing-builders/${data.listingBuilder.id}`
      });
    } catch (e) {
      if (e) {
        enqueueSnackbar(
          "There was some internal server error while saving the listing. Please try again later.",
          {
            variant: "error",
            preventDuplicate: true
          }
        );
      }
    }
  };

  return (
    <>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Edit Listing
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/dashboard/listing-management">
          Listing Management
        </Link>
        <Typography color="textPrimary">Edit Listing</Typography>
      </Breadcrumbs>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        {!loading && !updating && data && (
          <>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs={6} sm={2}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontWeight: "bold" }}
                >
                  Listing Builder
                </Typography>
              </Grid>
              <Grid item xs={6} sm={10} style={{ textAlign: "right" }}>
                <Chip
                  label={
                    data.listingBuilder.status === "Draft"
                      ? "Draft"
                      : "Published"
                  }
                  color={
                    data.listingBuilder.status === "Draft"
                      ? "default"
                      : "secondary"
                  }
                  style={{
                    marginRight: "10px"
                  }}
                />
                <Button
                  variant="outlined"
                  color="default"
                  style={{ marginRight: "10px" }}
                  // disabled={selectedElements.length === 0}
                  onClick={() => onSave("Draft")}
                >
                  Save as Draft
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  // disabled={selectedElements.length === 0}
                  onClick={() => onSave("Publish")}
                >
                  Publish Listing
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "20px" }}>
              <Grid item xs={6}>
                <TextField
                  label="Listing Title"
                  placeholder="Please enter listing title"
                  style={{ width: "100%" }}
                  variant="filled"
                  value={formHeader}
                  onChange={onFormHeaderUpdate}
                  error={hasFormHeaderError}
                  helperText={
                    hasFormHeaderError ? "Listing Title is requied!" : ""
                  }
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Merchant
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={merchant || 0}
                    onChange={handleMerchantChange}
                    error={merchantError}
                    // helperText={merchantError ? "Merchant is requied!" : ""}
                  >
                    {merchantsData &&
                      merchantsData.map((merchant) => (
                        <MenuItem key={merchant.id} value={merchant.id}>
                          {merchant.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Listing Description"
                  placeholder="Please enter listing description (optional)"
                  variant="filled"
                  style={{ width: "100%" }}
                  value={formDescription}
                  onChange={onFormDescriptionUpdate}
                  multiline
                  rows={5}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Terms & Conditions"
                  placeholder="Please enter Terms & Conditions (optional)"
                  variant="filled"
                  style={{ width: "100%" }}
                  value={formTnC}
                  onChange={onFormTnCUpdate}
                  multiline
                  rows={5}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Available To</FormLabel>
                  <FormGroup>
                    {membershipsData &&
                      membershipsData
                        .sort((a, b) => a.annual_price - b.annual_price)
                        .map((membership) => (
                          <FormControlLabel
                            id={membership.id}
                            key={membership.id}
                            control={
                              <Checkbox
                                checked={memberships[membership.name]}
                                onChange={handleMembershipChange}
                                name={membership.name}
                              />
                            }
                            label={membership.name}
                          />
                        ))}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" color={"secondary"}>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      label="Start Date"
                      required
                      value={startDate}
                      onChange={(date) => onChangeStartDate(date)}
                      animateYearScrolling
                      style={{
                        marginRight: "10px",
                        height: "55px"
                      }}
                      InputLabelProps={{
                        style: {
                          paddingLeft: "10px",
                          paddingTop: startDate ? "7px" : "0px"
                        }
                      }}
                      InputProps={{
                        style: {
                          paddingLeft: "10px",
                          marginTop: "22px"
                        }
                      }}
                      className="date-filled-input"
                      format="DD/MM/yyyy"
                      DialogProps={{
                        clearable: true
                      }}
                      error={hasStartDateError}
                      helperText={
                        hasStartDateError ? "Please select the start date!" : ""
                      }
                    />
                    {startDate ? (
                      <DatePicker
                        required
                        label="End Date"
                        className="date-filled-input"
                        value={endDate}
                        onChange={(date) => onChangeEndDate(date)}
                        minDate={startDate}
                        minDateMessage={"The mininim date is not valid"}
                        format="DD/MM/yyyy"
                        style={{
                          height: "55px"
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: "10px",
                            paddingTop: endDate ? "7px" : "0px"
                          }
                        }}
                        InputProps={{
                          style: {
                            paddingLeft: "10px",
                            marginTop: "22px"
                          }
                        }}
                        animateYearScrolling
                        DialogProps={{
                          clearable: true
                        }}
                        error={hasEndDateError}
                        helperText={
                          hasEndDateError ? "Please select the end date!" : ""
                        }
                      />
                    ) : (
                      <DatePicker
                        required
                        className="date-filled-input"
                        label="End Date"
                        DialogProps={{
                          clearable: true
                        }}
                        value={endDate}
                        onChange={(date) => onChangeEndDate(date)}
                        format="DD/MM/yyyy"
                        animateYearScrolling
                        style={{
                          height: "55px"
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: "10px",
                            paddingTop: endDate ? "7px" : "0px"
                          }
                        }}
                        InputProps={{
                          style: {
                            paddingLeft: "10px",
                            marginTop: "22px"
                          }
                        }}
                        error={hasEndDateError}
                        helperText={
                          hasEndDateError ? "Please select the end date!" : ""
                        }
                      />
                    )}
                  </div>
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Discount Amount (%)"
                  placeholder="Please enter Discount Amount (%)"
                  type="number"
                  style={{ width: "100%" }}
                  variant="filled"
                  onChange={onFormDiscountAmountUpdate}
                  value={discountAmount}
                  // error={hasFormHeaderError}
                  // helperText={
                  //   hasFormHeaderError ? "Discount Amount is requied!" : ""
                  // }
                  // required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Discount Type"
                  placeholder="Please enter Discount Type"
                  style={{ width: "100%" }}
                  variant="filled"
                  onChange={onFormDiscountTypeUpdate}
                  // error={hasFormHeaderError}
                  // helperText={
                  //   hasFormHeaderError ? "Discount Type is requied!" : ""
                  // }
                  // required
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginottom: "20px" }}>
              <Grid item xs={6}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>Voucher Generation</Grid>
                  <Grid item>
                    <RadioGroup
                      disabled
                      value={voucherGeneration}
                      // onChange={(e) => {
                      //   setVoucherGeneration(e.target.value);
                      //   setGeneratedVouchersList([]);
                      // }}
                    >
                      <FormControlLabel
                        control={<Radio color="default" />}
                        label="Real-Time"
                        value="RealTime"
                      />
                      <FormControlLabel
                        control={<Radio color="default" />}
                        label="Pre-Generated by ASME"
                        value="PreGeneratedASME"
                      />
                      <FormControlLabel
                        control={<Radio color="default" />}
                        label="Pre-Generated by Merchant"
                        value="PreGeneratedMerchant"
                      />
                      <FormControlLabel
                        control={<Radio color="default" />}
                        label="Link"
                        value="Link"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid item>Thumbnail</Grid>
                {data.listingBuilder && data.listingBuilder.thumbnail ? (
                  <div
                    onClick={() => setThumbnailModalVisibility(true)}
                    style={{
                      height: "170px",
                      maxWidth: "100%",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={`${BASE_URL}${data.listingBuilder.thumbnail.url}`}
                      alt="Thumbnail"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%"
                      }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => setThumbnailModalVisibility(true)}
                    style={{
                      width: "150px",
                      height: "150px",
                      backgroundColor: "rgba(0, 0, 0, 0.15)"
                    }}
                  >
                    <Typography
                      variant="h6"
                      // color={"secondary"}
                      style={{ padding: "35px" }}
                    >
                      <b>Upload Thumbnail</b>
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item xs={3}>
                <Grid item>Image</Grid>
                {data.listingBuilder && data.listingBuilder.image ? (
                  <div
                    onClick={() => setModalVisibility(true)}
                    style={{
                      height: "170px",
                      maxWidth: "100%",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={`${BASE_URL}${data.listingBuilder.image.url}`}
                      alt="Reward"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%"
                      }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => setModalVisibility(true)}
                    style={{
                      width: "150px",
                      height: "150px",
                      backgroundColor: "rgba(0, 0, 0, 0.15)"
                    }}
                  >
                    <Typography
                      variant="h6"
                      // color={"secondary"}
                      style={{ padding: "35px" }}
                    >
                      <b>Upload Image</b>
                    </Typography>
                  </div>
                )}
              </Grid>
              {voucherGeneration !== "PreGeneratedMerchant" &&
                voucherGeneration !== "Link" && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        label="Voucher Prefix"
                        placeholder="Please enter Voucher Prefix"
                        style={{ width: "100%" }}
                        variant="filled"
                        value={voucherPrefix}
                        onChange={onVoucherPrefixUpdate}
                        disabled
                        error={hasVoucherPrefixError}
                        helperText={
                          hasVoucherPrefixError
                            ? "Voucher Prefix is requied!"
                            : ""
                        }
                        required
                      />
                    </Grid>
                    {(voucherGeneration === "PreGeneratedASME" ||
                      (voucherGeneration === "RealTime" &&
                        !unlimitedUsage)) && (
                      <Grid item xs={4}>
                        <TextField
                          label="No. of Vouchers"
                          placeholder="Please enter No. of Vouchers"
                          type="number"
                          style={{ width: "100%" }}
                          variant="filled"
                          value={noOfVouchers}
                          onChange={onNoOfVouchersUpdate}
                          disabled
                          error={hasNoOfVouchersError}
                          helperText={
                            hasNoOfVouchersError
                              ? "No. of Vouchers is requied!"
                              : ""
                          }
                          required
                        />
                      </Grid>
                    )}

                    {voucherGeneration === "RealTime" && (
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={unlimitedUsage}
                              onChange={handleUnlimitedUsageChange}
                              name="No Limit"
                            />
                          }
                          label="No Limit"
                        />
                      </Grid>
                    )}
                  </>
                )}

              {voucherGeneration === "Link" && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      label="Voucher Link"
                      placeholder="Please enter Voucher Link"
                      style={{ width: "100%" }}
                      variant="filled"
                      value={link}
                      onChange={onFormLinkUpdate}
                      error={hasVoucherLinkError}
                      helperText={
                        hasVoucherLinkError ? "Voucher Link is requied!" : ""
                      }
                      required
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {/* {voucherGeneration !== "PreGeneratedMerchant" ? (
              <Grid item xs>
                <div style={{ textAlign: "center" }}>
                  <Button
                    color="secondary"
                    disabled={generatedVouchersList.length > 0}
                    variant="outlined"
                    onClick={() => {
                      if (noOfVouchers) onGenerateVouchersList(noOfVouchers);
                      console.log("Generate List");
                    }}
                  >
                    Generate List
                  </Button>
                </div>
              </Grid>
            ) : (
              <Grid item xs>
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      console.log("Upload List");
                      // onImageChange();
                    }}
                  >
                    Upload List
                  </Button>
                </div>
              </Grid>
            )} */}
            {generatedVouchersList.length > 0 && (
              <Grid style={{ marginBottom: "20px", marginTop: "20px" }}>
                Generated Vouchers
                <ul>
                  {generatedVouchersList.map((voucher) => (
                    <li key={voucher.id}>{voucher.code}</li>
                  ))}
                </ul>
              </Grid>
            )}
          </>
        )}
        {!loading && error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was some internal server error while fetching records. Please
            try reloading the page.
          </Alert>
        )}
        {(loading || updating) && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Paper>
      <Modal
        open={isModalVisible}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          className={classes.modal}
          style={{
            position: "absolute",
            width: "50%",
            // height: "35%",
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            border: "none"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Image</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onDrop}
            buttonStyles={{ background: "#de1736", color: "#fff" }}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Image"
            singleImage
            withPreview
          />
          {picture && picture.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                  onImageChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={isThumbnailModalVisible}
        onClose={handleThumbnailModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div
          className={classes.modal}
          style={{
            position: "absolute",
            width: "50%",
            // height: "35%",
            // backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            // padding: theme.spacing(2, 4, 3),
            border: "none"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h6"
              color={"secondary"}
              style={{ marginBottom: "20px" }}
            >
              <b>Edit Thumbnail Image</b>
            </Typography>
            <div style={{ marginTop: "-8px" }}>
              <IconButton onClick={handleThumbnailModalClose}>
                <Close />
              </IconButton>
            </div>
          </div>
          <Divider />
          <ImageUploader
            withIcon={true}
            onChange={onThumbnailDrop}
            buttonStyles={{ background: "#de1736", color: "#fff" }}
            imgExtension={[".jpg", ".png"]}
            label="Supported Image Types: .jpg or .png"
            maxFileSize={1000000}
            buttonText="Choose Thumbnail"
            singleImage
            withPreview
          />
          {thumbnail && thumbnail.length > 0 && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleThumbnailModalClose();
                  onThumbnailChange();
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default EditListing;
