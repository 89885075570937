export const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver ? "1px dashed grey" : "none",
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  //   ...draggableStyle,

  ...{
    marginBottom: "4px",
  },

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),

  ...(!isDragging && {
    border: "1px dashed #e2173733",
  }),
});
