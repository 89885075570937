import React from "react";
import {
  Badge,
  Chip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
} from "@material-ui/core";
import { getItemStyle } from "../styles";
import {
  CheckBoxOutlined,
  DeleteRounded,
  EditRounded,
} from "@material-ui/icons";

function CheckboxFieldRenderer({ item, snapshot, provided, onEdit, onDelete }) {
  return (
    <>
      <ListSubheader disableSticky>
        <Chip color="secondary" size="small" label={item.label} />
      </ListSubheader>
      <div style={{ padding: "0 10px" }}>
        <ListItem
          ContainerComponent="li"
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <ListItemIcon>
            <CheckBoxOutlined />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                {item.settings && item.settings.required ? (
                  <Tooltip title="Required" placement="top-end">
                    <Badge color="secondary" variant="dot">
                      {item.settings && item.settings.title
                        ? item.settings.title
                        : item.label}
                    </Badge>
                  </Tooltip>
                ) : (
                  <>
                    {item.settings && item.settings.title
                      ? item.settings.title
                      : item.label}
                  </>
                )}
              </span>
            }
            secondary={
              item.settings && item.settings.placeholder
                ? item.settings.placeholder
                : ""
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => onEdit(item)}
            >
              <EditRounded fontSize="small" color="primary" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onDelete(item.id)}
            >
              <DeleteRounded fontSize="small" color="error" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    </>
  );
}

export default CheckboxFieldRenderer;
