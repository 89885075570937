import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Paper,
  Select,
  TextField,
  MenuItem,
  Typography,
  makeStyles,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import CustomTable from "../../../../components/common/Table";
import { useForm } from "react-hook-form";
import { SECONDARY_COLOR } from "../../../../app/constants";
import ExcelReader from "../../../../components/common/ExcelReader/ExcelReader";
import { Alert } from "@material-ui/lab";
import { Allowed_Columns } from "../../../../components/common/ExcelReader/validators";
import moment from "moment";
import { useAuthedAxios } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    width: "150px",
    height: "30px",
    marginTop: "17px",
    marginLeft: "5px",
  },
  goButton: {
    width: "40px",
    height: "30px",
    marginTop: "15px",
    marginLeft: "5px",
  },
  icons_right: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  main_filter_div: {
    display: "flex",
    marginBottom: "10px",
    justifyContent: "space-between",
    paddingRight: "10px",
    paddingLeft: "2px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function NonMembers() {
  const {
    handleSubmit: searchHandleSubmit,
    register: searchRegister,
    errors: searchError,
  } = useForm();

  const classes = useStyles();

  const [excelError, setExcelError] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [hasRequestError, setHasRequestError] = useState(false);
  const [hasRequestData, setHasRequestData] = useState(false);

  const [{ loading, data, error }, sendInvitations] = useAuthedAxios(
    "/organizations/invitations",
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (error) {
      setHasRequestError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setHasRequestData(true);
    }
  }, [data]);

  const columns = React.useMemo(
    () =>
      Allowed_Columns.map((c) => {
        if (c === "Year of Incorporation") {
          return {
            Header: c,
            accessor: (data) => {
              if (data[c] && data[c] instanceof Date)
                return moment(data[c]).format("DD MMMM YYYY");
              return "";
            },
          };
        }
        return {
          Header: c,
          accessor: (data) => data[c],
        };
      }),
    []
  );

  const onSubmitSearch = (data) => {
    // console.log(data);
  };

  const uploadExcel = (data) => {
    if (data.error) {
      setExcelError(data.error_message);
      setTableData([]);
    } else {
      setExcelError(null);
      setTableData(data.data);
    }
  };

  const onRowSelection = (selections) => {
    setSelectedRows(selections);
  };

  const onSendInvitation = () => {
    sendInvitations({
      data: {
        organizations: selectedRows,
      },
      method: "POST",
    });
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Member Management
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "40px", display: "grid" }}
      >
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Non Member
        </Typography>
        <br />
        <div
          className={classes.main_filter_div}
          style={{ justifyContent: "flex-end" }}
        >
          {/* <div
            style={{
              display: "flex",
            }}
          >
            <form onSubmit={searchHandleSubmit(onSubmitSearch)}>
              <TextField
                label="Search"
                inputRef={searchRegister({
                  required: "This field cannot be empty!",
                })}
                name="search_term"
                error={
                  searchError.search_term && searchError.search_term.message
                    ? true
                    : false
                }
                helperText={
                  searchError.search_term && searchError.search_term.message
                    ? searchError.search_term.message
                    : null
                }
              />
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.searchButton}
                type="submit"
              >
                Search
              </Button>
            </form>
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <FormControl
                style={{
                  width: "150px",
                  marginTop: "10px",
                }}
              >
                <Select
                  name="actions"
                  defaultValue="Bulk Action"
                  style={{
                    color: SECONDARY_COLOR,
                    marginTop: "2px",
                  }}
                  disableUnderline={true}
                >
                  <MenuItem
                    value={"Bulk Action"}
                    style={{
                      color: SECONDARY_COLOR,
                    }}
                  >
                    Bulk Action
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.goButton}
                type="submit"
              >
                Go
              </Button>
            </div>
          </div> */}
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <img
              src="/images/import.png"
              alt="Import Icon"
              className={classes.icons_right}
              style={
                {
                  // marginRight: "10px",
                }
              }
              onClick={() => {
                document.getElementById("importExcelFile").value = "";
                document.getElementById("importExcelFile").click();
              }}
            />
            {/* <img
              src="/images/export.png"
              alt="Export Icon"
              className={classes.icons_right}
            /> */}
            <ExcelReader onUploadExcel={uploadExcel} />
          </div>
        </div>

        {selectedRows && selectedRows.length > 0 && (
          <>
            <Alert
              severity="info"
              action={
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={onSendInvitation}
                >
                  Send Invitation
                </Button>
              }
            >
              Selected Rows: {selectedRows.length}
            </Alert>
            <br />
          </>
        )}

        {excelError && (
          <>
            <Alert
              severity="error"
              onClose={() => {
                setExcelError(null);
              }}
            >
              {excelError}
            </Alert>
            <br />
          </>
        )}

        <CustomTable
          columns={columns}
          data={tableData}
          onRowSelection={onRowSelection}
        />

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="secondary" />
        </Backdrop>

        <Snackbar
          open={hasRequestData}
          autoHideDuration={2000}
          onClose={() => setHasRequestData(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert onClose={() => setHasRequestData(false)} severity="success">
            Invitations Sent Successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={hasRequestError}
          autoHideDuration={2000}
          onClose={() => setHasRequestError(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert onClose={() => setHasRequestError(false)} severity="error">
            {error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : "There was some internal server error while sending invitations. Please try again!"}
          </Alert>
        </Snackbar>
      </Paper>
    </div>
  );
}

export default NonMembers;
