export const TEXT_FIELD = "TEXT_FIELD";
export const PARAGRAPH = "PARAGRAPH";
export const PLAIN_TEXT = "PLAIN_TEXT";
export const CHECKBOX = "CHECKBOX";
export const RADIO_BUTTONS = "RADIO_BUTTONS";
export const CHECKBOX_GROUP = "CHECKBOX_GROUP";
export const FILE_UPLOAD = "FILE_UPLOAD";

export const PRE_FILLED_MEMBER_ITEMS = [
  {
    label: "First Name",
    attribute: "first_name",
    ref: "members",
  },
  {
    label: "Last Name",
    attribute: "last_name",
    ref: "members",
  },
  {
    label: "Email",
    attribute: "email",
    ref: "members",
  },
  {
    label: "Mobile Number",
    attribute: "mobile_no",
    ref: "members",
  },
  {
    label: "Citizenship",
    attribute: "citizenship",
    ref: "members",
  },
  {
    label: "Salutation",
    attribute: "salutation",
    ref: "members",
  },
  {
    label: "Designation",
    attribute: "designation",
    ref: "members",
  },
];

export const PRE_FILLED_ORGANIZATION_ITEMS = [
  {
    label: "Organization Name",
    attribute: "name",
    ref: "organizations",
  },
  {
    label: "Company Registration No.",
    attribute: "company_registration_no",
    ref: "organizations",
  },
  {
    label: "Address",
    attribute: "address",
    ref: "organizations",
  },
  {
    label: "Phone",
    attribute: "phone",
    ref: "organizations",
  },
  {
    label: "Staff Strength",
    attribute: "staff_strength",
    ref: "organizations",
  },
  {
    label: "Annual Turnover",
    attribute: "annual_turnover",
    ref: "organizations",
  },
  {
    label: "Paid Up Capital",
    attribute: "paid_up_capital",
    ref: "organizations",
  },
  {
    label: "Website",
    attribute: "website",
    ref: "organizations",
  },
  {
    label: "Facebook",
    attribute: "facebook",
    ref: "organizations",
  },
  {
    label: "Legal Structure",
    attribute: "legal_structure",
    ref: "organizations",
  },
  {
    label: "Ownership Type",
    attribute: "ownership_type",
    ref: "organizations",
  },
  {
    label: "Nature of Business",
    attribute: "nature_of_business",
    ref: "organizations",
  },
  {
    label: "Principal Business Activity",
    attribute: "principal_business_activity",
    ref: "organizations",
  },
  {
    label: "Secondary Business Activity",
    attribute: "secondary_business_activity",
    ref: "organizations",
  },
  {
    label: "Company Description",
    attribute: "company_description",
    ref: "organizations",
  },
  {
    label: "Year of Incorporation",
    attribute: "year_of_incorporation",
    ref: "organizations",
  },
  {
    label: "Fax",
    attribute: "fax",
    ref: "organizations",
  },
];

export const PRE_FILLED_ITEMS = [
  ...PRE_FILLED_MEMBER_ITEMS,
  ...PRE_FILLED_ORGANIZATION_ITEMS,
];
