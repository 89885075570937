import React from "react";
import {
  Chip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { getItemStyle } from "../styles";
import {
  DeleteRounded,
  EditRounded,
  TextFieldsOutlined,
} from "@material-ui/icons";

function PlainTextRenderer({ item, snapshot, provided, onEdit, onDelete }) {
  return (
    <>
      <ListSubheader disableSticky>
        <Chip color="secondary" size="small" label={item.label} />
      </ListSubheader>
      <div style={{ padding: "0 10px" }}>
        <ListItem
          ContainerComponent="li"
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <ListItemIcon>
            <TextFieldsOutlined />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                {item.settings && item.settings.variant
                  ? item.settings.variant
                  : ""}
              </span>
            }
            secondary={
              item.settings && item.settings.text ? item.settings.text : ""
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => onEdit(item)}
            >
              <EditRounded fontSize="small" color="primary" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onDelete(item.id)}
            >
              <DeleteRounded fontSize="small" color="error" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    </>
  );
}

export default PlainTextRenderer;
