import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
  makeStyles,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
  Grid,
  Modal
} from "@material-ui/core";
import CustomTable from "../../../../components/common/Table";
import { Controller, useForm } from "react-hook-form";
import IHLData from "../../../../app/IHL.json";
import { DatePicker } from "@material-ui/pickers";
import { authedAxios, BASE_URL, useAuthedAxios } from "../../../../util/axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import moment from "moment";
import qs from "qs";
import { CloseRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { renderMembershipDate } from "../../../../util/utils";
import { groupedIndustryData } from "../../../../app/constants";

const PER_PAGE_LIMIT = 10;

const FileDownload = require("js-file-download");

const groupedIndustryNames = Object.keys(IHLData.industry);

const useStyles = makeStyles((theme) => ({
  searchButton: {
    width: "150px",
    height: "30px",
    marginTop: "17px",
    marginLeft: "5px"
  },
  pendingButton: {
    width: "280px",
    height: "30px",
    marginTop: "17px",
    marginLeft: "5px"
  },
  goButton: {
    width: "40px",
    height: "30px",
    marginTop: "15px",
    marginLeft: "5px"
  },
  icons_right: {
    width: "20px",
    height: "20px",
    cursor: "pointer"
  },
  main_filter_div: {
    display: "flex",
    marginBottom: "10px",
    justifyContent: "space-between",
    paddingRight: "10px",
    paddingLeft: "2px"
  },
  filter_checkbox: {
    fontSize: "15px",
    margin: 0,
    padding: 0
  },
  filter_checkbox_label: {
    fontSize: "15px",
    marginLeft: "2px"
  },
  filter_table: {
    borderCollapse: "collapse",
    wordWrap: "break-word",
    tableLayout: "fixed"
  },
  modalPaper: {
    position: "absolute",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "1.15rem",
    // width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function getModalStyle() {
  const top = 25;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function PendingAccountCreation() {
  const {
    handleSubmit: searchHandleSubmit,
    register: searchRegister,
    errors: searchError,
    reset: searchFormReset,
    watch
  } = useForm();

  const searchTerm = watch("search_term");
  const { handleSubmit: filterHandleSubmit, control, reset } = useForm();
  const [modalStyle] = React.useState(getModalStyle);

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [finalQuery, setFinalQuery] = useState("/members/all");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [createFromDate, setCreateFromDate] = useState(null);
  const [createToDate, setCreateToDate] = useState(null);
  const [expiryFromDate, setExpiryFromDate] = useState(null);
  const [expiryToDate, setExpiryToDate] = useState(null);
  const [isModalVisible, setModalVisibility] = useState(false);
  const [sendingMails, setSendingMails] = useState(false);
  const [filteredOrganizations, setFilteredOrganizations] = useState(false);
  const [industryFilterSearchText, setIndustryFilterSearchText] = useState("");

  const [data, setData] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Organisation Name",
        accessor: "name"
      },
      {
        Header: "UEN",
        accessor: "company_registration_no"
      },
      {
        Header: "Phone No.",
        accessor: "phone"
      },
      {
        Header: "Email",
        accessor: (record) => (
          <span>
            {record.members && record.members.length > 0
              ? record.members.map((m) => (
                  <React.Fragment key={m.id}>
                    <span>
                      <span style={{ fontWeight: 500 }}>
                        ({m.first_name} {m.last_name})
                      </span>{" "}
                      {m.email}
                    </span>
                    <br />
                  </React.Fragment>
                ))
              : // .join(", ")
                "-"}
          </span>
        )
      },
      {
        Header: "Industry",
        accessor: (record) =>
          record.industry_classification &&
          record.industry_classification.length > 0
            ? record.industry_classification[0].title
            : "-"
      },
      {
        Header: "Address",
        accessor: "address"
      },
      {
        Header: "Membership",
        accessor: (record) =>
          record.membership_type ? record.membership_type : "Lite"
      },
      {
        Header: "Registration Date",
        accessor: (record) => renderMembershipDate(record, "registration")
      },
      {
        Header: "Expiry Date",
        accessor: (record) => renderMembershipDate(record, "expiry")
      },
      {
        Header: "Status",
        accessor: (record) =>
          record.membership_status === "Pending"
            ? "Pending Upgrade"
            : record.membership_status
            ? record.membership_status
            : "Active"
      }
    ],
    []
  );

  const onSubmitSearch = ({ search_term }) => {
    setCurrentPage(0);

    const q = `/members/all?_isimported=true&_limit=${PER_PAGE_LIMIT}&_offset=${
      0 * PER_PAGE_LIMIT
    }&search=${search_term}`;
    loadMembers(q);
    setFinalQuery(q);
  };

  const onSubmitFilter = (formData) => {
    setCurrentPage(0);

    const data = {
      industry: [],
      search: searchTerm ? searchTerm : undefined,
      created_date_from: createFromDate
        ? moment(createFromDate).toISOString()
        : undefined,
      created_date_to: createToDate
        ? moment(createToDate).toISOString()
        : undefined,
      expiry_date_from: expiryFromDate
        ? moment(expiryFromDate).toISOString()
        : undefined,
      expiry_date_to: expiryToDate
        ? moment(expiryToDate).toISOString()
        : undefined,
      membership_status: []
    };

    Object.keys(formData).forEach((key) => {
      const [type, val] = key.split("___");
      if (type === "i" && formData[key]) {
        data.industry.push(groupedIndustryNames[val]);
      } else if (type === "s" && formData[key]) {
        data.membership_status.push(val);
      }
    });

    if (!data.industry.length) {
      delete data.industry;
    }
    if (!data.membership_status.length) {
      delete data.membership_status;
    }

    const query = qs.stringify(data);

    const q =
      `/members/all?_isimported=true&_limit=${PER_PAGE_LIMIT}&_offset=${
        0 * PER_PAGE_LIMIT
      }&` + query;

    loadMembers(q);
    setFinalQuery(q);
  };

  const [{ loading, data: organizations, error }, loadMembers] = useAuthedAxios(
    `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=0&_isimported=true`,
    {
      manual: true
    }
  );

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const onFilterReset = () => {
    setCurrentPage(0);
    setIndustryFilterSearchText("");

    reset({
      i___0: false,
      i___1: false,
      i___2: false,
      i___3: false,
      i___4: false,
      i___5: false,
      i___6: false,
      i___7: false,
      i___8: false,
      i___9: false,
      i___10: false,
      i___11: false,
      m___1: false,
      m___2: false,
      m___3: false,
      m___4: false,
      s___Active: false,
      s___Expired: false,
      s___Pending: false,
      s___Inactive: false
    });

    setCreateFromDate(null);
    setCreateToDate(null);
    setExpiryFromDate(null);
    setExpiryToDate(null);
    if (searchTerm) {
      const q =
        `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=${
          0 * PER_PAGE_LIMIT
        }&_isimported=true&search=` + searchTerm;
      loadMembers(q);
      setFinalQuery(q);
    } else {
      const q = `/members/all?_limit=${PER_PAGE_LIMIT}&_offset=${
        0 * PER_PAGE_LIMIT
      }&_isimported=true`;
      loadMembers(q);
      setFinalQuery(q);
    }
  };

  const [{ loading: exporting, data: exportData }, requestExport] =
    useAuthedAxios(
      {
        method: "POST",
        url: "/members/export",
        responseType: "blob"
      },
      {
        manual: true
      }
    );

  useEffect(() => {
    if (exportData) {
      FileDownload(exportData, "members.csv");
    }
  }, [exportData]);

  // const onExport = () => {
  //   const q = finalQuery
  //     .replace("/members/all?_isimported=true&", "")
  //     .replace("/members/all", "");
  //   const parsedData = qs.parse(q);
  //   delete parsedData._limit;
  //   delete parsedData._offset;
  //   requestExport({
  //     data: parsedData
  //   });
  // };

  const onResendMail = async () => {
    handleCloseModal();

    if (!organizations || !data.length)
      return enqueueSnackbar("No pending members", {
        variant: "error"
      });

    setSendingMails(true);

    try {
      const res = await authedAxios.post(`${BASE_URL}/members/resendMail`, {
        organizations: data
      });
      setSendingMails(false);
      if (res.data) {
        enqueueSnackbar("Successfull resent mails to pending members", {
          variant: "success"
        });
      }
    } catch (error) {
      setSendingMails(false);
      enqueueSnackbar("Failed to resend mails to pending members", {
        variant: "error"
      });
    }
  };

  useEffect(() => {
    if (organizations) {
      const noneMemberCreatedAccountOrganizations = [];
      organizations.organizations.map((org) => {
        const isAnyRepresentativeCreated = org.members.find(
          (member) => member.user
        );
        if (!isAnyRepresentativeCreated)
          noneMemberCreatedAccountOrganizations.push(org);
      });
      console.log(noneMemberCreatedAccountOrganizations);
      setData(organizations.organizations);
      if (!totalCount) {
        setTotalCount(organizations.totalCount);
      }
    }
  }, [organizations, totalCount]);

  // const organizationsWithSomePendingAccounts = () => {
  //   if (organizations) {
  //     const noneMemberCreatedAccountOrganizations = [];

  //     organizations.organizations.map((org) => {
  //       const isAnyRepresentativeCreated = org.members.find(
  //         (member) => member.user
  //       );
  //       if (!isAnyRepresentativeCreated)
  //         noneMemberCreatedAccountOrganizations.push(org);
  //     });
  //     setFilteredOrganizations(true);
  //     setData(noneMemberCreatedAccountOrganizations);
  //   }
  // };

  const organizationsWithSomePendingAccounts = () => {
    if (organizations) {
      const someMemberCreatedAccountOrganizations = [];

      organizations.organizations.map((org) => {
        const isAnyRepresentativeCreated = org.members.find(
          (member) => !member.user
        );
        if (isAnyRepresentativeCreated)
          someMemberCreatedAccountOrganizations.push(org);
      });
      setFilteredOrganizations(true);
      setData(someMemberCreatedAccountOrganizations);
    }
  };

  const onRowSelect = (organization) => {
    history.push(`/dashboard/members/${organization.id}`);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    const q = finalQuery
      .replace("/members/all?", "")
      .replace("/members/all", "");
    const parsedData = qs.parse(q);
    loadMembers({
      url: `/members/all`,
      params: {
        ...parsedData,
        _offset: page * PER_PAGE_LIMIT
      }
    });
  };

  const handleCloseModal = () => {
    setModalVisibility(false);
  };

  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Pending Account Creation
      </Typography>
      <Modal
        open={isModalVisible}
        // onClose={handleCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        disableBackdropClick
      >
        <div className={classes.modalPaper} style={modalStyle}>
          {
            <>
              {/* <div style={{ float: "right" }}>
                <IconButton onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </div> */}

              <p>
                Are you sure you want to resend the emails to these members ?
              </p>

              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around"
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={onResendMail}
                  className={classes.searchButton}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={handleCloseModal}
                  className={classes.searchButton}
                >
                  No
                </Button>
              </div>
            </>
          }
        </div>
      </Modal>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "40px", display: "grid" }}
      >
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Imported Members
          {organizations && (
            <span>
              {" "}
              (
              {organizations.organizations
                ? organizations.organizations.length
                : "0"}
              )
            </span>
          )}
        </Typography>
        <br />
        <div className={classes.main_filter_div}>
          <div
            style={{
              display: "flex"
            }}
          >
            <form onSubmit={searchHandleSubmit(onSubmitSearch)}>
              <TextField
                label="Search"
                inputRef={searchRegister({
                  required: "This field cannot be empty!"
                })}
                name="search_term"
                error={
                  searchError.search_term && searchError.search_term.message
                    ? true
                    : false
                }
                helperText={
                  searchError.search_term && searchError.search_term.message
                    ? searchError.search_term.message
                    : null
                }
                InputProps={{
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          searchFormReset();
                          loadMembers();
                        }}
                      >
                        <CloseRounded />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.searchButton}
                type="submit"
              >
                Search
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleOpenModal}
                className={classes.searchButton}
              >
                Resend Email
              </Button>
              {!filteredOrganizations ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={organizationsWithSomePendingAccounts}
                  className={classes.pendingButton}
                >
                  View Pending Imported Members
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setFilteredOrganizations(false);
                    setData(organizations.organizations);
                  }}
                  className={classes.pendingButton}
                >
                  View All Imported Members
                </Button>
              )}
            </form>
            {/* <div
              style={{
                marginLeft: "20px"
              }}
            >
              <FormControl
                style={{
                  width: "150px",
                  marginTop: "10px"
                }}
              >
                <Select
                  name="actions"
                  defaultValue="Bulk Action"
                  style={{
                    color: SECONDARY_COLOR,
                    marginTop: "2px"
                  }}
                  disableUnderline={true}
                >
                  <MenuItem
                    value={"Bulk Action"}
                    style={{
                      color: SECONDARY_COLOR
                    }}
                  >
                    Bulk Action
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.goButton}
                type="submit"
              >
                Go
              </Button>
            </div> */}
          </div>
          {/* <div
            style={{
              marginTop: "15px"
            }}
          >
            <img
              src="/images/filter.png"
              alt="Filter Icon"
              className={classes.icons_right}
              style={{
                marginRight: "10px"
              }}
              onClick={() => setShowFilter(!showFilter)}
            />
            <img
              src="/images/export.png"
              alt="Export Icon"
              className={classes.icons_right}
              onClick={() => {
                // document.getElementById("allmemberexport").click();
                onExport();
              }}
            />
          </div> */}
        </div>

        {showFilter && (
          <>
            <form onSubmit={filterHandleSubmit(onSubmitFilter)}>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={7}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Industry</b>
                  </Typography>
                  <div
                    style={{
                      height: "120px",
                      overflow: "auto"
                    }}
                    className="custom-scroll"
                  >
                    <TextField
                      value={industryFilterSearchText}
                      onChange={(e) =>
                        setIndustryFilterSearchText(e.target.value)
                      }
                      placeholder="Search for industry"
                    />
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "10px" }}
                    >
                      <FormGroup aria-label="position">
                        {[...new Set(groupedIndustryData)].map((group) => (
                          <>
                            <div
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                margin: "10px 0"
                              }}
                            >
                              {group?.label} 👇
                            </div>
                            {group?.options
                              ?.filter((item) =>
                                item?.label
                                  ?.toLowerCase()
                                  ?.includes(
                                    industryFilterSearchText?.toLowerCase()
                                  )
                              )
                              ?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  value="end"
                                  control={
                                    <div>
                                      <Controller
                                        name={`i___${item?.value}`}
                                        control={control}
                                        defaultValue={false}
                                        render={(props) => (
                                          <Checkbox
                                            color="secondary"
                                            size="small"
                                            onChange={(e) =>
                                              props.onChange(e.target.checked)
                                            }
                                            checked={props.value}
                                            style={{
                                              fontSize: "15px",
                                              margin: 0,
                                              padding: 0
                                            }}
                                          />
                                        )} // props contains: onChange, onBlur and value
                                      />
                                    </div>
                                  }
                                  label={
                                    <span
                                      className={classes.filter_checkbox_label}
                                    >
                                      {item?.label?.replace("Sector", "")}
                                    </span>
                                  }
                                  labelPlacement="end"
                                />
                              ))}
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={5}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Membership</b>
                  </Typography>
                  <div>
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "10px" }}
                    >
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___1"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Lite
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___2"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Corporate
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___3"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Corporate Platinum
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="m___4"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Associate
                            </span>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={4}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Status</b>
                  </Typography>
                  <div>
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "10px" }}
                    >
                      <FormGroup aria-label="position">
                        <FormControlLabel
                          // style={{ margin: 0, padding: 0 }}
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Active"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Active
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Inactive"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Inactive
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Expired"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Expired
                            </span>
                          }
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <div>
                              <Controller
                                name="s___Pending"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <Checkbox
                                    color="secondary"
                                    size="small"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                    className={classes.filter_checkbox}
                                  />
                                )} // props contains: onChange, onBlur and value
                              />
                            </div>
                          }
                          label={
                            <span className={classes.filter_checkbox_label}>
                              Pending Upgrade
                            </span>
                          }
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={8}>
                  <Typography variant="subtitle1" color={"secondary"}>
                    <b>Created Date</b>
                    <div style={{ display: "flex" }}>
                      <DatePicker
                        label="From Date"
                        value={createFromDate}
                        onChange={setCreateFromDate}
                        animateYearScrolling
                        style={{
                          marginRight: "10px"
                        }}
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                      />
                      {createFromDate ? (
                        <DatePicker
                          label="To Date"
                          value={createToDate}
                          onChange={setCreateToDate}
                          minDate={createFromDate}
                          minDateMessage={"The mininim date is not valid"}
                          format="DD/MM/yyyy"
                          animateYearScrolling
                          DialogProps={{
                            clearable: true
                          }}
                        />
                      ) : (
                        <DatePicker
                          label="To Date"
                          value={createToDate}
                          onChange={setCreateToDate}
                          format="DD/MM/yyyy"
                          animateYearScrolling
                        />
                      )}
                    </div>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color={"secondary"}
                    style={{ marginTop: "10px" }}
                  >
                    <b>Expiry Date</b>
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      label="From Date"
                      value={expiryFromDate}
                      onChange={setExpiryFromDate}
                      animateYearScrolling
                      style={{
                        marginRight: "10px"
                      }}
                      format="DD/MM/yyyy"
                      DialogProps={{
                        clearable: true
                      }}
                    />
                    {expiryFromDate ? (
                      <DatePicker
                        label="To Date"
                        value={expiryToDate}
                        onChange={setExpiryToDate}
                        minDate={expiryFromDate}
                        minDateMessage={"The mininim date is not valid"}
                        animateYearScrolling
                        format="DD/MM/yyyy"
                        DialogProps={{
                          clearable: true
                        }}
                      />
                    ) : (
                      <DatePicker
                        label="To Date"
                        value={expiryToDate}
                        onChange={setExpiryToDate}
                        animateYearScrolling
                        format="DD/MM/yyyy"
                      />
                    )}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="default"
                      className={classes.searchButton}
                      onClick={onFilterReset}
                    >
                      Clear Filter
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.searchButton}
                      type="submit"
                    >
                      Filter
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
            <br />
          </>
        )}

        {!error && organizations && (
          <>
            <CustomTable
              columns={columns}
              data={data}
              showSelections={false}
              onRowSelect={onRowSelect}
              onPageChange={onPageChange}
              currentPage={currentPage}
              pageSize={PER_PAGE_LIMIT}
              totalRecordsCount={organizations.pagination.rowCount}
              totalPagesCount={organizations.pagination.pageCount}
              hover
            />
          </>
        )}

        {error && (
          <>
            <Alert severity="error">
              <AlertTitle>Internal Server Error</AlertTitle>
              There was some internal server error while fetching data from
              server. Please try reloading the page!
            </Alert>
          </>
        )}
      </Paper>
      <Backdrop
        className={classes.backdrop}
        open={loading || exporting || sendingMails}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default PendingAccountCreation;
