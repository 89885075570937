import React from "react";

import { v4 as uuid } from "uuid";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getRenderItem } from "./builder";
import { ListItem, List, ListItemText, ListItemIcon } from "@material-ui/core";
import {
  AttachFileOutlined,
  CheckBoxOutlined,
  CheckBoxTwoTone,
  FormatTextdirectionLToR,
  RadioButtonCheckedOutlined,
  TextFields,
  TextFormatOutlined,
} from "@material-ui/icons";
import {
  CHECKBOX,
  FILE_UPLOAD,
  PARAGRAPH,
  RADIO_BUTTONS,
  TEXT_FIELD,
  PLAIN_TEXT,
  CHECKBOX_GROUP,
} from "./constants";

export const TOOLBOX_ELEMENTS = [
  {
    id: uuid(),
    isNotPollBuilderItem: false,
    label: "Text Field",
    icon: <TextFormatOutlined />,
    type: TEXT_FIELD,
    settings: {
      title: "",
      placeholder: "",
    },
  },
  {
    id: uuid(),
    isNotPollBuilderItem: false,
    label: "Paragraph",
    icon: <FormatTextdirectionLToR />,
    type: PARAGRAPH,
    settings: {
      title: "",
      placeholder: "",
    },
  },
  {
    id: uuid(),
    isNotPollBuilderItem: false,
    label: "Plain Text",
    icon: <TextFields />,
    type: PLAIN_TEXT,
    settings: {
      text: "",
      variant: "body1",
    },
  },
  {
    id: uuid(),
    isNotPollBuilderItem: true,
    label: "Checkbox",
    icon: <CheckBoxOutlined />,
    type: CHECKBOX,
    settings: {
      title: "",
    },
  },
  {
    id: uuid(),
    isNotPollBuilderItem: false,
    label: "Checkbox Group",
    icon: <CheckBoxTwoTone />,
    type: CHECKBOX_GROUP,
    settings: {
      title: "",
      options: [
        {
          id: uuid(),
          value: "Option 1",
        },
      ],
      min: 0,
      max: 1,
    },
  },
  {
    id: uuid(),
    isNotPollBuilderItem: false,
    label: "Radio Buttons",
    icon: <RadioButtonCheckedOutlined />,
    type: RADIO_BUTTONS,
    settings: {
      title: "",
      options: [
        {
          id: uuid(),
          value: "Option 1",
        },
        {
          id: uuid(),
          value: "Option 2",
        },
      ],
    },
  },
  {
    id: uuid(),
    isNotPollBuilderItem: false,
    label: "File Upload",
    icon: <AttachFileOutlined />,
    type: FILE_UPLOAD,
    settings: {
      title: "",
    },
  },
];

export function Copyable(props) {
  return (
    <Droppable
      renderClone={getRenderItem(TOOLBOX_ELEMENTS, props.className)}
      droppableId={props.droppableId}
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef}>
          <List className={props.className}>
            {TOOLBOX_ELEMENTS.map((item, index) => {
              if (props.isPollBuilder && !item.isNotPollBuilderItem)
                return null;
              const shouldRenderClone =
                item.id === snapshot.draggingFromThisWith;
              return (
                <React.Fragment key={item.id}>
                  {shouldRenderClone ? (
                    <ListItem
                      className="react-beautiful-dnd-copy"
                      style={{
                        background: "#de1736",
                        color: "white",
                        marginBottom: "10px",
                      }}
                    >
                      <ListItemIcon style={{ color: "white" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  ) : (
                    <Draggable draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              background: "#de1736",
                              color: "white",
                              marginBottom: "10px",
                            }}
                          >
                            <ListItemIcon style={{ color: "white" }}>
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                          </ListItem>
                        </React.Fragment>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            {provided.placeholder}
          </List>
        </div>
      )}
    </Droppable>
  );
}

export function Toolbox(props) {
  return <Copyable isPollBuilder={props.isPollBuilder} droppableId="TOOLBOX" />;
}
