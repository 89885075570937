import IHLData from "./IHL.json";

export const INACTIVE = "INACTIVE";
export const ACTIVE = "ACTIVE";
export const ERROR = "ERROR";

export const MOBILE_BREAKPOINT = 540;
export const SECONDARY_COLOR = "#de1736";
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const groupedIndustryData = Object.keys(IHLData.industry).map(
  (group) => ({
    label: group,
    options: IHLData.industry[group].map((industry) => ({
      value: group + " | " + industry,
      label: industry
    }))
  })
);

export const allIndustryNames = groupedIndustryData
  .map((industry) => [...industry.options])
  .flat();
