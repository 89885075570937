import React from "react";
import { Button } from "@material-ui/core";

import { renderMembershipDate } from "../../../../../../util/utils";
import "./style.css";

function MemberInfo({ classes, organization, approveMembership }) {
  return (
    <>
      <table
        style={{
          borderCollapse: "collapse",
          wordWrap: "break-word",
          tableLayout: "fixed"
        }}
        cellPadding="10"
      >
        <tbody>
          <tr>
            <td
              className={classes.infoCellStyle}
              style={{
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px"
              }}
            >
              Type
            </td>
            <td className={classes.infoCellLeftMargin}>
              {organization.membership_type
                ? organization.membership_type
                : "Lite"}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Registration Date</td>
            <td className={classes.infoCellLeftMargin}>
              {renderMembershipDate(organization, "registration")}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Expiry Date</td>
            <td className={classes.infoCellLeftMargin}>
              {renderMembershipDate(organization, "expiry")}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Renewal Date</td>
            <td className={classes.infoCellLeftMargin}>
              {renderMembershipDate(organization, "renewal")}
            </td>
          </tr>
          <tr>
            <td className={classes.infoCellStyle}>Status</td>
            <td className={classes.infoCellLeftMargin}>
              {organization.membership_status === "Pending"
                ? "Pending Upgrade"
                : organization.membership_status}
              {!organization.membership_status && "Active"}
            </td>
          </tr>
          <tr>
            <td
              className={classes.infoCellStyle}
              style={{
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px"
              }}
            >
              Action
            </td>
            <td className={classes.infoCellLeftMargin}>
              {organization.membership_status === "Pending" ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.searchButton}
                  onClick={approveMembership}
                  type="submit"
                >
                  To Approve
                </Button>
              ) : organization.membership_status === "Renewal" ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.searchButton}
                  onClick={approveMembership}
                  type="submit"
                >
                  To Approve
                </Button>
              ) : organization.membership_status === "ActiveRenewal" ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.searchButton}
                  onClick={approveMembership}
                  type="submit"
                >
                  To Approve
                </Button>
              ) : organization.membership_status === "Active" ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.searchButton}
                  type="submit"
                >
                  Approved
                </Button>
              ) : (
                "NA"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {/*       
      <Typography
        variant="subtitle1"
        color={"secondary"}
        style={{ marginTop: "10px" }}
      >
        Recent Activity
      </Typography>
      <div
        id="style-2"
        className="custom-scroll"
        style={{ height: "200px", overflow: "auto" }}
      >
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
      </div>
      <Typography
        variant="subtitle1"
        color={"secondary"}
        style={{ marginTop: "10px" }}
      >
        Internal Note
      </Typography>
      <div id="style-2" style={{ height: "200px", overflow: "auto" }}>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
        <p style={{ marginBottom: "0px" }}>03 Sep 2020 2:35pm</p>
        <p style={{ marginTop: "0px" }}>Submitted XXX Form</p>
      </div>
    */}
    </>
  );
}

export default MemberInfo;
