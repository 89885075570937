import React from "react";
import {
  CHECKBOX,
  FILE_UPLOAD,
  PARAGRAPH,
  PLAIN_TEXT,
  RADIO_BUTTONS,
  TEXT_FIELD,
  CHECKBOX_GROUP,
} from "./constants";
import CheckboxFieldEditor from "./FieldEditor/CheckboxFieldEditor";
import CheckboxGroupEditor from "./FieldEditor/CheckboxGroupEditor";
import FileInputFieldEditor from "./FieldEditor/FileInputEditor";
import ParagraphFieldEditor from "./FieldEditor/ParagraphFieldEditor";
import PlainTextEditor from "./FieldEditor/PlainTextEditor";
import RadioButtonsEditor from "./FieldEditor/RadioButtonsEditor";
import TextFieldEditor from "./FieldEditor/TextFieldEditor";

function FieldEditor({ item }) {
  if (!item) return "";
  switch (item.type) {
    case TEXT_FIELD:
      return <TextFieldEditor item={item} />;
    case PARAGRAPH:
      return <ParagraphFieldEditor item={item} />;
    case CHECKBOX:
      return <CheckboxFieldEditor item={item} />;
    case CHECKBOX_GROUP:
      return <CheckboxGroupEditor item={item} />;
    case RADIO_BUTTONS:
      return <RadioButtonsEditor item={item} />;
    case FILE_UPLOAD:
      return <FileInputFieldEditor item={item} />;
    case PLAIN_TEXT:
      return <PlainTextEditor item={item} />;

    default:
      return <div>Field Editor</div>;
  }
}

export default FieldEditor;
