import moment from "moment";

function makeVoucher(prefix, length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${prefix}${result}`;
}

function makeIdsArray(prefix, size) {
  const result = [];
  for (var i = 0; i < size; i++) {
    result.push(makeVoucher(prefix, 8));
  }

  return result;
}

function isUrlValid(userInput) {
  var res = userInput.match(
    /^(https?|ftp|torrent|image|irc):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i
  );
  if (res == null) return false;
  return true;
}

function renderMembershipDate(record, type) {
  switch (type) {
    case "registration":
      return record.registration_date
        ? moment(record.registration_date).format("DD MMMM YYYY")
        : moment(record.created_at).format("DD MMMM YYYY");
    case "expiry":
      return record.expiry_date
        ? moment(record.expiry_date).format("DD MMMM YYYY")
        : record.registration_date
        ? moment(record.registration_date)
            .add(1, "y")
            .subtract(1, "d")
            .format("DD MMMM YYYY")
        : moment(record.created_at)
            .add(1, "y")
            .subtract(1, "d")
            .format("DD MMMM YYYY");
    case "renewal":
      return record.renewal_date
        ? moment(record.renewal_date).format("DD MMMM YYYY")
        : " - ";
    default:
      break;
  }

  return record.expiry_date
    ? moment(record.expiry_date).format("DD MMMM YYYY")
    : moment(record.created_at)
        .add(1, "y")
        .subtract(1, "d")
        .format("DD MMMM YYYY");
}

function trimData(obj) {
  Object.keys(obj).map(
    (k) => (obj[k] = typeof obj[k] == "string" ? obj[k].trim() : obj[k])
  );
  return obj;
}

export {
  makeVoucher,
  makeIdsArray,
  isUrlValid,
  renderMembershipDate,
  trimData
};
