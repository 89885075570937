import React from "react";
import { Paper, Typography } from "@material-ui/core";

function MemberAuditTrail() {
  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Member Audit Trail
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        Future content will appear here...
      </Paper>
    </div>
  );
}

export default MemberAuditTrail;
