import React, { useContext } from "react";
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import FormBuilderContext from "../form-builder-context";

import debounce from "lodash.debounce";

function PlainTextEditor({ item }) {
  const { onSettingField } = useContext(FormBuilderContext);
  const debouncedSettingsChange = debounce(
    (item, settings) => onSettingField(item, settings),
    400
  );

  const onTextChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, text: value } : { text: value }
    );
  };

  const onVariantSelect = (e) => {
    onSettingField(
      item,
      item.settings
        ? { ...item.settings, variant: e.target.value }
        : { variant: e.target.value }
    );
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      <Typography variant="h6">Edit Text Field</Typography>
      <Divider light />

      <TextField
        label="Text"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        defaultValue={
          item.settings && item.settings.text ? item.settings.text : ""
        }
        onChange={onTextChange}
        multiline
      />

      <FormControl
        variant="filled"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
      >
        <InputLabel id="variant">Variant</InputLabel>
        <Select
          labelId="variant"
          value={
            item.settings && item.settings.variant ? item.settings.variant : ""
          }
          variant="filled"
          onChange={onVariantSelect}
        >
          <MenuItem value="h1">h1</MenuItem>
          <MenuItem value="h2">h2</MenuItem>
          <MenuItem value="h3">h3</MenuItem>
          <MenuItem value="h4">h4</MenuItem>
          <MenuItem value="h5">h5</MenuItem>
          <MenuItem value="h6">h6</MenuItem>
          <MenuItem value="subtitle1">subtitle1</MenuItem>
          <MenuItem value="subtitle2">subtitle2</MenuItem>
          <MenuItem value="body1">body1</MenuItem>
          <MenuItem value="body2">body2</MenuItem>
        </Select>
        <FormHelperText id="helper">
          It refers to{" "}
          <a
            href="https://material-ui.com/components/typography/#component"
            target="_blank"
            rel="noopener noreferrer"
          >
            Material UI Typography Variant
          </a>
          .
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default PlainTextEditor;
