import { gql } from "@apollo/client";

export const GET_ALL_FORMS = gql`
  query {
    pollBuilders {
      id
      title
      allow_multiple_select
      description
      slug
      start_date
      end_date
      form
      status
      main_poll
      image {
        name
        url
      }
      available_to
      submissions {
        id
      }
    }
  }
`;

export const GET_FORM = gql`
  query ($id: ID!) {
    pollBuilder(id: $id) {
      id
      title
      allow_multiple_select
      available_to
      description
      slug
      start_date
      end_date
      form
      status
      image {
        id
        name
        url
      }
    }
  }
`;
