import React from "react";
import { useAuthedAxios } from "../../util/axios";

const OrganizationInfo = ({ id, type }) => {
  const [{ data: organization }] = useAuthedAxios(`/organizations/${id}`);

  return (
    <>
      {type === "name" && organization?.name}
      {type === "membership" && organization?.membership_type}
    </>
  );
};

export default OrganizationInfo;
