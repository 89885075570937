import React from "react";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import { useAuthedAxios } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const MembershipSummary = () => {
  const classes = useStyles();

  const [{ data, loading, error }] = useAuthedAxios({
    url: `/reporting?`
  });

  const lite = data?.organizations?.filter(
    (org) => org.membership_type === "Lite" || org.membership_type === null
  ).length;
  const platinum = data?.organizations?.filter(
    (org) => org.membership_type === "Platinum"
  ).length;
  const corporate = data?.organizations?.filter(
    (org) => org.membership_type === "Corporate"
  ).length;
  const associate = data?.organizations?.filter(
    (org) => org.membership_type === "Associate"
  ).length;

  return (
    <div>
      {!error && data && (
        <>
          <Typography variant="h6">Total Members: {data.totalCount}</Typography>
          <p>
            <b>Lite:</b> {lite}
          </p>
          <p>
            <b>Associate:</b> {associate}
          </p>
          <p>
            <b>Corporate:</b> {corporate}
          </p>
          <p>
            <b>Platinum Corporate:</b> {platinum}
          </p>
          {/* <Typography variant="h6">Number Of New Sign Ups</Typography>
          <p>Lite: 1000</p>
          <p>Associate: 1000</p>
          <p>Corporate: 1000</p>
          <p>Platinum Corporate: 1000</p> */}
        </>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
};

export default MembershipSummary;
