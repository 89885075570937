import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import FormBuilderContext from "../form-builder-context";
import {
  PRE_FILLED_ITEMS,
  PRE_FILLED_MEMBER_ITEMS,
  PRE_FILLED_ORGANIZATION_ITEMS,
} from "../constants";
import debounce from "lodash.debounce";

function TextFieldEditor({ item }) {
  const { onSettingField } = useContext(FormBuilderContext);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (item && !title) {
      setTitle(item.settings && item.settings.title ? item.settings.title : "");
    }
  }, [item, title]);

  const debouncedSettingsChange = debounce(
    (item, settings) => onSettingField(item, settings),
    400
  );

  const onTitleChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, title: value } : { title: value }
    );
    setTitle(value);
  };

  const onPlaceholderChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings
        ? { ...item.settings, placeholder: value }
        : { placeholder: value }
    );
  };

  const onHelpTextChange = (e) => {
    const value = e.target.value;
    debouncedSettingsChange(
      item,
      item.settings ? { ...item.settings, help: value } : { help: value }
    );
  };

  const onRequiredChange = (e, required) => {
    onSettingField(
      item,
      item.settings ? { ...item.settings, required } : { required }
    );
  };

  const onPrefillSelect = ({ target: { value } }) => {
    const prefill = PRE_FILLED_ITEMS.find((i) => i.attribute === value);
    setTitle(prefill ? prefill.label : "");
    onSettingField(
      item,
      item.settings
        ? {
            ...item.settings,
            prefill,
            title: prefill ? prefill.label : title,
          }
        : { prefill }
    );
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      <Typography variant="h6">Edit Text Field</Typography>
      <Divider light />

      <TextField
        label="Field Title"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onTitleChange}
        value={title}
      />
      <TextField
        label="Placeholder"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onPlaceholderChange}
        defaultValue={
          item.settings && item.settings.placeholder
            ? item.settings.placeholder
            : ""
        }
      />
      <TextField
        label="Help Text"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
        variant="filled"
        onChange={onHelpTextChange}
        defaultValue={
          item.settings && item.settings.help ? item.settings.help : ""
        }
      />
      <br />
      <FormControl
        variant="filled"
        style={{
          width: "100%",
          margin: "10px 0",
        }}
      >
        <InputLabel id="prefill-select">Prefill/Update</InputLabel>
        <Select
          labelId="prefill-select"
          value={
            item.settings && item.settings.prefill
              ? item.settings.prefill.attribute
              : ""
          }
          variant="filled"
          onChange={onPrefillSelect}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <ListSubheader disableSticky>Member</ListSubheader>
          {PRE_FILLED_MEMBER_ITEMS.map((item) => (
            <MenuItem key={item.ref + item.attribute} value={item.attribute}>
              {item.label}
            </MenuItem>
          ))}
          <ListSubheader disableSticky>Organisation</ListSubheader>
          {PRE_FILLED_ORGANIZATION_ITEMS.map((item) => (
            <MenuItem key={item.ref + item.attribute} value={item.attribute}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={item.settings && item.settings.required ? true : false}
            onChange={onRequiredChange}
            name="required"
          />
        }
        label="Required"
      />
    </div>
  );
}

export default TextFieldEditor;
