import React, { useState } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import XLSX from "xlsx";
import moment from "moment";

import { authedAxios, BASE_URL } from "../../../../util/axios";
import { trimData } from "../../../../util/utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  searchButton: {
    marginTop: "17px",
    marginLeft: "5px",
  },
  goButton: {
    marginTop: "15px",
    marginLeft: "5px",
  },
}));

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

function ImportMembers() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = useState("");
  const [arrayFromCSCV, setArrayFromCSCV] = useState([]);
  const [importErrors, setImportErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  async function importData() {
    setImportErrors([]);
    if (file && arrayFromCSCV.length === 0)
      return enqueueSnackbar("Please process triggers first", {
        variant: "error",
      });
    if (arrayFromCSCV.length === 0)
      return enqueueSnackbar(
        "Please choose a file and process triggers first",
        {
          variant: "error",
        }
      );
    const jsonArr = arrayFromCSCV.map((obj) => {
      trimData(obj);
      let newObj = { ...obj };
      const {
        annual_turnover,
        join_date,
        registration_date,
        expiry_date,
        membership_type,
        name,
        nature_of_business,
        ownership_type,
        paid_up_capital,
        phone,
        staff_strength,
        website,
        year_of_incorporation,
        legal_structure,
        fax,
        company_registration_no,
        address,
        industry,
        membership_status,
        renewal_date,
      } = newObj;
      newObj.organization = {
        registration_date: registration_date
          ? new Date(registration_date).toISOString()
          : null,
        expiry_date: expiry_date
          ? new Date(expiry_date).toISOString()
          : registration_date
          ? moment(registration_date).add(1, "y").subtract(1, "d").toISOString()
          : null,
        renewal_date: renewal_date
          ? new Date(renewal_date).toISOString()
          : null,
        membership_type,
        membership_status,
        annual_turnover,
        name,
        nature_of_business,
        ownership_type,
        paid_up_capital,
        phone,
        staff_strength,
        website,
        year_of_incorporation: year_of_incorporation
          ? new Date(year_of_incorporation).toISOString()
          : null,
        legal_structure,
        fax,
        company_registration_no,
        address,
        industry_classification: industry?.split("|"),
      };
      if (obj["Email 1"]) {
        newObj.members = [
          {
            email: obj["Email 1"],
            salutation: obj["Salutation 1"],
            first_name: obj["Full Name 1"].split(" ")[0],
            last_name: obj["Full Name 1"].split(" ").slice(1).join(" "),
            mobile_no: obj["Mobile 1"],
            citizenship: obj["Nationality 1"],
            designation: obj["Designation 1"],
          },
        ];
      }
      if (obj["Email 2"]) {
        newObj.members.push({
          email: obj["Email 2"],
          salutation: obj["Salutation 2"],
          first_name: obj["Full Name 2"].split(" ")[0],
          last_name: obj["Full Name 2"].split(" ").slice(1).join(" "),
          mobile_no: obj["Mobile 2"],
          citizenship: obj["Nationality 2"],
          designation: obj["Designation 2"],
        });
      }
      if (obj["Email 3"]) {
        newObj.members.push({
          email: obj["Email 3"],
          salutation: obj["Salutation 3"],
          first_name: obj["Full Name 3"].split(" ")[0],
          last_name: obj["Full Name 3"].split(" ").slice(1).join(" "),
          mobile_no: obj["Mobile 3"],
          citizenship: obj["Nationality 3"],
          designation: obj["Designation 3"],
        });
      }
      return newObj;
    });

    setLoading(true);
    Promise.allSettled(
      jsonArr.map(async (org) => {
        if (org.organization && org.members) {
          try {
            await authedAxios.post(`${BASE_URL}/organizations/registerbulk`, {
              members: org.members,
              organization: org.organization,
            });
          } catch (error) {
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
            setImportErrors((importErrors) => [
              ...importErrors,
              error.response.data.message,
            ]);
          }
        }
      })
    )
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Import Failed", {
          variant: "error",
        });
        setLoading(false);
      })
      .finally(() => {
        enqueueSnackbar("Import finished", {
          variant: "info",
        });
        setLoading(false);
      });
  }

  function handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) setFile(files[0]);
  }

  function handleFile() {
    if (!file)
      return enqueueSnackbar("Please choose a file first", {
        variant: "error",
      });
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
      });
      /* Update state */
      setArrayFromCSCV(data);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Member Management
      </Typography>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "40px", display: "grid" }}
      >
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          Import Organisations with Members
        </Typography>
        <br />
        <ul>
          <li>
            Download{" "}
            <a
              href={`${BASE_URL}/uploads/Template_72668d068b.xlsx`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Template.xlsx
            </a>
          </li>
          <li>Membership type can be - Lite/ Associate/ Corporate/ Platinum</li>
          <li>Membership status can be - Inactive/ Pending/ Active/ Expired</li>
          <li>
            <b>Registration date</b>,<b> Expiry Date</b> and
            <b> Year of Incorporation</b> should be formatted as Date
          </li>
          <li>Address column(last) is concat of all 4 address columns</li>
        </ul>
        {importErrors.length > 0 && (
          <div>
            <Typography
              variant="h5"
              color="secondary"
              style={{ fontWeight: "bold" }}
            >
              Import Errors:
            </Typography>
            <ul>
              {importErrors.map((error) => (
                <li style={{ color: "red" }} key={error}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div
          className={classes.main_filter_div}
          style={{ justifyContent: "flex-end" }}
        >
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <input
              type="file"
              className="form-control"
              id="file"
              accept={SheetJSFT}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classes.searchButton}
              onClick={handleFile}
            >
              Process Triggers
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              className={classes.goButton}
              onClick={importData}
            >
              Import data{" "}
            </Button>
          </div>
        </div>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </Paper>
    </div>
  );
}

export default ImportMembers;
