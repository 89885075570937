import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import CustomTable from "../../../../../components/common/Table";
import { useAuthedAxios } from "../../../../../util/axios";
import moment from "moment";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useSnackbar } from "notistack";
import OrganizationInfo from "../../../../../components/common/OrganizationInfo";

const PER_PAGE_LIMIT = 10;

const useStyles = makeStyles((theme) => ({
  searchButton: {
    width: "150px",
    height: "30px",
    marginTop: "17px",
    marginLeft: "5px"
  },
  goButton: {
    width: "40px",
    height: "30px",
    marginTop: "15px",
    marginLeft: "5px"
  },
  icons_right: {
    width: "20px",
    height: "20px",
    cursor: "pointer"
  },
  main_filter_div: {
    display: "flex",
    marginBottom: "10px",
    justifyContent: "space-between",
    paddingRight: "10px",
    paddingLeft: "2px"
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },

  remarksContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "250px"
  },

  remarksButtonContainer: {
    textAlign: "right",
    marginTop: "5px"
  }
}));

function ViewAttendance() {
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit: searchHandleSubmit,
    register: searchRegister,
    errors: searchError,
    reset: searchFormReset,
    watch
  } = useForm();
  const searchTerm = watch("search_term");

  const [{ data, loading, error }, loadSubmissions] = useAuthedAxios(
    {
      url: `/workshop-submissions/all/${id}?_limit=${PER_PAGE_LIMIT}&_offset=0`
    },
    {
      manual: true,
      useCache: false
    }
  );

  const [
    {
      data: updateSubmissionData,
      loading: updateSubmissionLoading,
      error: updateSubmissionError
    },
    updateSubmission
  ] = useAuthedAxios(
    {
      method: "PUT"
    },
    {
      manual: true,
      useCache: false
    }
  );

  const [showFilter, setShowFilter] = useState(null);
  const [attendedStatus, setAttendedStatus] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [remarks, setRemarks] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [recordIdOnFocus, setRecordIdOnFocus] = useState({});

  useEffect(() => {
    loadSubmissions();
  }, [loadSubmissions]);

  useEffect(() => {
    if (updateSubmissionData) {
      loadSubmissions({
        url: `/workshop-submissions/all/${id}?_limit=${PER_PAGE_LIMIT}&_offset=${
          currentPage * PER_PAGE_LIMIT
        }&search=${searchTerm}&attended=${attendedStatus}`
      });
      enqueueSnackbar("Submission updated successfully!", {
        variant: "success",
        preventDuplicate: true
      });
      setRecordIdOnFocus({
        ...recordIdOnFocus,
        [updateSubmissionData.id]: undefined
      });
      if (remarks && recordId) {
        setRemarks(null);
        setRecordId(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, loadSubmissions, updateSubmissionData]);

  useEffect(() => {
    if (updateSubmissionError) {
      enqueueSnackbar(
        "There was some internal server error while saving the submission. Please try again later.",
        {
          variant: "error",
          preventDuplicate: true
        }
      );
    }
  }, [enqueueSnackbar, updateSubmissionError]);

  const onShowFilter = (event) => {
    setShowFilter(event.currentTarget);
  };

  const onSelectFilter = (selectedMenu) => {
    setAttendedStatus(selectedMenu);
    setCurrentPage(0);
    loadSubmissions({
      url: `/workshop-submissions/all/${id}?_limit=${PER_PAGE_LIMIT}&_offset=0&search=${searchTerm}&attended=${selectedMenu}`
    });
  };

  const onSubmitSearch = ({ search_term }) => {
    setCurrentPage(0);
    loadSubmissions({
      url: `/workshop-submissions/all/${id}?_limit=${PER_PAGE_LIMIT}&_offset=0&search=${search_term}&attended=${attendedStatus}`
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickAttendance = (record, hasAttended) => {
    updateSubmission({
      data: {
        hasAttended: hasAttended
      },
      url: `/workshop-submissions/${record.id}`
    });
  };

  const onChangeRemarks = (id, value) => {
    setRemarks(value);
    setRecordId(id);
  };

  const onFocusOut = (id) => {
    document.getElementById(`remarks${id}`).value = recordIdOnFocus[id];
    setRecordIdOnFocus({
      ...recordIdOnFocus,
      [id]: undefined
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Organisation Name",
        accessor: (record) =>
          record?.name ||
          (record?.member?.organization && (
            <OrganizationInfo id={record?.member?.organization} type="name" />
          )) || <center>-</center>
      },
      {
        Header: "Name",
        accessor: (record) =>
          `${record?.form_submission?.first_name} ${record?.form_submission?.last_name}`
      },
      {
        Header: "Phone No.",
        accessor: (record) => `${record?.form_submission?.mobile_no}`
      },

      {
        Header: "Email",
        accessor: (record) => `${record?.form_submission?.email}`
      },
      {
        Header: "Membership",
        accessor: (record) =>
          record?.membership_type ||
          (record?.member?.organization && (
            <OrganizationInfo
              id={record?.member?.organization}
              type="membership"
            />
          )) || <center>-</center>
      },
      {
        Header: "Date Registered",
        accessor: (record) => moment(record.created_at).format("DD-MM-YYYY")
      },
      {
        Header: "Attendance",
        accessor: (record) => (
          <>
            {record.hasAttended === true ? (
              <IconButton
                aria-label="Attend"
                size="small"
                onClick={() => onClickAttendance(record, false)}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="Attend"
                size="small"
                onClick={() => onClickAttendance(record, true)}
              >
                <CheckIcon />
              </IconButton>
            )}
          </>
        )
      },
      {
        Header: "Status",
        accessor: (record) => (
          <>
            {record.hasAttended ? (
              <span>Attended</span>
            ) : (
              <span>Did Not Attended</span>
            )}
          </>
        )
      },
      {
        Header: "Remarks",
        accessor: (record) => (
          <>
            <div className={classes.remarksContainer}>
              <TextField
                key={record.id}
                defaultValue={record.remarks ? record.remarks : ""}
                onChange={(e) => onChangeRemarks(record.id, e.target.value)}
                onFocus={(e) => {
                  setRecordIdOnFocus({
                    ...recordIdOnFocus,
                    [record.id]: record.remarks ? record.remarks : ""
                  });
                }}
                // onBlur={(e) => {
                //   onFocusOut(record.id);
                // }}
                id={`remarks${record.id}`}
                placeholder="Remarks"
                label="Remarks"
                variant="outlined"
                multiline
                rows={3}
              />
              {recordIdOnFocus[record.id] ||
              recordIdOnFocus[record.id] !== undefined ? (
                <div className={classes.remarksButtonContainer}>
                  <IconButton
                    aria-label="Attend"
                    size="small"
                    style={{
                      color: "red"
                    }}
                    onClick={() => {
                      setRecordId(null);
                      setRemarks(null);
                      onFocusOut(record.id);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Attend"
                    size="small"
                    style={{
                      color: "green"
                    }}
                    onClick={() => onSubmitRemarks(record.id, remarks)}
                  >
                    <CheckIcon />
                  </IconButton>
                </div>
              ) : null}
            </div>
          </>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClickAttendance]
  );

  const onPageChange = (page) => {
    setCurrentPage(page);
    loadSubmissions({
      url: `/workshop-submissions/all/${id}?_limit=${PER_PAGE_LIMIT}&_offset=${
        page * PER_PAGE_LIMIT
      }`
    });
  };

  const onSubmitRemarks = (id, value) => {
    updateSubmission({
      data: {
        remarks: value
      },
      url: `/workshop-submissions/${id}`
    });
  };

  return (
    <div>
      {!error && data && (
        <Typography
          variant="h4"
          color="secondary"
          style={{ fontWeight: "bold" }}
        >
          {data.title}
        </Typography>
      )}
      {!error && data && (
        <Paper
          elevation={3}
          style={{ padding: "20px", marginTop: "40px", display: "grid" }}
        >
          <div className={classes.main_filter_div}>
            <div
              style={{
                display: "flex"
              }}
            >
              <form onSubmit={searchHandleSubmit(onSubmitSearch)}>
                <TextField
                  label="Search"
                  key="form-submission-search-textfield"
                  inputRef={searchRegister({
                    required: "This field cannot be empty!"
                  })}
                  name="search_term"
                  error={
                    searchError.search_term && searchError.search_term.message
                      ? true
                      : false
                  }
                  helperText={
                    searchError.search_term && searchError.search_term.message
                      ? searchError.search_term.message
                      : null
                  }
                  InputProps={{
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            searchFormReset();
                            setCurrentPage(0);
                            loadSubmissions();
                          }}
                        >
                          <CloseRounded />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.searchButton}
                  type="submit"
                >
                  Search
                </Button>
              </form>
            </div>
            <div
              style={{
                marginTop: "15px"
              }}
            >
              <div>
                {attendedStatus !== 0 ? (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setShowFilter(null);
                      setAttendedStatus(0);
                      loadSubmissions();
                    }}
                    style={{
                      marginRight: "10px",
                      marginTop: "-10px"
                    }}
                  >
                    Clear Filter
                  </Button>
                ) : null}
                <img
                  src="/images/filter.png"
                  alt="Filter Icon"
                  className={classes.icons_right}
                  style={{
                    marginRight: "10px"
                  }}
                  aria-controls="filter-menu"
                  aria-haspopup="true"
                  onClick={onShowFilter}
                />
                <Menu
                  id="filter-menu"
                  anchorEl={showFilter}
                  keepMounted
                  open={Boolean(showFilter)}
                  onClose={() => {
                    setShowFilter(null);
                  }}
                  style={{
                    marginLeft: "-95px"
                  }}
                >
                  <MenuItem
                    onClick={() => onSelectFilter(0)}
                    style={{
                      background: attendedStatus === 0 ? "#de1736" : ""
                    }}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => onSelectFilter(1)}
                    style={{
                      background: attendedStatus === 1 ? "#de1736" : ""
                    }}
                  >
                    Attended
                  </MenuItem>
                  <MenuItem
                    onClick={() => onSelectFilter(2)}
                    style={{
                      background: attendedStatus === 2 ? "#de1736" : ""
                    }}
                  >
                    Did Not Attended
                  </MenuItem>
                </Menu>
              </div>

              {/* <img
                  src="/images/export.png"
                  alt="Export Icon"
                  className={classes.icons_right}
                  onClick={() => {
                    // document.getElementById("allmemberexport").click();
                    // onExport();
                  }}
                /> */}
            </div>
          </div>
          <CustomTable
            columns={columns}
            data={data.submissions}
            showSelections={false}
            //   onRowSelect={onRowSelect}
            onPageChange={onPageChange}
            currentPage={currentPage}
            pageSize={PER_PAGE_LIMIT}
            totalRecordsCount={data.pagination.rowCount}
            totalPagesCount={data.pagination.pageCount}
            hover
          />
        </Paper>
      )}

      {error && (
        <Paper
          elevation={3}
          style={{ padding: "20px", marginTop: "40px", display: "grid" }}
        >
          <Alert severity="error">
            <AlertTitle>Internal Server Error</AlertTitle>
            There was some internal server error while fetching data from
            server. Please try reloading the page!
          </Alert>
        </Paper>
      )}

      <Backdrop
        className={classes.backdrop}
        open={loading || updateSubmissionLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default ViewAttendance;
