import React, { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  Badge,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import CustomTable from "../../../../../components/common/Table";
import { GET_ALL_WORKSHOPS } from "../workshops.graphql";
import { CloudDownload } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { authedAxios } from "../../../../../util/axios";
import fileDownload from "js-file-download";

function ViewAllListings() {
  const history = useHistory();
  const { state } = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { loading, error, data, refetch } = useQuery(GET_ALL_WORKSHOPS, {
    pollInterval: 5000
  });

  useEffect(() => {
    if (state && state.update) {
      refetch();
    }
  }, [state, refetch]);

  const downloadSubmissions = React.useCallback(
    async (title, id) => {
      const key = enqueueSnackbar(
        `Generating csv file for workshop '${title}'!`,
        {
          preventDuplicate: true,
          variant: "info"
        }
      );
      authedAxios({
        url: `/workshop-submissions/download/${id}`,
        method: "GET",
        responseType: "blob" // important
      })
        .then((res) => {
          fileDownload(res.data, title + ".csv");
          closeSnackbar(key);
          enqueueSnackbar(
            `Submissions for workshop '${title}' are downloaded successfully!`,
            {
              preventDuplicate: true,
              variant: "success"
            }
          );
        })
        .catch((e) => {
          enqueueSnackbar(
            "There was some internal server error while downloading the submissions. Please try again later!",
            {
              preventDuplicate: true,
              variant: "error"
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const columns = useMemo(
    () => [
      {
        Header: "S/N",
        accessor: (_, index) => <span>{index + 1}</span>
      },
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Date",
        accessor: (record) => moment(record.date).format("DD-MM-YYYY")
      },
      {
        Header: "Location",
        accessor: "location"
      },
      {
        Header: "Price",
        accessor: "price"
      },
      {
        Header: "Tag",
        accessor: "tag"
      },
      {
        Header: "Total Submissions",
        accessor: (record) =>
          record.workshop_submissions.length === 0 ? (
            <Chip
              variant="default"
              label={record.workshop_submissions.length}
              color={"default"}
            />
          ) : (
            <IconButton
              aria-label="download"
              title="Download Submissions"
              onClick={() => downloadSubmissions(record.title, record.id)}
            >
              <Badge
                badgeContent={record.workshop_submissions.length}
                color="secondary"
              >
                <CloudDownload />
              </Badge>
            </IconButton>
          )
      },
      {
        Header: "Action",
        accessor: (record) => (
          <div>
            <ButtonGroup
              color="secondary"
              aria-label="outlined primary button group"
              size="small"
            >
              <Button
                onClick={() =>
                  history.push(`/dashboard/workshops/${record.id}`)
                }
              >
                Attendance
              </Button>
            </ButtonGroup>
          </div>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return (
    <div>
      <Typography variant="h4" color="secondary" style={{ fontWeight: "bold" }}>
        Workshops
      </Typography>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "40px" }}>
        {data && (
          <Grid container spacing={3}>
            <CustomTable
              columns={columns}
              data={data.workshops}
              showSelections={false}
              showPagination={false}
              hover
            />
          </Grid>
        )}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!loading && error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            There was some internal server error while fetching records. Please
            try reloading the page.
          </Alert>
        )}
      </Paper>
    </div>
  );
}

export default ViewAllListings;
