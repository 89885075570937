import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
  Modal,
  Divider,
  IconButton,
  Link
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
// import EditOrganizationInfo from "./EditOrganizationInfo/EditOrganizationInfo";
import { Close } from "@material-ui/icons";
import OrgInfo from "./OrganizationDetails/OrgInfo";
import MemberInfo from "./OrganizationDetails/MembersInfo";
import {
  authedAxios,
  BASE_URL,
  useAuthedAxios
} from "../../../../../util/axios";
import Contact from "./OrganizationDetails/Contact";
import { useHistory } from "react-router-dom";
import ReferralsInfo from "./OrganizationDetails/Referrals";
// import Contact from "./OrganizationDetails/Contact";

const useStyles = makeStyles((theme) => ({
  infoCellStyle: {
    backgroundColor: "#DE1736",
    verticalAlign: "top",
    color: "white",
    width: "220px"
  },
  infoCellLeftMargin: {
    marginLeft: "10px"
  },
  avtarOuter: {
    border: "1px solid #ededed",
    borderRadius: "5px",
    width: "150px",
    height: "170px",
    position: "relative"
  },
  avtarDiv: {
    textAlign: "end",
    position: "absolute",
    right: "10px",
    top: "10px"
  },
  paper: {
    position: "absolute",
    width: "80%",
    height: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

function getModalStyle() {
  const top = 5;
  // const left = 50;

  return {
    top: `${top}%`,
    margin: "auto",
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    outline: "none"
  };
}

function OrganisationProfile({ organizationID }) {
  const classes = useStyles();
  const history = useHistory();

  const [modalStyle] = useState(getModalStyle);
  const [isModalVisible, setModalVisibility] = useState(false);

  const [{ loading, data: organization, error }, refetch] = useAuthedAxios(
    `/organizations/${organizationID}`
  );

  const approveMembership = async () => {
    if (!organizationID) return;

    try {
      await authedAxios.post(
        `${BASE_URL}/organizations/approve/${organizationID}`,
        {
          organization
        }
      );
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setModalVisibility(false);
  };

  const onImageUpdateSuccess = () => {
    refetch();
  };

  const onDataUpdateSuccess = () => {
    refetch();
  };

  useEffect(() => {
    if (error && error.response && error.response.status === 404)
      history.push("/dashboard/member/all");
  }, [error]);

  const [
    { loading: loadingReferrals, data: referralsCount, error: referralsError }
  ] = useAuthedAxios(
    `/referrals/invitedFromAdmin?organization_id=${organizationID}`
  );

  return (
    <>
      {(loading || loadingReferrals) && (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {!loading && !referralsError && organization && (
        <>
          <Typography
            variant="h6"
            color={"secondary"}
            style={{ marginBottom: "20px" }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/dashboard/member/all")}
            >
              All Member
            </span>
            {" > "}
            <b>
              {organization.name}{" "}
              {organization.company_registration_no ? (
                <span>({organization.company_registration_no})</span>
              ) : null}
            </b>
          </Typography>
          <Grid container spacing={3}>
            <Grid lg={6} item>
              <Typography variant="subtitle1" color={"secondary"}>
                Organisation Information
                {/* <EditIcon
                  fontSize="inherit"
                  onClick={() => setModalVisibility(true)}
                  style={{
                    cursor: "pointer",
                  }}
                /> */}
              </Typography>
              <br />
              <OrgInfo classes={classes} organization={organization} />
            </Grid>
            <Grid lg={4} item>
              <Typography variant="subtitle1" color={"secondary"}>
                Membership Information
              </Typography>
              <br />
              <MemberInfo
                approveMembership={approveMembership}
                classes={classes}
                organization={organization}
              />
              <br />
              <Typography variant="subtitle1" color={"secondary"}>
                Referrals Information
              </Typography>
              <br />
              <ReferralsInfo classes={classes} referrals={referralsCount} />
            </Grid>
            <Grid lg={2} item>
              <Contact
                classes={classes}
                organization={organization}
                onImageUpdateSuccess={onImageUpdateSuccess}
              />
            </Grid>
          </Grid>

          {/* Popup Model */}
          {/* <Modal
            open={isModalVisible}
            onClose={handleClose}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            disableBackdropClick
          >
            <div className={classes.paper} style={modalStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  color={"secondary"}
                  style={{ marginBottom: "20px" }}
                >
                  <b>Edit Organisation Info</b>
                </Typography>
                <div style={{ marginTop: "-8px" }}>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </div>
              </div>
              <EditOrganizationInfo
                data={organization}
                onDataUpdateSuccess={onDataUpdateSuccess}
              />
            </div>
          </Modal> */}
        </>
      )}
      {!loading && error && (
        <Alert severity="error">
          <AlertTitle>Internal Server Error!</AlertTitle>
          There was some internal server error while fetching organisation info.
          Please try reloading the page.
        </Alert>
      )}
    </>
  );
}

export default OrganisationProfile;
