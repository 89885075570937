import { TextField } from "@material-ui/core";
import React from "react";

function ParagraphRenderer({ id, settings, member, organization, register }) {
  const getPrefillValue = () => {
    const ref = settings.prefill.ref;
    const attribute = settings.prefill.attribute;
    switch (ref) {
      case "members":
        return member[attribute];
      case "organizations":
        return organization[attribute];
      default:
        return "";
    }
  };

  const prefillValue = settings.prefill ? getPrefillValue() : null;

  return (
    <TextField
      id={id}
      name={id}
      label={settings.title}
      required={settings.required}
      placeholder={settings.placeholder}
      style={{ width: "100%" }}
      helperText={settings.help}
      defaultValue={prefillValue}
      inputRef={register}
      InputProps={{
        type:
          settings.prefill && settings.prefill.attribute === "email"
            ? "email"
            : "text",
      }}
      multiline
    />
  );
}

export default ParagraphRenderer;
