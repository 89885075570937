import { gql } from "@apollo/client";

export const GET_ALL_FORMS = gql`
  query {
    formBuilders {
      id
      title
      description
      slug
      start_date
      end_date
      available_to
      form
      status
      submissions {
        id
      }
    }
  }
`;

export const GET_FORM = gql`
  query ($id: ID!) {
    formBuilder(id: $id) {
      id
      title
      description
      slug
      start_date
      end_date
      available_to
      form
      status
    }
  }
`;
