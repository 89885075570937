import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { loadCurrentUser } from "./containers/Auth/auth.slice";
import AppRoutes from "./app.routes";
import { CircularProgress } from "@material-ui/core";
import { authedAxios } from "./util/axios";

import { hot } from "react-hot-loader";

function App() {
  // Load current user

  const dispatch = useDispatch();
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const res = await authedAxios.get(`/auth/admin/me`);
      dispatch(loadCurrentUser(res.data));
      setIsUserLoaded(true);
    } catch (e) {
      localStorage.removeItem("auth");
      authedAxios.post("/logout").finally(() => setIsUserLoaded(true));
    }
  };
  return (
    <>
      {isUserLoaded ? (
        <AppRoutes />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
}

export default hot(module)(App);
